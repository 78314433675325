<!-- 项目展示 -->
<template>
	<div>
		<homenav navTag="project"></homenav>
		<div class="region">
			<!-- 面包屑 -->
			<crumbs :crumbs="crumbs"  @clkItem="clkItemd"></crumbs>
			<div class="rg-cotain">
				<!-- 侧边导航栏 -->
				<navleft :navs="navs" :tapItem="tapItem" @clkItem="clkItem" :navRqt="true" :child="false"></navleft>
				<!-- 子组件渲染 -->
				<div class="chirn">
					<router-view :trading="trading" :left_id="left_id"/>
				</div>
			</div>
			<!-- 底部 -->
			<bottom></bottom>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tapItem: '',
				navs: [],
				crumbs: ['首页', '项目展示', '实物资产公告'],
				trading: {},
				left_id: 101,
				page: 1,
			}
		},
		created() {
			let index = parseInt(this.$router.history.current.query.index)
			this.tapItem = index
			this.leftNav()
		},
		methods: {
			clkItem(index, text) {
				this.tapItem = index
				this.crumbs = ['首页', '项目展示', '实物资产公告']
				// 修改数组
				this.$set(this.crumbs, 2, text.catname)
				this.$router.push({path:"/project/projectlist",query:{index}})
				this.left_id = text.id
			},
			// 查找左边导航
			async leftNav() {
				let url = this.$api.trading.leftNav
				let data = {
					nav_id: 59
				}
				let res = await this.$axios.get(url, {
					params: data
				})
				if (res.code) {
					this.navs = res.list
					this.$set(this.crumbs, 2, res.list[0].catname)
					let left_id = res.list[0].id
					this.left_id = res.list[0].id
				}
			},
			getCotain() {
				let letain = $(".chirn")
				let width = letain.outerWidth()/1198*100+'px'
				letain.css("font-size",width)
			},
			clkItemd(index) {
				switch (index) {
					case 0:
						this.$router.push({name:"index"})
					break;
				}
			}
		},
		mounted() {
			$(document).ready(()=>{
				this.getCotain()
				$(window).resize(()=>{
					this.getCotain()
				})
			})
		},
	}
</script>

<style lang="less" scoped>
	.region {
		max-width: 1440px;
		margin: 0 auto;

		.rg-cotain {
			display: flex;
		}

		.chirn {
			flex-grow: 1;
			background-color: #fff;
		}

		.bottom {
			position: static;
		}
	}

	@media (max-width:1480px) {
		.region {
			padding: 0 20px;
		}
	}

	@media (max-width:900px) {
		.region {
			.rg-cotain {
				flex-direction: column;
				margin: 0;
			}

			.chirn {
				width: 100%;
				padding: 0;
			}
		}
	}
</style>
