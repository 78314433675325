<!-- 竞价大厅 -->
<template>
	<div>
		<homenav navTag="bidding"></homenav>
		<div class="region">
			<!-- 面包屑 -->
			<crumbs :crumbs="crumbs"></crumbs>
			<div class="rg-cotain">
				<!-- 子组件渲染 -->
				<div class="chirn">
					<router-view />
				</div>
			</div>
			<!-- 底部 -->
			<bottom></bottom>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tapItem: 0,
				navs: ['竞价大厅'],
				crumbs: ['首页', '竞价大厅']
			}
		},
		methods: {
			clkItem(index, text) {
				this.tapItem = index
				this.crumbs = ['首页', '竞价大厅']
				// 修改数组
				this.$set(this.crumbs, 2, text)
				switch (index) {
					case 0:
						this.$router.push({
							name: "referrals"
						})
						break;
					case 1:
						this.$router.push({
							name: "promote_details"
						})
						break;
				}
			}
		},
		mounted() {
			this.$bus.$on('clkTableRow', (data) => {
				this.crumbs.push(data)
			})
		}
	}
</script>

<style lang="less" scoped>
	.region {
		max-width: 1440px;
		margin: 0 auto;
	
		.rg-cotain {
			display: flex;
		}
	
		.chirn {
			flex-grow: 1;
			background-color: #fff;
		}
	
		.bottom {
			position: static;
		}
	}
	
	@media (max-width:1480px) {
		.region {
			padding: 0 20px;
		}
	}
	
	@media (max-width:900px) {
		.region {
			.rg-cotain {
				flex-direction: column;
				margin: 0;
			}
	
			.chirn {
				width: 100%;
				padding: 0;
			}
		}
	}
</style>
