<template>
  <div>
    <dialog1 :dialogVisible.sync="show" @submit="goDetail"></dialog1>
    <tap :taps="taps"><i></i></tap>
    <!-- 推荐路演 -->
    <letain :letain="letains" :time="1500"><i></i></letain>
    <!-- 全部路演 -->
    <div class="all-ly">
      <tap :taps="taps2"><i></i></tap>
    </div>
    <course :courses="allTrains.data" @clkImgDeal="clkImgDeal"></course>
    <div class="block" v-show="allTrains.data.length">
      <el-pagination
        background
        layout="total, prev, pager, next, jumper"
        :total="allTrains.total"
        :page-size="allTrains.per_page"
        @current-change="curChge"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import course from "@/components/course/course";
import letain from "@/components/letain/letain";
import qs from "qs";
import moment from "moment";
import dialog1 from "./dialog.vue";
export default {
  data() {
    return {
      taps: ["推荐路演"],
      taps2: ["全部路演"],
      allTrains: [], // 全部路演
      letains: [],
      page: 1,
      show: false,
	  clkId:'',
    };
  },
  components: {
    course,
    letain,
    dialog1,
  },
  created() {
    this.GetTrainList();
    this.GetTrainListgg();
  },
  methods: {

    // 获取列表详情
    GetTrainList() {
      let url = this.$api.train.GetTrainList;
      let data = {
        nav_id: 25,
        clas_id: 36,
        page: this.page,
        limit: 6,
      };
      this.$axios.post(url, qs.stringify(data)).then((res) => {
        if (res.code) {
          res.data[0].list.data.forEach((item) => {
            item.inputtime = moment(parseInt(item.inputtime * 1000)).format(
              "YYYY-MM-DD"
            );
          });
          this.allTrains = res.data[0].list;
        }
      });
    },
    // 点击课程
    clkImgDeal(id) {
      this.show = true;
      this.clkId = id;
    },
    goDetail() {
      let index = this.$route.query.index;
      let queryPath = "Policyzone/GetTrainDetails";
      this.$router.push({
        path: "/train/tadetail",
        query: { index, id:this.clkId, queryPath },
      });
    },
    // 获取列表详情
    GetTrainListgg() {
      let url = this.$api.train.GetTrainList;
      let data = {
        nav_id: 25,
        clas_id: 36,
        type: 1,
      };
      this.$axios.post(url, qs.stringify(data)).then((res) => {
        if (res.code) {
          if (res.data[0].list.data.length != 0) {
            res.data[0].list.data.forEach((item) => {
              item.inputtime = moment(parseInt(item.inputtime * 1000)).format(
                "YYYY-MM-DD"
              );
            });
          }
          this.letains = res.data[0].list.data;
        }
      });
    },
    curChge(e) {
      this.page = e;
      this.GetTrainList();
    },
  },
};
</script>

<style lang="less" scoped>
.leter {
  position: relative;
  overflow: hidden;
  .cot-txt {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 416px;
    left: -416px;
    background: rgba(255, 255, 255, 0.7);
    box-sizing: border-box;
    text-align: justify;

    .study {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      padding: 0 40px;
      box-sizing: border-box;
      .tle {
        color: rgba(51, 51, 51, 1);
        font-size: 34px;
        font-weight: 700;
        line-height: 50px;
        opacity: 0;
      }
      .bor {
        height: 3px;
        width: 25%;
        background-color: rgba(51, 51, 51, 1);
        margin-top: 20px;
      }
      .time {
        color: rgba(51, 51, 51, 1);
        font-size: 14px;
        font-weight: 700;
        margin-top: 80px;
      }
    }
  }
  .cot-img {
    // position: relative;
    img {
      width: 100%;
    }
    .cot-bf {
      position: absolute;
      right: 300px;
      bottom: 40px;
      color: white;
      background-color: rgba(255, 255, 255, 0.5);
      width: 56px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      opacity: 0;
      i {
        font-size: 30px;
      }
    }
  }
  .cot-gd {
    position: absolute;
    right: -245px;
    top: 0;
    bottom: 0;
    width: 245px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.7);
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    padding: 14px;
    grid-row-gap: 14px;
    overflow: hidden;
    .arrow {
      position: absolute;
      width: 48px;
      height: 48px;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      display: flex;
      left: 50%;
      justify-content: center;
      z-index: 99999;
      color: white;
      box-sizing: border-box;
      cursor: pointer;
      &:first-child {
        top: -24px;
        align-items: flex-end;
        transform: translate(-50%, -50%);
        padding-bottom: 5px;
      }
      &:last-child {
        bottom: -24px;
        align-items: flex-start;
        transform: translate(-50%, 50%);
        padding-top: 5px;
      }
    }
    div {
      overflow: hidden;
      box-sizing: border-box;
      position: relative;
      .tip {
        color: rgba(255, 255, 255, 0);
        font-size: 16px;
        font-weight: 400;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
// 全部路演
.all-ly {
  margin-top: 60px;
}
@media (max-width: 768px) {
  .all-ly {
    margin-top: 30px;
  }
}
</style>