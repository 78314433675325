<template>
	<div>
		<probm :probm="proplem">
			<template v-slot:addIcon>
				<p><i class="iconfont">&#xe616;</i>{{proplem.title}}</p>
			</template>
			<template v-slot:shareIcon>
				<div class="share">
					<div>分享到：</div>
					<div class="sh-icon">
						<p><i class="iconfont">&#xe609;</i></p>
						<p><i class="iconfont">&#xe602;</i></p>
						<p><i class="iconfont">&#xe63d;</i></p>
					</div>
				</div>
			</template>
		</probm>
	</div>
</template>

<script>
	import probm from "@/components/probm/probm"
	import qs from "qs"
	import moment from "moment"
	export default {
		data() {
			return {
				proplem:{}
			}
		},
		components:{
			probm
		},
		created() {
			let id = this.$route.query.id
			this.InvestmentDetails(id)
		},
		methods:{
			// 获取详情页
			InvestmentDetails(id) {
				let url = this.$api.base+this.$route.query.queryPath
				this.$axios.post(
					url,
					qs.stringify({
						id
					})
				).then(res=>{
					if(res.code) {
						res.data.inputtime = moment(parseInt(res.data.inputtime*1000)).format("YYYY-MM-DD")
						this.proplem = res.data
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.share {
		display: flex;
		align-items: center;
		color: rgba(102, 102, 102, 1);
		font-size: 16px;
		font-weight: 500;
		justify-content: flex-end;
		border-top: 0.5px solid rgba(235, 236, 239, 1);
		padding-top: 34px;
		margin-top: 34px;
		.sh-icon {
			display: flex;
			p {
				width: 32px;
				height: 32px;
				background: rgba(235, 236, 239, 1);
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 12px;
				&:last-child {
					margin-right: 0;
				}
				i {
					font-size: 24px;
				}
			}
		}
		
	}
</style>