<template>
	<div>
		<enterprise @clkItem="clkItem">
			<!-- 分类 -->
			<div class="type">
				<div class="type-item" v-for="(item,index) in rqqy.data" :key="item.id" @click="clkEntAil(item.id)">
					<div class="tle">
						<h4>{{item.title}}</h4>
						<h4>{{item.code}}</h4>
					</div>
					<div class="type-ctx">
						<div>
							<h4>注册资本</h4>
							<h2>{{item.capital_name}}</h2>
						</div>
						<div>
							<h4>所属板块</h4>
							<h2>{{item.plate_name}}</h2>
						</div>
						<div>
							<h4>所属行业</h4>
							<h2>{{item.industry_name}}</h2>
						</div>
						<div>
							<h4>所属城市</h4>
							<h2>{{item.city_name}}</h2>
						</div>
					</div>
				</div>
			</div>
		</enterprise>
		<!-- 搜索标签栏 pc端-->
		
		<!-- 分页 -->
		<div class="block">
			<el-pagination
			   background
			   :page-size="parseInt(rqqy.per_page)"
			   layout="total, prev, pager, next, jumper"
			   :total="rqqy.total"
			   @current-change="curChge">
			</el-pagination>
		</div>
		<div class="block2">
			<phonePg></phonePg>
		</div>
	</div>
</template>

<script>
	import enterprise from "@/components/enterprise/enterprise"
	import phonePg from "@/components/phonePg/phonePg"
	export default {
		components:{
			enterprise,
			phonePg
		},
		data() {
			return {
				catid:0,
				page:1,
				plate:0, // 所属模块
				industry:0, // 行业分类
				capital:0, // 注册资本,
				city:0, // 所在城市
				rqqy:{}
			}
		},
		methods:{
			clkEntAil(id) {
				let index = this.$route.query.index
				this.$router.push({name:"enterDetail",query:{id,index}})
			},
			getDetail() {
				this.$axios.get("Specialization/getEnterpriseList",{
					params:{
						catid:this.catid,
						limit:9,
						page:this.page,
						plate:this.plate,
						industry:this.industry,
						capital:this.capital,
						city:this.city
					}
				}).then(res=>{
					this.rqqy = res.data
				})
			},
			// 点击每一项
			clkItem(index,id) {
				if(index==0) {
					this.plate = id
				}else if(index==1) {
					this.industry = id
				}else if(index==2) {
					this.capital = id
				}else if(index==3) {
					this.city = id
				}
				this.getDetail()
			},
		},
		created() {
			let id = this.$route.query.id
			this.catid = id
			this.getDetail()
		},
		curChge(e) {
			this.page = e
			this.getDetail()
		}
	}
</script>

<style lang="less" scoped>
	// 分类
	.type {
		display: grid;
		grid-template-columns: repeat(3,1fr);
		column-gap: 20px;
		row-gap: 30px;
		margin-top: 30px;
		.type-item {
			
			cursor: pointer;
			transition: all .5s;
			border: 0.5px solid rgba(235, 236, 239, 1);
			&:hover {
				transform: translateY(-5px);
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
				.tle {
					color: rgba(10, 72, 144, 1);
					background-color: #ecf1f7;
					
				}
				.type-ctx {
					&>div {
						h2 {
							color: rgba(10, 72, 144, 1);
						}
					}
				}
			}
			.tle {
				color: rgba(102, 102, 102, 1);
				font-size: 16px;
				font-weight: 500;
				display: flex;
				justify-content: space-between;
				padding: 11px 20px;
				background-color: #f3f3f3;
				h4:first-child {
					display: -webkit-box;
					overflow: hidden;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					padding-right: 20px;
				}
			}
			.type-ctx {
				padding: 20px 20px 56px 20px;
				display: grid;
				grid-template-columns: repeat(2,1fr);
				row-gap: 20px;
				&>div {
					h4 {
						color: rgba(153, 153, 153, 1);
						font-size: 16px;
						font-weight: 400;
						line-height: 24px;
					}
					h2 {
						color: rgba(102, 102, 102, 1);
						font-size: 22px;
						font-weight: 500;
						line-height: 35px;
					}
				}
			}
		}	
	}
	
	@media (max-width:1200px) {
		.type {
			grid-template-columns: repeat(2,1fr);
		}
	}
	@media (max-width:900px) {
		.enter {
			padding-top: 9px;
		}
	}
	@media (max-width:768px) {
		.type {
			grid-template-columns: repeat(1,1fr);
			margin-top: 19px;
		}
	}
</style>