<!-- 区域股权业务 -->
<template>
	<div>
		<navs navTag="region"></navs>
		<div class="region">
			<!-- 面包屑 -->
			<crumbs :crumbs="crumbs" @clkItem="clkItemd"></crumbs>
			<div class="rg-cotain">
				<!-- 侧边导航栏 -->
				<navleft :navs="navs" :tapItem="tapItem" @clkItem="clkItem"></navleft>
				<!-- 子组件渲染 -->
				<div class="chirn">
					<router-view :resList="resList"/>
				</div>
			</div>
		</div>
		<!-- 底部 -->
		<bottom></bottom>
	</div>
</template>

<script>
	import qs from "qs"
	export default {
		data() {
			return {
				tapItem:0,
				navs:['登记托管','企业挂牌','股权质押','债权融资','股权交易'],
				crumbs:['首页','区域股权业务','登记托管'],
				// 点击左侧导航获取到的数据
				resList:[]
			}
		},
		created() {
			// 获取左侧导航数据
			let index = parseInt(this.$router.history.current.query.index)
			this.tapItem = index
			if(index==0) {
				this.LeftSidebarList(52)
			}else if(index==1) {
				this.LeftSidebarList(49)
			}else if(index==2) {
				this.LeftSidebarList(55)
			}
			
			this.$bus.$on("clkTap",(data)=>{
				
			})
		},
		methods:{
			// 点击面包屑
			clkItemd(index) {
				switch (index) {
					case 0:
						this.$router.push({name:"index"})
					break;
				}
			},
			// 点击左侧导航
			clkItem(index,text) {
				this.tapItem = index
				this.crumbs = ['首页','区域股权业务','会员展示']
				// 修改面包屑数组
				this.$set(this.crumbs,2,text)
				// 如果是活的数据则
				switch(index) {
					case 0:
						this.LeftSidebarList(52)
						this.$router.push({name:"register",query:{index:0}})
					break;
					case 1:
						this.LeftSidebarList(49)
						this.$router.push({name:"listing",query:{index:1}})
					break;
					case 2:
						this.LeftSidebarList(55)
						this.$router.push({name:"stock",query:{index:2}})
					break;
					case 3:
						// this.$router.push({name:"stock",query:{index:3}})
						window.location.href = "https://www.xl-bao.com/"
					break;
					case 4:
						this.$router.push({name:"pledge",query:{index:4}})
					break;
				}
			},
			// 获取左侧导航数据
			LeftSidebar() {
				let url = this.$api.stock.LeftSidebar
				let data = {
					nav_id:"2"
				}
				this.$axios.post(
					url,
					qs.stringify(data)
				).then(res=>{
					if(res.code) {
						this.LeftSidebarList(res.list[0].id)
						res.list.push({id:-1,catname:"债权融资"})
						res.list.push({id:-2,catname:"股权交易"})
						this.navs = res.list
						this.$set(this.crumbs,2,res.list[0].catname)
						this.$router.push({name:"register",params:{nav_id:res.list[0].id}})
					}
				})
			},
			
			// 根据左侧获取右边列表
			LeftSidebarList(nav_id) {
				let url = this.$api.stock.LeftSidebarList
				let data = {
					nav_id
				}
				this.$axios.post(
					url,
					qs.stringify(data)
				).then(res=>{
					if(res.code) {
						this.resList = res.list
						console.log('this.resList',this.resList)
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.region {
		.rg-cotain {
			margin-bottom: 60px;
		}
	}
	
	
	
	@media (max-width:900px) {
		.region {
			.rg-cotain {
				flex-direction: column;
				.chirn {
					margin-top: 10px;
				}
			}
		}
	}
</style>
