<template>
	<!-- 详细信息 -->
	<div>
		<ul class="shdeal">
			<li>
				<h3>会员名称</h3>
				<h4>{{menber.title}}</h4>
			</li>
			<li>
				<h3>会员编号</h3>
				<h4>{{menber.number}}</h4>
			</li>
			<li>
				<h3>注册资本</h3>
				<h4>{{menber.capital}}</h4>
			</li>
			<li>
				<h3>入会时间</h3>
				<h4>{{menber.title}}</h4>
			</li>
			<li>
				<h3>主营业务</h3>
				<h4>{{menber.business}}</h4>
			</li>
			<li>
				<h3>企业介绍</h3>
				<h4>{{menber.introduce}}</h4>
			</li>
		</ul>
		<div class="sh-share">
			<p>分享到：</p>
			<ul>
				<li>
					<i class="iconfont">&#xe609;</i>
				</li>
				<li>
					<i class="iconfont">&#xe602;</i>
				</li>
				<li>
					<i class="iconfont">&#xe63d;</i>
				</li>
			</ul>
		</div>
	</div>
	
</template>

<script>
	export default {
		data() {
			return {
				menber:{}
			}
		},
		created() {
			let id = this.$route.query.id
			this.getMenById(id)
		},
		methods:{
			getMenById(id) {
				this.$axios.get("Member/getDetail",{
					params:{
						id
					}
				}).then(res=>{
					if(res.code) {
						this.menber = res.data
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.shdeal {
		li {
			h3 {
				color: rgba(10, 72, 144, 1);
				font-size: 16px;
				font-weight: 500;
				border-bottom: 2px solid rgba(10, 72, 144, 1);
				display: inline-block;
				padding-bottom: 10px;
			}
			h4 {
				color: rgba(51, 51, 51, 1);
				font-size: 16px;
				font-weight: 500;
				margin-top: 20px;
				margin-bottom: 30px;
				line-height: 30px;
			}
		}
	}
	.sh-share {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		p {
			color: rgba(102, 102, 102, 1);
			font-size: 16px;
			font-weight: 500;
		}
		ul {
			display: flex;
			li {
				width: 32px;
				height: 32px;
				border-radius: 50%;
				background: rgba(235, 236, 239, 1);
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 12px;
				i {
					color: #666666;
					font-size: 23px;
				}
			}
		}
	}
</style>