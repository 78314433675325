<!-- 新闻 -->
<template>
	<div class="news" id="news">
		<!-- 选项卡标签 -->
		<tap :taps="taps" :tapIndex="tapIndex" :tapsTrue="true" @clkTap="clkTap" @more="more" :bgColor="bgColor"></tap>
		<!-- 内容 -->
		<div class="cotain" v-if="newgg.length">
			<div class="swiper-container">
				<div class="swiper-wrapper">
					<div class="swiper-slide" v-for="(item,index) in newgg" :key="item.id" v-if="index<3" @click="newDail(item.id)">
						<img :src="item.thumb">
					</div>
				</div>
				<div class="tle" :style="{backgroundColor:bgColor}">
					<div>{{newgg[newIndex].title}}</div>
					<div class="circle">
						<div class="cle-item"  v-for="(item,index) in newgg" :key="item.id" v-if="index<3" :class="newIndex==index?'active':''"></div>
					</div>
				</div>
			</div>
			<div class="right-ctx">
				<div class="ctx-item" v-for="(item,index) in newgg" :key="item.id" v-if="index<5" @click="newDail(item.id)">
					<div class="ctx-tle">
						<span class="tle">{{item.title}}</span>
						<span class="time">{{item.inputtime}}</span>
					</div>
					<!-- <div class="cota">{{item.description}}</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import qs from "qs"
	import $ from "jquery"
	export default {
		data() {
			return {
				tapIndex:0,
				taps:[],
				// 新闻列表轮播图
				newIndex:0,
				newgg:[]
			}
		},
		
		props:{
			bgColor:{
				type:String,
				default:"var(--themeColor)"
			}
		},
		created() {
			this.getNav()
		},
		methods: {
			clkTap(index,id) {
				this.tapIndex = index
				this.catid = id
				this.getWord(id)
				
			},
			more(type) {
				this.$router.push({name:"newMore",query:{index:5,url:"Aboutus/news_center",catid:this.catid,tap:this.taps[this.tapIndex].catname}})
			},
			// 详情页
			newDail(id) {
				this.$bus.$emit("clkEntAil",["首页","新闻中心",'新闻详情'])
				this.$router.push({name:"newdetails",query:{index:5,id}})
			},
			// 查找新闻公告
			async getWord(catid) {
				let url = "Aboutus/news_center"
				let data = {
					catid,
					page:1,
					limit:6
				}
				let res = await this.$axios.post(url,qs.stringify(data))
				if(res.code) {
					this.newgg = res.data.data
					this.$nextTick(()=>{
						this.getSwiper()
					})
				}
			},
			
			// 获取导航条
			getNav() {
				this.$axios.post(this.$api.about.LeftSidebar,qs.stringify({nav_id:17})).then(res=>{
					if(res.code) {
						this.taps = res.list
						this.catid = res.list[0].id
						this.getWord(res.list[0].id)
					}
				})
			},
			
			// 轮播图
			getSwiper() {
				let that = this
				setTimeout(()=>{
					new Swiper('.swiper-container', {
						pagination: '.swiper-pagination', //分页器
						loop: true, //循环
						paginationClickable:true, //分页器点击
						autoplay: 5000, //时长
						speed:600,
						grabCursor:true,
						autoplayDisableOnInteraction : false,
						onSlideChangeEnd: function(swiper){
							if(that.newgg.length<=1) {
								return false
							}
							that.newIndex = swiper.realIndex
						}
					})
				},1000)
			},
			
			getCotain() {
				let letain = $("#news")
				let width = letain.outerWidth()/1440*100+'px'
				letain.css("font-size",width)
			}
		},
		mounted() {
			
			this.$nextTick(()=>{
				this.getCotain()
				$(window).resize(()=>{
					this.getCotain()
				})
			})
		}
		
	};
</script>

<style lang="less" scoped>
	.news {
		max-width: 1440px;
		margin: 0 auto;
		background-color: #f9fbfc;
		box-sizing: border-box;
		padding: 0.74em 0.4em 0.7em 0.4em;
		.cotain {
			display: flex;
			justify-content: space-between;
			.swiper-container {
				width: 5.24em;
				height: 3.51em;
				flex-shrink: 0;
				position: relative;
				.swiper-wrapper {
					.swiper-slide {
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}
				.tle {
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					color: white;
					font-size: 12px;
					font-weight: 400;
					z-index: 999;
					padding: 15px 16px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					&>div:first-child {
						// display: -webkit-box;
						overflow: hidden;
						// -webkit-box-orient: vertical;
						// -webkit-line-clamp: 1;
						width: 70%;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.circle {
						display: flex;
						.cle-item {
							width: 10px;
							height: 10px;
							border-radius: 50%;
							box-sizing: border-box;
							border: 1px solid white;
							margin-right: 5px;
							&:last-child {
								margin-right: 0;
							}
						}
						.active {
							background-color: #fff;
						}
					}
				}
			}
			.right-ctx {
				flex-grow: 1;
				font-size: 16px;
				display: grid;
				grid-template-rows: repeat(5,1fr);
				margin-left: 30px;
				.ctx-item {
					display: flex;
					flex-direction: column;
					justify-content: center;
					border-bottom: 0.5px solid rgba(235, 236, 239, 1);
					cursor: pointer;
					&:hover {
						.ctx-tle {
							.tle {
								color: var(--themeColor);
								font-weight: bold;
							}
							.time {
								color: var(--themeColor);
								font-weight: bold;
							}
						}
						.cota {
							color: var(--themeColor);
							font-weight: bold;
						}
					}
					.ctx-tle {
						display: flex;
						align-items: baseline;
						justify-content: space-between;
						.tle {
							color: rgba(51, 51, 51, 1);
							font-size: 18px;
							font-weight: 500;
							display: -webkit-box;
							overflow: hidden;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 1;
							padding-right: 60px;
							// text-overflow: ellipsis;
							// white-space: nowrap;
							line-height: 18px;
						}
						.time {
							color: rgba(154, 160, 171, 1);
							font-size: 14px;
							font-weight: 400;
							flex-shrink: 0;
						}
					}
					.cota {
						color: rgba(93, 103, 121, 1);
						font-size: 14px;
						line-height: 21px;
						font-weight: 400;
						margin-top: 10px;
						display: -webkit-box;
						overflow: hidden;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
					}
				}
			}
		}
	}
	
	@media (max-width:1200px) {
		.news {
			.cotain {
				flex-direction: column;
				.swiper-container {
					width: 100%;
					height: 6em;
				}
				.right-ctx {
					margin-left: 0;
					display: block;
					.ctx-item {
						padding: 15px 0;
					}
				}
			}
		}
	}
	
	@media (max-width:768px) {
		/deep/ .not-tap {
			margin-bottom: 20px;
			.tmore {
				i {
					font-size: 12px;
				}
			} 
		}
		.news {
			.cotain {
				.right-ctx {
					.ctx-item {
						.ctx-tle {
							.tle {
								font-size: 12px;
								
							}
							.time {
								font-size: 12px;
							}
						}
					}
				}
				.swiper-container {
					height: 8em;
					.tle {
						padding: 10px;
					}
				}
			}
		}
	}
</style>
