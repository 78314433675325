<!-- 手机端分页 -->
<template>
	<div class="block2">
		<div class="pri-pge">
			<div class="next" :style="{background:page==1?'#d6d9dd':''}" @click="pageNext"><i class="iconfont">&#xe635;</i></div>
			<div class="pref" :style="{background:page*perPage>total?'#d6d9dd':''}" @click="pagePref"><i class="iconfont">&#xe642;</i></div>
		</div>
	</div>
	
</template>

<script>
	export default {
		// 每页默认10条
		props:{
			// 当前分页数
			page:{
				type:Number,
				default:0
			},
			// 当前总数
			total:{
				type:Number,
				default:0
			},
			// 每页显示条数
			perPage:{
				type:Number,
				default:0
			}
		},
		methods:{
			// 点击上一页
			pageNext() {
				this.$emit("pageNext")
			},
			// 点击下一页
			pagePref() {
				this.$emit("pagePref")
			}
		}
		
	}
</script>

<style lang="less" scoped>
	.pri-pge {
		display: grid;
		grid-template-columns: repeat(2,1fr);
		&>div {
			text-align: center;
			padding: 20px 0;
			background-color: white;
			&.next {
				
				i {
					color: #9aa0ab;
				}
			}
			&.pref {
				border: 1px solid d6d9dd;
				i {
					color: #666666;
				}
			}
		}
	}
</style>