<template>
	<div class="tain">
		
		<div class="jingjiaT">竞价大厅</div>
		
		<iframe width="100%" height="300px" src="https://www.gsfae.cn/#/indexProject"></iframe>
		
		
		<letain :letain="letains" :time="2500">
			<titles tleText="培训路演" @tleMore="tleMore" class="let-tle">
				<div>
					了解更多
					<i class="iconfont">&#xe642;</i>
				</div>
			</titles>
		</letain>
	</div>
</template>

<script>
	import letain from "@/components/letain/letain"
	import $ from "jquery"
	import qs from "qs"
	import moment from "moment"
	export default {
		data() {
			return {
				letains:[]
			}
		},
		components:{
			letain
		},
		created() {
			this.GetTrainList()
		},
		methods:{
			tleMore() {
				this.$router.push({path:"/train/roadshow",query:{index:1}})
			},
			getCotain() {
				let letain = $(".tain")
				let width = letain.outerWidth()/1440*100+'px'
				letain.css("font-size",width)
			},
			// 获取列表详情
			GetTrainList() {
				let url = this.$api.train.GetTrainList
				let data = {
					nav_id:25,
					clas_id:36,
					type:1,
					limit:4,
					page:1
				}
				this.$axios.post(
					url,
					qs.stringify(data)
				).then(res=>{
					if(res.code) {
						if(res.data[0].list.data.length!=0) {
							res.data[0].list.data.forEach(item=>{
								item.inputtime = moment(parseInt(item.inputtime*1000)).format("YYYY-MM-DD")
							})
						}
						this.letains = res.data[0].list.data
					}
				})
			}
		},
		mounted() {
			$(document).ready(()=>{
				this.getCotain()
				$(window).resize(()=>{
					this.getCotain()
				})
			})
		}
	}
</script>

<style lang="less" scoped>
	
	.link-href {
		display: block;
		width: 100%;
		height: 300px;
		z-index: 99;
	}
	
	.jingjiaT {
		padding: 20px 0;
		color: #0A4890;
		font-size: 24px;
		font-family: SourceHanSansCN;
		font-weight: bold;
		position: relative;
	}
	
	.tain {
		padding-top: 75px;
		padding: 40px;
		box-sizing: border-box;
		max-width: 1440px;
		margin: 0 auto;
		background-color: #fff;
		.let-tle {
			margin-bottom: 36px;
		}
	}
	@media (max-width:1000px) {
		.tain {
			padding: 40px 20px;
		}
	}
	@media (max-width:768px) {
		.tain {
			padding:  0 10px;
			padding-top: 10px;
			.let-tle {
				margin-bottom: 20px;
			}
		}
		/deep/ .moret {
			font-size: 14px;
			.iconfont {
				font-size: 12px;
			}
		}
	}
</style>