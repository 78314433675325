import { render, staticRenderFns } from "./tap.vue?vue&type=template&id=58c78608&scoped=true&"
import script from "./tap.vue?vue&type=script&lang=js&"
export * from "./tap.vue?vue&type=script&lang=js&"
import style0 from "./tap.vue?vue&type=style&index=0&id=58c78608&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58c78608",
  null
  
)

export default component.exports