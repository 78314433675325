<!-- 产权业务首页 -->
<template>
	<div>
		<!-- 导航栏 -->
		<homenav navTag="home"></homenav>
		<!-- 轮播图 -->
		<swiper></swiper>
		<!-- 选项-->
		<options></options>
		<!-- 交易公告和重点项目推荐 -->
		<more></more>
		<!-- 项目公告 -->
		<!-- <item></item> -->
		<!-- 竞价大厅 -->
		
		<!-- <hall></hall> -->
		
		
		<!-- 底部介绍 -->
		<bottom></bottom>
	</div>
</template>

<script>
	// 轮播图
	import swiper from "@/components/home/swiper"
	// 选项
	import options from "@/components/home/options"
	//交易公告和重点项目推荐
	import more from "@/components/home/more"
	//项目公告
	import item from "@/components/home/item"
	//竞价大厅
	import hall from "@/components/home/hall"
	import $ from 'jquery'
	export default {
		components: {
			swiper,
			options,
			more,
			item,
			hall
		},
		mounted() {

		}
	}
</script>

<style lang="less" scoped>
	body {
		background-color: #f2f2f2;
	}
</style>
