<!-- 公告列表 -->
<template>
	<div class="de-box">
		<template v-if="list.length!=0">
			<div class="deal" v-for="(item,index) in list.data" :key="index" @click="clkTableRow(item.id)">
				<span class="text">
					<img src="../../assets/images/trading/logo.png" alt="">
					{{item.title}}</span>
				<span class="data">{{item.date}}</span>
			</div>
		</template>

		<template v-else>
			<el-empty description="暂无数据"></el-empty>
		</template>

		<div class="block">
			<el-pagination background :page-size="list.per_page" layout="total, prev, pager, next, jumper"
				:total="list.total" @current-change="curChge">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import moment from "moment"
	export default {
		data() {
			return {
				page: 1,
				list: {}
			};
		},
		props: {
			left_id: {
				type: Number,
				default: 0
			}
		},
		watch: {
			left_id(news, old) {
				this.noticeList(news)
			}
		},
		created() {
			this.noticeList(this.left_id)
		},
		methods: {
			// 点击公告详情
			clkTableRow(id) {
				let index = this.$route.query.index
				this.$router.push({
					path: "/trading/annodetail",
					query: {
						index,
						id
					}
				})
			},
			// 获取公告列表
			noticeList(catid) {
				let url = this.$api.trading.noticeList
				let data = {
					catid,
					page: this.page
				}
				this.$axios.get(
					url, {
						params: data
					}
				).then(res => {
					if (res.code) {
						res.data.data.forEach(item => {
							item.inputtime = moment(item.inputtime * 1000).format("YYYY-MM-DD")
						})
						this.list = res.data
					}
				})
			},
			curChge(e) {
				this.page = e
				this.noticeList(this.left_id)
			}
		}
	}
</script>

<style lang="less" scoped>
	.de-box {
		.deal {
			border-bottom: 0.5px solid rgba(235, 236, 239, 1);
			height: 88px;
			color: rgba(102, 102, 102, 1);
			font-size: 16px;
			font-weight: 400;
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;

			&:hover {
				background-color: #f5f7fa;
			}

			.text {
				line-height: 26px;
				flex: auto;

				img {
					width: 26px;
				}
			}

			.date {
				float: right;
				width: 90px;
			}
		}

		.number {
			margin: 60px 0 30px;
			text-align: right;
		}

		.number-n {
			display: none;
			text-align: right;
			padding: 10px 0;
		}
	}

	@media (max-width:768px) {
		.de-box {
			padding: 0 10px;

			.deal {
				height: 62px;
				font-size: 14px;

				.text {
					flex: 0 0 223px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}

				.data {
					flex: 0 0 78px;
				}
			}

			.number {
				display: none;
			}

			.number-n {
				display: block;
			}
		}
	}
</style>
