<!-- 股权质押 -->
<template>
	<ul class="register">
		<li v-for="item in resList" :key="item.id">
			<p class="img">
				<img :src="item.thumb" alt="">
			</p>
			<div class="re-ctx">
				<h3 class="tle">{{item.catname}}</h3>
				<p class="re-cotain">{{item.description}}</p>
				<div class="more" @click="clkService(item.id)">
					了解更多<i class="iconfont">&#xe642;</i>
				</div>
			</div>
		</li>
	</ul>
</template>

<script>
	export default {
		props:{
			resList:{
				type:Array,
				default:()=>[]
			}
		},
		data() {
			return {
				
			}
		},
		methods:{
			// 业务介绍
			clkService(id) {
				if(id==56) {
					this.$router.push({name:'service',query:{index:2,id}})
				}else if(id==57) {
					this.$router.push({name:'means',query:{index:2,id}})
				}	
			}
		}
	}
</script>

<style lang="less" scoped>
	
</style>