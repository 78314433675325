<template>
	<div class="cupon">
		<newList @clkCup="clkCup" :policy="policy.data"></newList>
		<!-- 分页 -->
		<template v-if="policy.data.length">
			<div class="block">
				<el-pagination background :page-size="policy.per_page" layout="total, prev, pager, next, jumper"
					:total="policy.total" @current-change="changePg">
				</el-pagination>
			</div>
			<div class="block2">
				<phonePg :page="page" :total="policy.total" :perPage="policy.per_page" @pageNext="pageNext"
					@pagePref="pagePref"></phonePg>
			</div>
		</template>
		<template v-else>
			<el-empty description="暂无数据"></el-empty>
		</template>
	</div>
</template>

<script>
	import newList from "@/components/new/newList"
	import phonePg from "@/components/phonePg/phonePg"
	export default {
		data() {
			return {

			}
		},
		components: {
			newList,
			phonePg
		},
		props: {
			// 政策数据
			policy: {
				type: Object,
				default: () => {}
			},
			// 当前页
			page: {
				type: Number,
				default: 0
			}
		},
		created() {

		},
		methods: {
			clkCup(index, id) {
				this.$router.push({
					path: "/policy/poldeal",
					query: {
						index,
						id
					}
				})
			},
			// 改变page
			changePg(e) {
				this.$emit("changePg", e)
			},
			// 上一页
			pageNext() {
				if (this.page - 1 == 0) {
					return false
				}
				this.$emit("changePg", this.page - 1)
			},
			// 下一页
			pagePref() {
				if ((this.page + 1) * this.policy.per_page > this.policy.total) {
					return false
				}
				this.$emit("changePg", this.page + 1)
			}
		}
	}
</script>

<style lang="less" scoped>
	@media (max-width:900px) {
		.cupon {
			padding: 10px 0px 0 0px;
		}
	}
</style>
