<template>
	<div class="titleh">
		<div class="tle">{{tleText}}</div>
		<div class="moret" @click="tleMore">
			<slot style="cursor: pointer;"></slot>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			tleText:{
				type:String,
				default:""
			}
		},
		methods:{
			tleMore() {
				this.$emit("tleMore")
			}
		}
	}
</script>

<style lang="less">
	.titleh {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.titleh .tle {
		color: var(--themeColor);
		font-size: 24px;
		font-family: SourceHanSansCN;
		font-weight: bold;
		position: relative;
		&:hover{
			color: var(--themeColor);
		}
	}
	.moret {
		font-size: 16px;
		// font-family: SourceHanSansCN;
		// font-weight: 300;
		// color: #555555;
		cursor: pointer;
		&:hover{
			color: var(--themeColor);
		}
	}
	
	@media (max-width:500px) {
		.titleh {
			.tle {
				font-size: 20px;
			}
			.more {
				font-size: 14px;
				i {
					font-size: 12px;
				}
			}
		}
	}
</style>