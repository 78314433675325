<!-- 企业挂牌展示 -->
<template>
	<div>
		<!-- 搜索标签栏 pc端-->
		<div class="search">
			<div class="sea-item" v-for="(item,index) in typeList" :key="index">
				<div class="sea-tle">
					<div class="tle">{{item.catname}}</div>
				</div>
				<ul class="tle-item">
					<li 
						:class="[index==0 && item2.id==plate?'active':'',
								index==1 && item2.id==industry?'active':'',
								index==2 && item2.id==capital?'active':'',
								index==3 && item2.id==city?'active':'']"
						v-for="(item2,index2) in item.child" 
						:key="index2" @click.stop="clkItem(index,item2.id)">{{item2.title}}</li>
				</ul>
			</div>
		</div>

		<!-- 手机端 筛选 -->
		<div class="search2">
			<div class="all">
				<el-dropdown trigger="click">
					<span class="el-dropdown-link">全部</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item>黄金糕</el-dropdown-item>
						<el-dropdown-item>狮子头</el-dropdown-item>
						<el-dropdown-item>螺蛳粉</el-dropdown-item>
						<el-dropdown-item disabled>双皮奶</el-dropdown-item>
						<el-dropdown-item divided>蚵仔煎</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>

			<div class="saix">
				<el-dropdown trigger="click">
					<span class="el-dropdown-link">
						筛选<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item>黄金糕</el-dropdown-item>
						<el-dropdown-item>狮子头</el-dropdown-item>
						<el-dropdown-item>螺蛳粉</el-dropdown-item>
						<el-dropdown-item disabled>双皮奶</el-dropdown-item>
						<el-dropdown-item divided>蚵仔煎</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<!-- 插槽 -->
		<slot></slot>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				typeList:[],
				// 当前选中的
				plate:0, // 所属板块
				industry:0, // 行业分类
				capital:0, // 注册资本
				city:0 // 所在城市
			}
		},
		props: {
			themeColor: {
				type: String,
				default: ""
			},
		},
		created() {
			let type = this.$route.query.type
			if (type == 1) {
				// 专精特新版
				this.getEnterpriseParam(184)
			} else if (type == 2) {
				// 科技创新版	
				this.getEnterpriseParam(127)
			} else if (type == 3) {
				// 交易版
				this.getEnterpriseParam(135)
			}else {
				this.getEnterpriseParam(135)
			}
		},
		methods: {
			// 点击分类进入详情页
			clkEntAil(index) {
				this.$emit("clkEntAil", index)
			},
			//获取企业专区筛选列表
			async getEnterpriseParam(catid) {
				let res = await this.$axios.get("Specialization/getEnterpriseParam", {
					params: {
						catid,
					}
				})
				if (res.code) {
					res.data.forEach((item,index)=>{
						let obj = {
							title:"全部",
							id:0
						}
						item.child.unshift(obj)
					})
					this.typeList = res.data
				}
			},
			// 点击每一项
			clkItem(index,id) {
				if(index==0) {
					this.plate = id
				}else if(index==1) {
					this.industry = id
				}else if(index==2) {
					this.capital = id
				}else if(index==3) {
					this.city = id
				}
				this.$emit("clkItem",index,id)
			}
		}
	}
</script>

<style lang="less" scoped>
	// 搜索标签栏
	.search {
		.sea-item {
			display: flex;
			color: rgba(51, 51, 51, 1);
			font-size: 16px;
			font-weight: 400;
			align-items: flex-start;
			padding: 19px 0 9px 0;
			border-bottom: 0.5px solid rgba(235, 236, 239, 1);

			.sea-tle {
				padding: 6px 0;
				flex-shrink: 0;
				margin-right: 40px;
				display: flex;
				align-items: center;
				position: relative;

			}

			&:first-child {
				padding-top: 0;
			}

			.tle {
				position: relative;
				padding-left: 9px;

				&::before {
					content: '';
					display: block;
					width: 3px;
					background-color: var(--themeColor);
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
				}
			}

			.tle-item {
				display: flex;
				flex-wrap: wrap;

				li {
					margin-right: 32px;
					box-sizing: border-box;
					padding: 5px 12px;
					padding-left: 0;
					color: rgba(102, 102, 102, 1);
					font-size: 16px;
					font-weight: 400;
					cursor: pointer;
					flex-shrink: 0;
					margin-bottom: 10px;

					&:last-child {
						margin-right: 0;
					}
					padding-left: 12px;
					border: 1px solid transparent;

					&.active {
						border: 1px solid rgba(10, 72, 144, 1);
						color: rgba(10, 72, 144, 1);
						
					}
				}
			}
		}
	}

	// 搜索标签栏2
	.search2 {
		display: none;
	}


	@media (max-width:768px) {
		.search {
			display: none;
		}
		
		
		
		.search2 {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #f2f2f2;
			padding-bottom: 19px;

			.all {
				.el-dropdown-link {
					color: var(--themeColor);
					padding: 5px 10px;
					border: 1px solid currentColor;
					cursor: pointer;
					display: block;
					font-size: 16px;
				}
			}

			.saix {
				.el-dropdown-link {
					color: #999;
					font-size: 16px;
					cursor: pointer;
				}

				.el-icon-arrow-down {
					font-size: 16px;
				}
			}
		}
	}
</style>
