<template>
	<ul class="curmbs">
		<li>当前位置：</li>
		<li v-for="(item,index) in crumbs" :key="index" @click="clkItem(index)">{{item}}<i class="iconfont">&#xe642;</i></li>
	</ul>
</template>

<script>
	import $ from "jquery"
	export default {
		props:{
			crumbs:{
				type:Array,
				default:null
			}
		},
		mounted() {
		},
		methods:{
			clkItem(index) {
				this.$emit("clkItem",index)
			}
		}
	}
</script>

<style lang="less">
	.curmbs {
		display: flex;
		font-size: 14px;
		align-items: center;
		padding: 16px 0;
		color: #666666;
		li {
			flex-shrink: 0;
			cursor: pointer;
			&:hover{
				color: var(--themeColor);
			}
		}
		li:last-child {
			.iconfont {
				display: none;
			}
		}
		.iconfont {
			font-size: 12px;
			margin: 0 5px;
		}
	}
</style>