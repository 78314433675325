<!-- 产权详情 -->
 <template>
 	<div class="options-details">
 		<div class="op">
 			<div class="one">
 				<div class="op-left">
 					<img class="big" src="../../assets/images/bidding/big.png" alt="">
 					<div class="img-box">
 						<img class="arrow" src="../../assets/images/bidding/left.png" alt="">
 						<img class="opt-photo" src="../../assets/images/bidding/big.png" alt="">
 						<img class="opt-photo" src="../../assets/images/bidding/big.png" alt="">
 						<img class="opt-photo" src="../../assets/images/bidding/big.png" alt="">
 						<img class="opt-photo" src="../../assets/images/bidding/big.png" alt="">
 						<img class="arrow" src="../../assets/images/bidding/right.png" alt="">
 					</div>
 				</div>
 				<div class="op-right">
 					<div class="content">
 						<div class="title">蓝天科技股份80%转让</div>
 						<div class="opt-number">项目编号：ZZCQ2021081900044</div>
 						<div class="state">
 							<img src="../../assets/images/bidding/bmzt.png" alt="">
 							<input v-model="input" :disabled="true" placeholder="当前产权已禁止报名"></input>
 						</div>
 						<div class="opt-box">
 							<div class="opt-price">挂牌价格</div>
 							<div class="money">200万元</div>
 							<div class="opt">
 								<div class="opt-price">保证金</div>
 								<div class="money">120000.00元</div>
 							</div>
 							<div class="opt-price">所属地区</div>
 							<div class="money">甘肃</div>
 						</div>
 						<div class="apply">
 							<button type="info" plain disabled>报名</button>
 						</div>
 					</div>
 					<div class="text">
 						<div class="te-left">公告发布起始日期</div>
 						<div class="te-right">2022-04-18 09:00:00 ~ 2022-05-16 17:00:00</div>
 					</div>
 				</div>
 			</div>
 			<div class="process">
 				<!-- <div class="steps">
					<div class="st-text">项目流程</div>
				</div>
				<div class="steps" v-for="(item,index) in stepData" :key="index">
					<img class="img" src="../../assets/images/bidding/arrow.png" alt="">
					<img :src="require(`../../assets/images/bidding/${index}.png`)" alt="">
					<div class="steps-text">
						<div class="on-text">{{item.number}}</div>
						<div class="tw-text">{{item.name}}</div>
					</div>
				</div> -->
 				<img class="liu" src="../../assets/images/bidding/max.png" alt="">
 				<img class="liumin" src="../../assets/images/bidding/min.png" alt="">
 			</div>
 		</div>
 		<div class="two">
 			<tap :taps="taps" :tapIndex="tapIndex" :isMore="isMore" @clkTap="clkTap"></tap>
 			<div class="line"><div class="li-name"> 标的名称 </div> <div class="date"> 蓝天科技股份80%转让 </div></div>
 			<div class="line"><div class="li-name"> 转让方名称 </div> <div class="date"> 白云有限 </div></div>
 			<div class="line"><div class="li-name"> 公司类型(经济性质) </div> <div class="date"> 有限责任公司 </div></div>
 			<div class="line"><div class="li-name"> 预告日期 </div> <div class="date"> 2021-08-20至2021-08-20 </div></div>
 			<div class="line"><div class="li-name wmax"> 标的企业基本情况 </div></div>
 			<div class="line"><div class="li-name"> 所属行业 </div> <div class="date"> 科技推广和应用服务业 </div></div>
 			<div class="line"><div class="li-name"> 成立时间 </div> <div class="date"> 2022-05-05 </div></div>
 			<div class="line"><div class="li-name"> 注册地 </div> <div class="date"> 甘肃 </div></div>
 			<div class="line"><div class="li-name"> 公司类型(经济性质) </div> <div class="date"> 股份有限公司 </div></div>
 			<div class="line"><div class="li-name"> 统一社会信用代码 </div> <div class="date"> 916201000000000011 </div></div>
 			<div class="line"><div class="li-name"> 经营范围 </div> <div class="date"> 经营范围 </div></div>
 			<div class="line"><div class="li-name"> 注册资本 </div> <div class="date"> 10000000.00元 </div></div>
 			<div class="line"><div class="li-name"> 职工人数 </div> <div class="date"> 10 </div></div>
 			<div class="line"><div class="li-name wmax"> 标的企业股权结构 </div></div>
 			<div class="line"><div class="li-name w"> 序号 </div><div class="li-name w">前十位股东名称</div><div class="li-name w">持股比例 (%)</div></div>
 			<div class="line"><div class="date w"> 1 </div><div class="date w"> 白云有限 </div><div class="date w"> 80 </div></div>
 			<div class="line"><div class="date w"> 2 </div><div class="date w"> 黑土地 </div><div class="date w"> 20 </div></div>
 			<div class="line"><div class="li-name wmax"> 主要财务数据指标 </div></div>
 			<div class="line"><div class="date wmax"> 以下数据出自业务无法提供年度审计报告(单位：人民币万元) </div></div>
 			<div class="line"><div class="date wmax"> 审计机构 </div></div>
 			<div class="line"><div class="li-name w"> 主营业务收入 </div><div class="li-name w"> 利润总额 </div><div class="li-name w"> 净利润 </div></div>
 			<div class="line"><div class="li-name w"> --</div><div class="li-name w"> -- </div><div class="li-name w"> -- </div></div>
 			<div class="line"><div class="li-name w"> 资产总额 </div><div class="li-name w"> 负债总额 </div><div class="li-name w"> 所有者权益 </div></div>
 			<div class="line"><div class="li-name w"> -- </div><div class="li-name w"> -- </div><div class="li-name w"> -- </div></div>
 			<div class="line"><div class="date wmax"> 以下数据出自1970年01月01日的财务报表(单位：人民币万元) </div></div>
 			<div class="line"><div class="li-name w"> 主营业务收入 </div><div class="li-name w"> 主营业务收入 </div><div class="li-name w"> 主营业务收入 </div></div>
 			<div class="line"><div class="li-name w"> 53212 </div><div class="li-name w"> 53212 </div><div class="li-name w"> 53212 </div></div>
 			<div class="line"><div class="li-name w"> 资产总额 </div><div class="li-name w"> 负债总额 </div><div class="li-name w"> 所有者权益 </div></div>
 			<div class="line"><div class="li-name w"> 53212 </div><div class="li-name w"> 53212 </div><div class="li-name w"> 53212 </div></div>
 			<div class="line"><div class="li-name"> 转让行为内部决策情况 </div> <div class="date"> 已审议 </div></div>
 			<div class="line"><div class="li-name"> 转让行为批准情况 </div> <div class="date"> 转让行为批准情况 </div></div>
 			<div class="line"><div class="li-name"> 受让方资格条件 </div> <div class="date"> 随意 </div></div>
 			<div class="line"><div class="li-name"> 其他说明 </div> <div class="date"> 暂无 </div></div>
 			<div class="line"><div class="li-name"> 联系方式 </div> <div class="date"> 张三-13893175456 </div></div>
 		</div>
 	</div>
 </template>

 <script>
 	export default {
 		data() {
 			return {
 				taps: ['挂牌信息', '成交公告'],
 				tapIndex: 0,
 				isMore: false,
 				input: '',
 				stepData: [{
 						number: "第一步",
 						name: "阅读公告",
 					},
 					{
 						number: "第二步",
 						name: "报名登记",
 					},
 					{
 						number: "第三步",
 						name: "交保险金",
 					},
 					{
 						number: "第四步",
 						name: "参与报价",
 					},
 					{
 						number: "第五步",
 						name: "成交确认",
 					},
 					{
 						number: "第六步",
 						name: "资金结算",
 					},
 					{
 						number: "第七步",
 						name: "办理交割",
 					}
 				]
 			}
 		},
 		methods: {
 			clkTap(index) {
 				this.tapIndex = index;
 			}
 		}
 	}
 </script>

 <style lang="less" scoped>
 	.options-details {
 		max-width: 1440px;
 		margin: 0 auto;

 		.op {
 			background-color: #fff;
 			padding: 30px;

 			.one {
 				display: flex;

 				.op-left {
 					flex: 0 0 44%;
 					margin-right: 3%;

 					.big {
 						border: 0.5px solid rgba(214, 217, 221, 1);
 						width: 100%;
 						margin-bottom: 3%;
 					}

 					.img-box {
 						width: 100%;
 						align-items: baseline;

 						.arrow {
 							width: 6.9%;
 						}

 						.opt-photo {
 							width: 20.1%;
 							margin: 0 0.5%;
 							border: 0.5px solid rgba(214, 217, 221, 1);
 						}

 						.opt-photo:hover {
 							border: 1px solid rgba(10, 72, 144, 1);
 						}
 					}
 				}

 				.op-right {
 					flex: 0 0 53%;
 					display: flex;
 					flex-direction: column;

 					.content {
 						border-bottom: 0.5px solid rgba(235, 236, 239, 1);
 						float: right;
 						padding: 3% 0 6%;
 						color: rgba(51, 51, 51, 1);
 						width: 100%;

 						.title {
 							line-height: 47px;
 							font-size: 32px;
 						}

 						.opt-number {
 							line-height: 24px;
 							font-size: 16px;
 							margin: 3% 0;
 						}

 						.state {
 							width: 400px;

 							img {
 								width: 56px;
 							}

 							input {
 								height: 20px;
 								padding: 16px;
 								color: rgba(153, 153, 153, 1);
 								font-size: 16px;
 								background: rgba(234, 235, 237, 1);
 								border: 1px solid rgba(219, 219, 219, 1);
 								border-left: none;
 							}
 						}

 						.opt-box {
 							padding: 5% 0;
 							font-size: 16px;
 							line-height: 24px;

 							.opt-price {
 								display: inline-block;
 								color: rgba(102, 102, 102, 1);
 								width: 70px;
 							}

 							.money {
 								display: inline;
 								color: rgba(51, 51, 51, 1);
 								font-weight: 500;
 							}

 							.opt {
 								margin: 12px 0;

 								.opt-price {
 									color: rgba(102, 102, 102, 1);
 								}

 								.money {
 									display: inline;
 									color: rgba(51, 51, 51, 1);
 								}
 							}
 						}

 						.apply button {
 							width: 160px;
 							height: 56px;
 							color: rgba(153, 153, 153, 1);
 							font-size: 16px;
 							border: none;
 							display: block;
 							background: rgba(234, 235, 237, 1);
 						}
 					}

 					.text {
 						padding: 38px 0;
 						float: left;
 						font-size: 16px;

 						.te-left {
 							display: inline-block;
 							color: rgba(102, 102, 102, 1);

 						}

 						.te-right {
 							display: inline-block;
 							color: rgba(51, 51, 51, 1);
 							margin-left: 16px;
 							font-weight: 500;
 						}
 					}
 				}
 			}

 			.process {
 				// display: flex;
 				width: 100%;
 				margin-top: 60px;

 				.liu {
 					width: 100%;
 				}

 				.liumin {
 					display: none;
 				}

 				.steps {
 					width: 132px;
 					height: 57px;
 					background: rgba(213, 232, 247, 1);
 					float: left;
 					padding: 14px 21px 9px 16px;
 					color: rgba(10, 72, 144, 1);
 					font-size: 16px;
 					position: relative;

 					.img {
 						width: 24px;
 						position: absolute;
 						top: 28px;
 						left: -14px;
 					}

 					img {
 						width: 40px;
 						margin-bottom: 11px;
 					}

 					.st-text {
 						line-height: 57px;
 						font-weight: 500;
 						font-size: 22px;
 					}

 					.steps-text {
 						display: inline-block;
 						margin-left: 4px;

 						.on-text {
 							font-size: 16px;
 							line-height: 24px;
 							font-weight: 400;
 						}

 						.tw-text {
 							font-size: 22px;
 							line-height: 33px;
 							font-weight: 500;
 						}
 					}
 				}

 				.steps:not(:last-child) {
 					margin-right: 4px;
 				}

 				.steps:first-child {
 					padding: 11.5px 40.5px;
 					width: 88px;
 					text-align: center;
 				}
 			}
 		}


 		.two {
 			margin-top: 20px;
 			padding: 20px 30px 80px;
 			background-color: #fff;

 			.line {
 				border-bottom: 0.5px solid rgba(235, 236, 239, 1);
 				display: flex;
 				line-height: 24px;
 				color: rgba(102, 102, 102, 1);
 				font-size: 16px;
 				font-weight: 400;
 				padding: 28px 0;

 				.li-name {
 					width: 20%;
 				}

 				.date {
 					color: rgba(51, 51, 51, 1);
 				}
				
				.w{
					width: 30%;
					text-align: center;
				}
				
				.wmax{
					width: 100%;
				}
 			}
 		}

 		// .row {
 		// 	height: 80px;
 		// 	border-bottom: 0.5px solid rgba(235, 236, 239, 1);
 		// 	font-size: 16px;
 		// 	font-weight: 400;

 		// 	.qqq {
 		// 		color: rgba(102, 102, 102, 1);
 		// 		width: 228px;
 		// 		display: inline-block;
 		// 		line-height: 80px;
 		// 	}

 		// 	.www {
 		// 		color: rgba(51, 51, 51, 1);
 		// 		display: inline-block;
 		// 		line-height: 80px;
 		// 	}

 		// 	.wid {
 		// 		width: 280px;
 		// 	}

 		// 	.wid_s {
 		// 		width: 182px;
 		// 	}

 		// 	.ble {
 		// 		border-left: 0.5px solid rgba(235, 236, 239, 1);
 		// 	}

 		// 	.bletr {
 		// 		border-left: 0.5px solid transparent;
 		// 	}

 		// 	.ple {
 		// 		padding-left: 60px;
 		// 	}
 		// }

 		// .hei {
 		// 	width: 228px;
 		// 	height: 728px;
 		// 	line-height: 728px;
 		// }

 		// .dis {
 		// 	display: inline-block;
 		// 	float: left;
 		// }

 		// .wid {
 		// 	width: 1152px;
 		// }

 		// .ti-text {
 		// 	color: rgba(6, 43, 112, 1);
 		// 	font-size: 22px;
 		// 	font-weight: 700;
 		// 	margin-right: 40px;
 		// 	line-height: 33px;
 		// 	padding-bottom: 16px;
 		// }

 		// &.active {
 		// 	border-bottom: 4px solid rgba(6, 43, 112, 1);
 		// }
 	}

 	/deep/.not-tap {
 		margin-bottom: 20px;

 		&::after {
 			height: 1px;
 		}

 		.not .not-item {
 			color: rgba(93, 103, 121, 1);
 			font-size: 22px;
 			font-weight: 400;

 			&.active {
 				color: rgba(10, 72, 144, 1);
 				font-size: 22px;
 				font-weight: 700;
 				border-bottom: 4px solid rgba(10, 72, 144, 1);
 			}
 		}
 	}

 	@media (max-width:900px) {
 		.options-details .op {
 			padding: 15px;

 			.one {
 				align-content: space-between;
 				flex-wrap: wrap;

 				.op-left {
 					flex: 0 0 100%;
 					margin: 0;

 					.big {
 						border: 0.25px solid rgba(214, 217, 221, 1);
 					}

 					.arrow {
 						width: 6.8%;
 					}

 					.opt-photo {
 						width: 19.9%;
 					}
 				}

 				.op-right {
 					flex: 0 0 100%;

 					.content {
 						.title {
 							font-size: 24px;
 							line-height: 35.5px;
 						}

 						.opt-number {
 							font-size: 13px;
 							line-height: 19px;
 						}

 						.opt-box {
 							font-size: 13px;
 							line-height: 19px;

 						}
 					}

 					.text {
 						padding: 15px 0;
 						font-size: 13px;
 						line-height: 19px;

 						.te-right {
 							margin: 6px 0 0;
 							display: block;
 						}
 					}
 				}
 			}

 			.process {
 				margin-top: 30px;

 				.liu {
 					display: none;
 				}

 				.liumin {
 					display: block;
 					width: 100%;
 				}
 			}
 		}
 	}

 	@media (max-width:768px) {
 		.options-details{
			.op .one .op-left .img-box .opt-photo {
				width: 20%;
			}
			.two{
				margin-top: 10px;
				padding: 10px 15px 40px;
				.line{
					padding: 14px 0;
					border-bottom: 0.25px solid rgba(235, 236, 239, 1);
					line-height: 19px;
					font-size: 13px;
				}
			}
		} 
 	}

 	@media (max-width:450px) {
 		.options-details .op .one .op-left .img-box .opt-photo {
 			width: 19.7%;
 		}
 	}
 </style>
 <!-- <div class="row">
					<div class="qqq"> 标的名称 </div>
					<div class="www ple"> 蓝天科技股份80%转让 </div>
				</div>
				<div class="row">
					<div class="qqq"> 转让方名称 </div>
					<div class="www ple"> 白云有限 </div>
				</div>
				<div class="row">
					<div class="qqq"> 公司类型（经济性质） </div>
					<div class="www ple"> 有限责任公司 </div>
				</div>
				<div class="row">
					<div class="qqq"> 预告日期 </div>
					<div class="www ple"> 2021-08-20至2021-08-20 </div>
				</div>
				<div class="row hei dis">
					<div class="qqq"> 标的企业基本情况 </div>
				</div>
				<div class="row wid">
					<div class="www wid ble ple"> 所属行业 </div>
					<div class="www ble ple"> 科技和应用推广服务业 </div>
				</div>
				<div class="row">
					<div class="www wid ble ple"> 成立时间 </div>
					<div class="www ble ple"> 2021-08-01 </div>
				</div>
				<div class="row">
					<div class="www wid ble ple"> 注册地 </div>
					<div class="www ble ple"> 甘肃 </div>
				</div>
				<div class="row">
					<div class="www wid ble ple"> 公司类型（经济性质） </div>
					<div class="www ble ple"> 股份有限公司 </div>
				</div>
				<div class="row">
					<div class="www wid ble ple"> 统一社会信用代码 </div>
					<div class="www ble ple"> 916201000000000011 </div>
				</div>
				<div class="row">
					<div class="www wid ble ple"> 经营范围 </div>
					<div class="www ble ple"> 经营范围 </div>
				</div>
				<div class="row">
					<div class="www wid ble ple"> 注册资本 </div>
					<div class="www ble ple"> 10000000.00元 </div>
				</div>
				<div class="row">
					<div class="www wid ble ple"> 法定代表人 </div>
					<div class="www ble ple"> 张三 </div>
				</div>
				<div class="row">
					<div class="www wid ble ple"> 职工人数 </div>
					<div class="www ble ple"> 10 </div>
				</div>
				<div class="row">
					<div class="qqq"></div>
					<div class="qqq wid ple bletr"> 序号 </div>
					<div class="qqq wid ple ble"> 前十位股东名称 </div>
					<div class="qqq wid ple ble"> 持股比例(%) </div>
				</div>
				<div class="row">
					<div class="qqq">标的企业股权结构</div>
					<div class="www wid ple bletr"> 1 </div>
					<div class="www wid ple ble"> 白云有限 </div>
					<div class="www wid ple ble"> 80 </div>
				</div>
				<div class="row">
					<div class="qqq"></div>
					<div class="www wid ple bletr"> 2 </div>
					<div class="www wid ple ble"> 黑土地 </div>
					<div class="www wid ple ble"> 20 </div>
				</div>
				<div class="row">
					<div class="qqq"></div>
					<div class="www ple">以下数据出自业务无法提供年度审计报告（单位：人民币万元）</div>
				</div>
				<div class="row">
					<div class="qqq"></div>
					<div class="www ple"> 审计机构 </div>
				</div>
				<div class="row">
					<div class="qqq"></div>
					<div class="qqq wid ple bletr"> 主营业务收入 </div>
					<div class="qqq wid ple ble"> 利润总额 </div>
					<div class="qqq wid ple ble"> 净利润 </div>
				</div>
				<div class="row">
					<div class="qqq"></div>
					<div class="qqq wid ple bletr"> 资产总额 </div>
					<div class="qqq wid ple ble"> 负债总额 </div>
					<div class="qqq wid ple ble"> 所有者权益 </div>
				</div>
				<div class="row">
					<div class="qqq">主要财务数据指标</div>
					<div class="www ple">以下数据出自业务无法提供年度审计报告（单位：人民币万元）</div>
				</div>
				<div class="row">
					<div class="qqq"></div>
					<div class="qqq wid ple bletr"> 主营业务收入 </div>
					<div class="qqq wid ple ble wid_s"> 利润总额 </div>
					<div class="qqq wid ple ble"> 净利润 </div>
				</div>
				<div class="row">
					<div class="qqq"> </div>
					<div class="www wid ple bletr"> 53212 </div>
					<div class="www wid ple ble wid_s"> 53212 </div>
					<div class="www wid ple ble"> 53212 </div>
				</div>
				<div class="row">
					<div class="qqq"></div>
					<div class="qqq wid ple bletr"> 资产总额 </div>
					<div class="qqq wid ple ble wid_s"> 负债总额 </div>
					<div class="qqq wid ple ble"> 所有者权益 </div>
				</div>
				<div class="row">
					<div class="qqq"> </div>
					<div class="www wid ple bletr"> 53212 </div>
					<div class="www wid ple ble wid_s"> 53212 </div>
					<div class="www wid ple ble"> 53212 </div>
				</div>
				<div class="row">
					<div class="qqq">转让行为内部决策情况</div>
					<div class="www ple">已审议</div>
				</div>
				<div class="row">
					<div class="qqq">转让行为批准情况</div>
					<div class="www ple"> 转让行为批准情况</div>
				</div>
				<div class="row">
					<div class="qqq">受让方资格条件</div>
					<div class="www ple">随意</div>
				</div>
				<div class="row">
					<div class="qqq">其他说明</div>
					<div class="www ple">暂无</div>
				</div>
				<div class="row">
					<div class="qqq">联系方式</div>
					<div class="www ple">张三 - 13893175456</div>
				</div> -->
