<!-- 农村产权公告 -->
<template>
	<div>
		<homenav navTag="trading"></homenav>
		<div class="region">
			<!-- 面包屑 -->
			<crumbs :crumbs="crumbs" @clkItem="clkItemd"></crumbs>
			<div class="rg-cotain">
				<!-- 侧边导航栏 -->
				<navleft :navs="navs" :tapItem="tapItem" :tapItem2="tapItem2" @clkItem="clkItem" @clkItem2="clkItem2" :navRqt="true"></navleft>
				<!-- 子组件渲染 -->
				<div class="chirn">
					<router-view :trading="trading" :left_id="left_id"/>
				</div>
			</div>
			<!-- 底部 -->
			<bottom></bottom>
		</div>
	</div>
</template>

<script>
	import $ from "jquery"
	export default {
		data() {
			return {
				// 菜单下标
				tapItem: 0,
				// 菜单子菜单下标
				tapItem2:0,
				navs: [],
				crumbs: ['首页','农村产权公告'],
				trading: {},
				left_id: 60,
				page: 1
			}
		},
		created() {
			let index = parseInt(this.$router.history.current.query.index)
			this.tapItem = index
			this.leftNav()
		},
		methods: {
			clkItemd(index) {
				switch(index) {
					case 0:
						this.$router.push({name:"index"})
					break;
				}
			},
			// 点击菜单栏
			clkItem(index, text) {
				if(index!=this.tapItem) {
					this.tapItem2 = 0
				}
				this.tapItem = index
				this.crumbs = ['首页','农村产权公告']
				// 修改数组
				this.$set(this.crumbs, 1, text.catname)
				this.$router.push({path:"/trading/announcelist",query:{index}})
				this.left_id = text.child[0].id
			},
			// 点击子菜单
			clkItem2(index,text) {
				this.tapItem2 = index
				this.left_id = text.id
				// 修改数组
				this.$set(this.crumbs, 2, text.catname)
				this.$router.push({path:"/trading/announcelist",query:{index}})
			},
			// 查找左边导航
			async leftNav() {
				let url = this.$api.trading.leftNav
				let data = {
					nav_id: 59
				}
				let res = await this.$axios.get(url, {
					params: data
				})
				if (res.code) {
					this.navs = res.list
					console.log('this.navs',this.navs)
					this.$set(this.crumbs, 2, res.list[0].catname)
					this.left_id = res.list[this.$route.query.index].child[0].id;
				}
			},
			
			getCotain() {
				let letain = $(".chirn")
				let width = letain.outerWidth()/1198*100+'px'
				letain.css("font-size",width)
			}
		},
		mounted() {
			$(document).ready(()=>{
				this.getCotain()
				$(window).resize(()=>{
					this.getCotain()
				})
			})
			this.$bus.$on('clkTableRow', (data) => {
				this.crumbs.push(data)
			})
		}
	}
</script>

<style lang="less" scoped>
	.chirn {
		background-color: #fff;
		padding: 30px;
		box-sizing: border-box;
		width: 100%;
		align-self: normal;
		
	}
	.region .rg-cotain {
		margin-bottom: 60px;
	}
	@media (max-width: 900px){
		.region .rg-cotain{
			flex-direction: column;
		}
	}
	@media (max-width:768px) {
		.chirn {
			padding: 10px;
		}
	}
</style>
