import Vue from 'vue'
import VueRouter from 'vue-router'
// 首页
import index from '@/views/index/index'
// 区域股权业务
import region from '@/views/region/region'
import register from '@/components/region/register'// 登记托管
import pledge from '@/components/region/pledge'// 股权交易
import listing from '@/components/region/listing'// 企业挂牌
import stock from '@/components/region/stock'// 股权质押
import service from '@/components/region/service'// 业务介绍
import means from '@/components/region/means'// 资料下载
import enterprise from '@/components/region/enterprise' // 挂牌企业展示
import epdeal from '@/components/region/epdeal' // 挂牌企业展示详情

// 会员服务
import member from '@/views/member/member'
import show from '@/components/member/show' // 会员展示
import shreg from '@/components/member/shreg' // 会员注册
import shdetail from '@/components/member/shdetail' // 会员详情

// 投资者教育
import ecation from '@/views/ecation/ecation'
import proplem from '@/components/ecation/proplem' // 常见问题
import course from '@/components/ecation/course' // 投资者课程
import activity from '@/components/ecation/activity' // 投资者教育活动
import athref from '@/components/ecation/athref' // 活动报名链接
import plemdeal from '@/components/ecation/plemdeal' // 问题详情
import etrain from '@/components/ecation/etrain' // 培训详情

// 信息披露
import infotion from '@/views/infotion/infotion' 
import info from '@/components/infotion/info' //信息披露列表
import infolist from '@/components/infotion/infolist' //公告详情
import infodetail from '@/components/infotion/infodetail' //公告详情

// 路演培训
import train from '@/views/train/train'
import business from '@/components/train/business' // 企业培训
import roadshow from '@/components/train/roadshow' // 企业路演

// 政策专区
import policy from '@/views/policy/policy'
import cupon from '@/components/policy/cupon' // 优惠政策

//产权业务
import home from '@/views/home/home' //产权业务首页

//交易公告
import trading from '@/views/trading/trading' 
import announcelist from '@/components/trading/announcelist' //公告列表
import details from '@/components/trading/details' //公告详情

//交易制度
import system from '@/views/system/system'
import systemlist from '@/components/system/systemlist' //交易制度
import system_details from '@/components/system/system_details' //制度详情

//重点项目推荐
import promote from '@/views/promote/promote'
import referrals from '@/components/promote/referrals' //重点项目推荐
import promote_details from '@/components/promote/promote_details' //重点项目推荐详情

 //项目展示
 import project from '@/views/project/project'
 import projectlist from '@/components/project/projectlist' //项目列表
 import projectdetails from '@/components/project/projectdetails' //项目列表详情
 
 //竞价大厅
 import bidding from '@/views/bidding/bidding'
 import options from '@/components/bidding/options' //竞价大厅详情
 import options_details from '@/components/bidding/options_details' //产权详情

// 特辑专版
import scDown from '@/components/special/scDown'
import scZcfg from '@/components/special/scZcfg'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
	{
		path:"/",
		name:"index",
		component:index
	},
	// 数据面板
	{
		path:"/panel",
		name:"panel",
		component:()=>import("@/views/panel/panel")
	},
	// 搜索
	{
		path:"/search",
		name:"search",
		component:()=>import("@/components/index/search")
	},
	{
		path:"/region",
		name:"region",
		component:region,
		children:[
			{
				path:'pledge',
				name:'pledge',
				component:pledge
			},
			{
				path:'enterDetail',
				name:"enterDetail",
				component:()=>import("@/components/region/enterDetail")
			},
			{
				path:'register',
				name:'register',
				component:register
			},
			{
				path:'listing',
				name:'listing',
				component:listing
			},
			{
				path:'stock',
				name:'stock',
				component:stock
			},
			{
				path:'service',
				name:'service',
				component:service
			},
			{
				path:'means',
				name:'means',
				component:means
			},
			// 挂牌企业展示
			{
				path:"enterprise",
				name:"enterprise",
				component:enterprise
			},
			// 挂牌企业展示详情
			{
				path:"epdeal",
				name:"epdeal",
				component:epdeal
			}
		]
	},
	// 会员服务
	{
		path:"/member",
		name:"member",
		component:member,
		children:[
			// 会员展示
			{
				path:"show",
				name:"show",
				component:show
			},
			// 会员注册
			{
				path:"shreg",
				name:"shreg",
				component:shreg
			},
			// 会员详情
			{
				path:"shdetail",
				name:"shdetail",
				component:shdetail
			}
		]
	},
	// 投资者教育
	{
		path:"/ecation",
		name:"ecation",
		component:ecation,
		children:[
			// 常见问题
			{
				path:"proplem",
				name:"proplem",
				component:proplem
			},
			// 投资者课程
			{
				path:"course",
				name:"course",
				component:course
			},
			// 投资者教育活动
			{
				path:"activity",
				name:"activity",
				component:activity
			},
			// 活动报名链接
			{
				path:"athref",
				name:"athref",
				component:athref
			},
			// 问题详情
			{
				path:"plemdeal",
				name:"plemdeal",
				component:plemdeal
			},
			// 培训详情
			{
				path:"etrain",
				name:"etrain",
				component:etrain
			},
			// 历史详情
			{
				path:"hisDetail",
				name:"hisDetail",
				component:()=>import("@/components/ecation/hisDetail")
			}
		]
	},
	// 信息披露
	{
		path:"/infotion",
		name:"infotion",
		component:infotion,
		children:[
			{
				path:"info",
				name:"info",
				component:()=>import("@/components/infotion/info")
			},
			{
				path:"infolist",
				name:"infolist",
				component:infolist
			},
			{
				path:"infodetail",
				name:"infodetail",
				component:infodetail
			},
			// 公告详情
			{
				path:"details",
				name:"details",
				component:details
			}
		]
	},
	// 路演培训
	{
		path:"/train",
		name:"train",
		component:train,
		children:[
			{
				path:"business",
				name:"business",
				component:business
			},
			{
				path:"roadshow",
				name:"roadshow",
				component:roadshow
			},
			// 培训详情
			{
				path:"tadetail",
				name:"tadetail",
				component:()=>import("@/components/train/tadetail")
			}
		]
	},
	// 政策专区
	{
		path:"/policy",
		name:"policy",
		component:policy,
		children:[
			// 优惠政策
			{
				path:"cupon",
				name:"cupon",
				component:cupon
			},
			// 优惠政策详情
			{
				path:"poldeal",
				name:"poldeal",
				component:()=>import("@/components/policy/poldeal")
			}
		]
	},
	// 关于我们
	{
		path:"/about",
		name:"about",
		component:()=>import("@/views/about/about"),
		children:[
			{
				path:"/about/centerIntroduce",
				name:"centerIntroduce",
				component:()=>import("@/components/about/centerIntroduce")
			},
			{
				path:"/about/centerCulture",
				name:"centerCulture",
				component:()=>import("@/components/about/centerCulture")
			},
			{
				path:"/about/shareholder",
				name:"shareholder",
				component:()=>import("@/components/about/shareholder")
			},
			{
				path:"/about/history",
				name:"history",
				component:()=>import("@/components/about/history")
			},
			{
				path:"/about/new_file",
				name:"new_file",
				component:()=>import("@/components/about/new_file")
			},
			{
				path:"/about/newsNotice",
				name:"newsNotice",
				component:()=>import("@/components/about/newsNotice")
			},
			{
				path:"/about/newList",
				name:"newList",
				component:()=>import("@/components/about/newsList")
			},
			{
				path:"/about/contactUs",
				name:"contactUs",
				component:()=>import("@/components/about/contactUs")
			},
			{
				name:"abNewDail",
				path:"/abNewDail",
				component:()=>import("@/components/special/scNewDail")
			},
			{
				name:"newdetail",
				path:"/newdetail",
				component:()=>import("@/components/about/newdetail")
			},
			{
				name:"newdetails",
				path:"/newdetails",
				component:()=>import("@/components/about/newdetails")
			},
			{
				name:"newMore",
				path:"/about/newMore",
				component:()=>import("@/components/about/newMore")
			}
		]
	},
	
	//产权业务首页
		{
			path:"/home",
			name:"home",
			component:home
		},
		
		//交易公告
		{
			path:"/trading",
			name:"trading",
			component:trading,
			children:[
				// 公告列表
				{
					path:"announcelist",
					name:"announcelist",
					component:announcelist
				},
				// 公告详情
				{
					path:"details",
					name:"details",
					component:details
				},
				// 公告详情
				{
					path:"annodetail",
					name:"annodetail",
					component:()=>import("@/components/trading/annodetail")
				}
			]
		},
		// 搜索
		{
			path:"/home/search",
			name:"homSea",
			component:()=>import("@/components/home/search")
		},
		// 交易制度
		{
			path:"/system",
			name:"system",
			component:system,
			children:[
				//交易制度
				{
					path:"systemlist",
					name:"systemlist",
					component:systemlist,
				},
				//制度详情
				{
					path:"system_details",
					name:"system_details",
					component:system_details,
				}
			]
		},
		// 重点项目推荐
		{
			path:"/promote",
			name:"promote",
			component:promote,
			children:[
				//重点项目推荐
				{
					path:"referrals",
					name:"referrals",
					component:referrals,
				},
				//重点项目推荐详情
				{
					path:"promote_details",
					name:"promote_details",
					component:promote_details,
				}
			]
		},
		// 项目展示
		{
			path:"/project",
			name:"project",
			component:project,
			children:[
				//项目列表
				{
					path:"projectlist",
					name:"projectlist",
					component:projectlist,
				},
				//项目展示详情
				{
					path:"projectdetails",
					name:"projectdetails",
					component:projectdetails,
				},
				// 公告详情
				{
					path:"annodetail",
					name:"annodetail",
					component:()=>import("@/components/trading/annodetail")
				}
			]
		},
		// 竞价大厅
		{
			path:"/bidding",
			name:"bidding",
			component:bidding,
			children:[
				//竞价大厅
				{
					path:"options",
					name:"options",
					component:options,
				},
				//产权详情
				{
					path:"options_details",
					name:"options_details",
					component:options_details,
				}
			]
		},
		{
			name:"scDown",
			path:"/scDown",
			component:()=>import("@/components/special/scDown")
		},
		// 政策法规
		{
			name:"scZcfg",
			path:"/scZcfg",
			component:scZcfg
		},
		// 政策法规详情
		{
			name:"scZcfgDail",
			path:"/scZcfgDail",
			component:()=>import("@/components/special/scZcfgDail")
		},
		// 新闻中心
		{
			name:"scNews",
			path:"/scNews",
			component:()=>import("@/components/special/scNews")
		},
		// 新闻详情
		{
			name:"scNewDail",
			path:"/scNewDail",
			component:()=>import("@/components/special/scNewDail")
		},
		{
			name:"scNewsdeal",
			path:"/scNewsdeal",
			component:()=>import("@/components/special/scNewsdeal")
		},
		// 挂牌企业详情
		{
			name:"scGpDail",
			path:"/scGpDail",
			component:()=>import("@/components/special/scGpDail")
		},
		// 关于我们
		{
			name:"scAbout",
			path:"/scAbout",
			component:()=>import("@/components/special/scAbout")
		},
		// 挂牌详情
		{
			name:"scGpDails",
			path:"/scGpDails",
			component:()=>import("@/components/special/scGpDails")
		},
		// 企业专区
		{
			name:"area",
			path:"/area",
			component:()=>import("@/components/special/area")
		},
		{
			name:"areaDail",
			path:"/areaDail",
			component:()=>import("@/components/special/areaDail")
		},
		// 	]
		// },
		// 专辑特新版首页
		{
			path:"/scIndex",
			name:"scIndex",
			component:()=>import("@/components/special/scIndex")
		}
]



const router = new VueRouter({
	routes,
	//配置滚动行为，跳转到新的路由界面滚动条的位置
	scrollBehavior () {
		return { x: 0, y: 0 }
	}
})

export default router
