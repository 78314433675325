<!-- 培训 -->
<template>
	<div class="train" id="letain">
		<slot></slot>
		<div class="leter">
			<!-- 电脑端显示 -->
			<div class="cot-txt" v-if="letain.length">
				<div class="study">
					<div class="tle">{{letain[acIndex].description || letain[acIndex].title}}</div>
					<div class="bor"></div>
					<div class="time">{{letain[acIndex].inputtime}}</div>
				</div>
			</div>
			<!-- 手机端显示 -->
			<div class="cot-txt2" v-if="letain.length">
				{{letain[acIndex].description}}
			</div>
			<div class="cot-img" @click.stop="tapTrai(letain[acIndex].id)">
				
				<el-carousel 
				indicator-position="none"
				arrow="never"
				:interval="time"
				@change="swiperChg"
				ref="swiLer">
				    <el-carousel-item v-for="(item,index) in letain" :key="item.id">
						<!-- <img src="../../assets/images/ecation/jz63.png" alt=""> -->
						<img :src="letain[acIndex].thumb" alt="">
				    </el-carousel-item>
				</el-carousel>
				
			</div>
			<div class="cot-gd">
				<p class="arrow" @click="arrowTop">
					<i class="iconfont">&#xe632;</i>
				</p>
				<div class="cot-img" id="cot-img">
					<div v-for="(item,index) in letain" :key="item.id" @click="clkLetain(index)" class="cot-item-img">
						<img :src="item.thumb" alt="">
						<div class="tip" :class="index==acIndex?'active':''">{{item.title}}</div>
						<div class="active"></div>
					</div>
				</div>
				<p class="arrow" @click="arrowBom">
					<i class="iconfont">&#xe634;</i>
				</p>
			</div>
			
		</div>
		<!-- 手机端2 -->
		<div class="cot-gd2" v-if="letain.length">
			<div v-for="(item,index) in letain" :key="item.id" @click="clkLetain(index)">
				<img :src="item.thumb" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	import $ from 'jquery'
	import qs from "qs"
	import moment from "moment"
	export default {
		data() {
			return {
				acIndex:0, // 当前选中项
				swiper:[],
				swiperOption:{
					pagination:{
						el:'.swiper-pagination'
					}
				},
				scrollTop:0
			}
		},
		created() {
			// this.GetTrainList()
		},
		props:{
			letain:{
				type:Array,
				default:()=>[]
			},
			time:{
				type:Number,
				default:0
			}
		},
		watch:{
			letain() {
				this.hoverAni()
			}
		},
		methods:{
			arrowTop() {
				this.scrollTop+=130
				$("#cot-img").animate({scrollTop:this.scrollTop+"px"},300)
			},
			arrowBom() {
				if(this.scrollTop-130<0) {
					this.scrollTop = 0
				}else {
					this.scrollTop-=130
				}
				$("#cot-img").animate({scrollTop:this.scrollTop+"px"},300)
			},
			swiperChg(e) {
				this.acIndex = e
			},
			// 点击项目
			clkLetain(index) {
				this.acIndex = index
				let swiLer = this.$refs.swiLer
				swiLer.setActiveItem(index)
			},
			tapTrai(id) {
				let queryPath = "Policyzone/GetTrainDetails"
				this.$router.push({path:"/train/tadetail",query:{index:1,id,queryPath}})
			},
			// 悬浮动画
			hoverAni() {
				let that = this
				$(".leter").hover(function(){
					if(that.screenWidth>1245) {
						$(".cot-txt").stop().animate({
							left:0
						})
						$(".cot-gd").stop().animate({
							right:0
						})
						$(".cot-bf").animate({opacity:1})
						$(".tle").animate({opacity:1})
					}
					
				},function(){
					if(that.screenWidth>1245) {
						$(".cot-txt").stop().animate({
							left:$(this).width()*-1+"px"
						})
						$(".cot-gd").stop().animate({
							right:$(this).width()*-1+"px"
						})
						$(".cot-bf").animate({
							opacity:0
						})
					}
				})
				$(".cot-gd>div").hover(function(){
					if(that.screenWidth>1245) {
						$(this).children(".tip").css(
							{
								"background-color":"rgba(20, 6, 128, 0.5)",
								"color":"rgba(255, 255, 255, 1)"
							}
						);
					}
				},function(){
					if(that.screenWidth>1245) {
						$(this).children(".tip").css(
							{
								"background-color":"transparent",
								"color":"rgba(255, 255, 255, 0)"
							}
						);
					}
				})
				$(".cot-gd").hover(function(){
					if(that.screenWidth>1245) {
						$(this).children(".arrow").eq(0).stop().animate({top:'0'})
						$(this).children(".arrow").eq(1).stop().animate({bottom:'0'})
					}
				},function(){
					if(that.screenWidth>1245) {
						$(this).children(".arrow").eq(0).stop().animate({top:'-24px'})
						$(this).children(".arrow").eq(1).stop().animate({bottom:'-24px'})
					}
				})
			},
			
		},
		mounted() {
			this.screenWidth = $(window).width()
			let that = this
			$(window).resize(function(){
				that.screenWidth = $(window).width()
			})
		},
	}
</script>

<style lang="less" scoped>
	/deep/ .el-carousel--horizontal {
		height: 100%;
	}
	/deep/ .el-carousel__container {
		height: 100%;
	}
	.train {
		position: relative;
		overflow: hidden;
		max-width: 1440px;
		margin: 0 auto;
		background-color: white;
		box-sizing: border-box;
		overflow: hidden;
	}
	.leter {
		position: relative;
		height: 600px;
		overflow: hidden;
		.cot-txt {
			position: absolute;
			top: 0;
			bottom: 0;
			width: 416px;
			left: 0;
			background: rgba(255, 255, 255, 0.7);
			box-sizing: border-box;
			text-align: justify;
			z-index: 999;
			.study {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				width: 100%;
				padding: 0 40px;
				box-sizing: border-box;
				.tle {
					color: rgba(51, 51, 51, 1);
					font-size: 34px;
					font-weight: 700;
					line-height: 50px;
				}
				.bor {
					height: 3px;
					width: 25%;
					background-color: rgba(51, 51, 51, 1);
					margin-top: 20px;
				}
				.time {
					color: rgba(51, 51, 51, 1);
					font-size: 14px;
					font-weight: 700;
					margin-top: 80px;
				}
			}
			
		}
		.cot-txt2 {
			display: none;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(255,255,255,.7);
			color: rgba(51, 51, 51, 1);
			font-size: 0.28em;
			text-align: justify;
			box-sizing: border-box;
			padding: 20px;
			letter-spacing: 3px;
			font-weight: 700;
			line-height: 40px;
		}
		.cot-img {
			height: 100%;
			.swiper-container {
				height: 100%;
				pointer-events: none;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			.cot-bf {
				position: absolute;
				right: 300px;
				bottom: 40px;
				color: white;
				background-color: rgba(255, 255, 255, .5);
				width: 56px;
				height: 56px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				opacity: 0;
				i {
					font-size: 30px;
				}
				
			}
		}
		.cot-gd {
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			width: 245px;
			box-sizing: border-box;
			background: rgba(255, 255, 255, 0.7);
			padding: 14px;
			z-index: 999;
			
			.cot-img {
				overflow-y: hidden;
				.cot-item-img {
					height: 130px;
					margin-bottom: 14px;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			
			.arrow {
				position: absolute;
				width: 48px;
				height: 48px;
				background-color: rgba(0, 0, 0, .5);
				border-radius: 50%;
				display: flex;
				left: 50%;
				justify-content: center;
				z-index: 99999;
				color: white;
				box-sizing: border-box;
				cursor: pointer;
				&:first-child {
					top: -24px;
					align-items: flex-end;
					transform: translate(-50%,-50%);
					padding-bottom: 5px;
				}
				&:last-child {
					bottom: -24px;
					align-items: flex-start;
					transform: translate(-50%,50%);
					padding-top: 5px;
				}
			}
			div {
				overflow: hidden;
				box-sizing: border-box;
				position: relative;
				.tip {
					color:rgba(255, 255, 255, 0);
					font-size: 16px;
					font-weight: 400;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					transition: all .3s;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					&.active {
						background-color:rgba(20, 6, 128, 0.5);
						color: #fff;
					}
				}
				img {
					object-fit: cover;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
				}
			}
		}
		
	}
	.cot-gd2 {
		display: none;
		margin-top: 10px;
		
	}
	
	@media (max-width:1245px) {
		.leter {
			height: 400px;
			.cot-txt {
				display: none;
			}
			.cot-txt2 {
				display: block;
			}
			.cot-gd {
				display: none;
			}
			&:hover {
				.cot-img {
					.cot-bf {
						opacity: 0;
					}
				}
			}
		}
		.cot-gd2 {
			display: flex;
			overflow: auto;
			&>div {
				width: 33.33%;
				margin-right: 10px;
				&:last-child {
					margin-right: 0;
				}
				flex-shrink: 0;
				img {
					width: 100%;
					height: 2.2em;
				}
			}
		}
		
	}
	@media (max-width:1000px) {
		
	}
	@media (max-width:650px) {
		.leter {
			height: 350px;
			.cot-txt2 {
				font-size: 16px;
				padding: 10px;
				line-height: 30px;
			}
		}
	}
</style>