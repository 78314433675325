<!-- 导航栏 -->
<template>
	<div class="home-father">
		<!-- 登录注册 -->
		<ul class="login">
			<li @click="clkMan">
				<i class="el-icon-goods"></i>
				<span>管理后台</span>
			</li>
			<li @click="clkLog(1)">
				<i class="iconfont">&#xe64d;</i>
				<span>邮箱</span>
			</li>
			<li @click="clkLog(2)">
				<i class="iconfont">&#xe624;</i>
				<span>OA 办公</span>
			</li>
			<li @click="clkLog(3)">
				<i class="iconfont">&#xeca7;</i>
				<span>加入收藏</span>
			</li>
			<li>
				<i class="iconfont">&#xe658;</i>
				<span @click="clkLog">登录</span>
				<span style="margin: 0 5px;">|</span>
				<span @click="clkRegon">注册</span>
			</li>
		</ul>
		<div class="nav">
			<img src="../../assets/images/trading/cqjylogo.png" alt="" class="logo" v-if="!navFlag" @click="clkIntext">
			<div v-else class="regain">
				<span @click="clkLog">登录</span>
				<span> | </span>
				<span @click="clkRegon">注册</span>
			</div>
			<ul class="nav-item" :class="navFlag==true?'nav-block':''">
				<li :class="navTag=='home'?'active':''" @click="clkTag(1)">首页</li>
				<li :class="navTag=='trading'?'active':''" @click="clkTag(2)">交易公告</li>
				<li :class="navTag=='system'?'active':''" @click="clkTag(3)">交易制度</li>
				<li :class="navTag=='promote'?'active':''" @click="clkTag(4)">重点项目推荐</li>
				<li :class="navTag=='project'?'active':''" @click="clkTag(5)" style="margin-right: 0;">项目展示</li>
				<!-- <li :class="navTag=='bidding'?'active':''" @click="clkTag(6)">竞价大厅</li> -->
				<li class="d">邮箱<i class="iconfont h">&#xe642;</i></li>
				<li class="d">OA 办公<i class="iconfont h">&#xe642;</i></li>
				<li class="d">加入收藏<i class="iconfont h">&#xe642;</i></li>
				<!-- <li class="solid">|</li> -->
				<li :class="navTag=='search'?'active':''" @click="clkSearch">
					<i class="iconfont" style="margin-right: 10px;">&#xe600;</i>
					<span>搜索</span>
				</li>
			</ul>
			<ul class="nav-none" @click="clkNav" :class="navFlag==true?'nav-clk':''">
				<li></li>
				<li></li>
				<li></li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				navFlag: false
			}
		},
		props: {
			navTag: {
				type: String,
				default: ''
			}
		},
		methods: {
			// 点击搜索
			clkSearch() {
				this.$router.push({path:"/search"})
			},
			clkMan() {
				window.open("http://www.gsotc.com.cn/admin")
			},
			// 点击三条斜杠
			clkNav() {
				this.navFlag = !this.navFlag
			},
			// 点击导航
			clkTag(index) {
				switch (index) {
					case 1:
						this.$router.push({
							name: 'home'
						})
						break;
					case 2:
						this.$router.push({
							name: "announcelist",
							query: {
								index: 0
							}
						})
						break;
					case 3:
						this.$router.push({
							name: "systemlist"
						})
						break;
					case 4:
						this.$router.push({
							name: "referrals"
						})
						break;
					case 5:
						this.$router.push({
							name: 'projectlist',
							query: {
								index: 0
							}
						})
						break;
					case 6:
						this.$router.push({
							name: 'options'
						})
						break;
					case 7:
						this.$router.push({
							name: 'centerIntroduce'
						})
						break;
				}
			},

			// 点击邮箱
			clkEmail() {
				location.href = "http://mail.gsotc.com.cn/"
			},
			// 点击OA办公
			clkOa() {
				location.href = "http://192.168.1.88:88"
			},
			// 点击登录
			clkLog() {
				location.href = "http://61.178.68.41:8888/login.html"
			},
			// 点击注册
			clkRegon() {
				location.href = "http://61.178.68.41:8888/register-1.html"
			},
			//点击logo跳转至大首页
			clkIntext() {
				this.$router.push({
					name: "index"
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.home-father {
		background-color: #fff;
	}

	.login {
		display: flex;
		max-width: 1440px;
		margin: 0 auto;
		font-size: 16px;
		font-family: SourceHanSansCN;
		font-weight: 300;
		color: #000000;
		justify-content: flex-end;
		padding-top: 28px;
	}

	.login li {
		margin-right: 40px;
		cursor: pointer;

		&:hover:not(&:last-child) {
			color: var(--themeColor);
		}

		&:last-child {
			span {
				&:hover {
					color: var(--themeColor);
				}
			}
		}
	}

	.login li:last-child {
		margin-right: 0;
	}

	.login i {
		margin-right: 10px;
	}

	.nav {
		max-width: 1440px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 16px;
		font-family: SourceHanSansCN;
		font-weight: 300;
		box-sizing: border-box;
		padding: 28px 0;
		position: relative;

		.img {
			cursor: pointer;
		}

		.regain {
			span {
				color: rgba(152, 155, 158, 1);

				&:nth-child(2) {
					margin: 0 10px;
				}
			}
		}

		.nav-none {
			display: none;
			width: 25px;
			height: 17px;

			li {
				width: 100%;
				height: 3px;
				border-radius: 10px;
				background-color: #000000;
				transition: all .2s;
				transform-origin: left;
			}

			&.nav-clk {
				li {
					&:nth-child(1) {
						transform: rotateZ(45deg);
						position: relative;
						top: -3px;
					}

					&:nth-child(2) {
						opacity: 0;
					}

					&:nth-child(3) {
						transform: rotateZ(-45deg);
					}
				}
			}
		}
	}

	.nav .nav-item {
		display: flex;
	}

	.nav-item li {
		margin-right: 40px;
		cursor: pointer;
		color: rgba(102, 102, 102, 1);
		font-size: 16px;
		font-weight: 400;

		img {
			width: 16px;
			margin-right: 6px;
		}

		&.d {
			display: none;
		}

		// i.h {
		// 	display: none;
		// }
		// i.l{
		// 	margin-right: 6px;
		// }
		&.active {
			color: rgba(10, 72, 144, 1);
			font-size: 16px;
			font-weight: 700;
		}

		&:hover {
			color: rgba(10, 72, 144, 1);
			font-size: 16px;
			font-weight: 700;
		}
	}

	.nav-item li:last-child {
		margin-right: 0;
		padding-left: 22px;
		margin-left: 20px;
		border-left: 1px solid rgba(154, 160, 171, 1);
		height: 15px;
	}

	.nav-item li:nth-child(6) {
		margin-right: 0;
	}

	// .nav-item .about,.nav-item .solid {
	// 	margin:0 20px;
	// 	color: rgba(102, 102, 102, 1);
	// 	font-size: 14px;
	// 	font-weight: 400;
	// }
	.nav .logo {
		max-width: 390px;
		cursor: pointer;
	}

	@media (max-width:1440px) {
		.login {
			font-size: 14px;
		}

		.nav {
			padding: 20px 20px;
			font-size: 14px;

			.logo {
				width: 20%;
			}
		}

	}

	@media (max-width:1275px) {
		.login {
			display: none;
			margin-bottom: 10px;

			li {
				margin-right: 15px;
			}
		}

		.nav {
			padding: 5px 20px;
			height: 50px;
			box-sizing: border-box;
			background-color: white;
			border-bottom: 1px solid rgba(152, 155, 158, 1);

			.logo {
				height: 100%;
				width: auto;
			}

			.nav-none {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				cursor: pointer;
			}

			.nav-item {
				flex-direction: column;
				position: absolute;
				left: 0;
				top: 50px;
				right: 0;
				z-index: 9999999;
				background-color: white;
				display: none;

				&.nav-block {
					display: block;
				}

				li {
					height: 50px;
					line-height: 50px;
					text-align: center;
					border-bottom: 1px solid #ccc;
					margin-right: 0;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0 20px;
					color: rgba(152, 155, 158, 1);

					&.d {
						display: block;
						display: flex;
						justify-content: space-between;
						padding: 0 20px;
					}

					i.h {
						display: block;
						color: rgba(152, 155, 158, 1);
					}

					&:hover {
						color: var(--themeColor);
						cursor: pointer;
						font-weight: bold;
					}

					&.solid {
						display: none;
					}
				}
			}
		}
	}

	@media (max-width:768px) {
		.login {
			display: none;
		}

		.nav {
			.logo {}
		}
	}
</style>
