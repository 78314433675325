<template>
	<div>
		<navs navTag="train"></navs>
		<div class="region">
			<!-- 面包屑 -->
			<crumbs :crumbs="crumbs" @clkItem="clkItemd"></crumbs>
			<div class="rg-cotain">
				<!-- 侧边导航栏 -->
				<navleft :navs="navs" :tapItem="tapItem" @clkItem="clkItem"></navleft>
				<!-- 子组件渲染 -->
				<div class="chirn">
					<router-view/>
				</div>
			</div>
		</div>
		<bottom></bottom>
	</div>
</template>

<script>
	import $ from "jquery"
	export default {
		data() {
			return {
				crumbs:['首页','培训路演','企业培训'],
				navs:['企业培训','企业路演'],
				tapItem:0
			}
		},
		created() {
			let index = parseInt(this.$router.history.current.query.index)
			this.tapItem = index
		},
		
		mounted() {
			this.$nextTick(()=>{
				this.getCotain()
				$(window).resize(()=>{
					this.getCotain()
				})
			})
		},
		methods:{
			clkItem(index,text) {
				this.tapItem = index
				this.crumbs = ['首页','培训路演','企业培训']
				// 修改数组
				this.$set(this.crumbs,2,text)
				
				switch (index) {
					case 0:
						this.$router.push({name:"business",query:{index}})
					break;
					case 1:
						this.$router.push({name:"roadshow",query:{index}})
					break;
				}
			},
			getCotain() {
				let letain = $(".chirn")
				let width = letain.outerWidth()/1198*100+'px'
				letain.css("font-size",width)
			},
			clkItemd(index) {
				switch (index) {
					case 0:
						this.$router.push({name:"index"})
					break;
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.region {
		margin-bottom: 60px;
	}
	@media (max-width:1480px) {
		.region {
			padding: 0 20px;
		}
	}
	@media (max-width:900px) {
		.region {
			.rg-cotain {
				flex-direction: column;
			}
		}
	}
	@media (max-width:768px) {
		.region {
			padding: 0 10px;
		}
	}
</style>