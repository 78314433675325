// 用于将秒转成分
function formatSeconds(value) {
	var secondTime = parseInt(value); // 秒
	var minuteTime = 0; // 分
	var hourTime = 0; // 小时
	if (secondTime > 60) { //如果秒数大于60，将秒数转换成整数
		//获取分钟，除以60取整数，得到整数分钟
		minuteTime = parseInt(secondTime / 60);
		//获取秒数，秒数取佘，得到整数秒数
		secondTime = parseInt(secondTime % 60);
		//如果分钟大于60，将分钟转换成小时
		if (minuteTime > 60) {
			//获取小时，获取分钟除以60，得到整数小时
			hourTime = parseInt(minuteTime / 60);
			//获取小时后取佘的分，获取分钟除以60取佘的分
			minuteTime = parseInt(minuteTime % 60);
		}
	}
	var result = "" + parseInt(secondTime);

	if (minuteTime > 0) {
		result = "" + parseInt(minuteTime) + ":" + result;
	}
	if (hourTime > 0) {
		result = "" + parseInt(hourTime) + ":" + result;
	}
	return result;
}

// 用于解决富文本图片问题问题
function setContent(res) {
	const regex = new RegExp('<img', 'gi')
	return res.replace(regex, `<img style="width: 50%; height: auto;margin:0 auto"`)
}

/**
 * @param {*} content 文件链接内容
 * @param {*} filename 下载的文件名
 */
function downLoad(content,filename) {
	let a = document.createElement("a")
	a.download = filename
	a.style.display = 'none'
	// 字符内容转变blob地址
	let blob = new Blob([content])
	a.href = URL.createObjectURL(blob)
	document.body.appendChild(a)
	a.click()
	document.body.removeChild(a)
}

export default {
	formatSeconds,
	setContent,
	downLoad
}
