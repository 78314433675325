<!-- 活动报名链接 -->
<template>
	<div class="athref">
		<div class="ipt">
			<label>活动标题</label>
			<el-input v-model="formData.title" placeholder="请输入活动标题" disabled></el-input>
		</div>
		<div class="ipt">
			<label>开始时间</label>
			<el-input v-model="formData.activitytime" placeholder="请输入开始时间" disabled></el-input>
		</div>
		<div class="ipt">
			<label>活动地点</label>
			<el-input v-model="formData.activityplace" placeholder="请输入活动地点" disabled></el-input>
		</div>
		<div class="ipt">
			<label>报名链接</label>
			<el-input placeholder="请输入报名链接" disabled :value="bmurl">
				
			</el-input>
			<div class="copy" data-clipboard-text="我是点击复制的内容" @click="clkCopy">复制链接</div>
		</div>
		
		<div class="ipt">
			<label>参会人数</label>
			<el-input type="number" v-model.number="submitData.number" placeholder="请输入参会人数" @input="iptChange"></el-input>
		</div>
		<div class="ipt">
			<label class="name">参会人姓名</label>
			<el-input v-model="submitData.personnel_name" placeholder="请输入参会人姓名" @input="iptChange"></el-input>
		</div>
		<div class="ipt">
			<label>参会人号码</label>
			<el-input v-model="submitData.corporate_name" placeholder="请输入参会人号码" @input="iptChange"></el-input>
		</div>
		<div class="ipt qrimg">
			<label>二维码报名</label>
			<div class="img">
				<img :src="imgUrl" alt="">
			</div>
			<div class="save" @click="clkSave" style="cursor: pointer;">保存二维码</div>
		</div>
		
		<div class="cancal">
			<div>取消</div>
			<div :class="bmFlag?'active':''" @click="clkBm">确定报名</div>
		</div>
	</div>
</template>

<script>
	import $ from "jquery"
	import qs from "qs"
	import moment from "moment"
	import Clipboard from 'clipboard'
	export default {
		data() {
			return {
				// 表单数据
				formData:{},
				// 提交参数
				submitData:{
					address:"",
					starttime:"",
					theme_name:"",
					corporate_name:"",
					number:null,
					personnel_name:""
				},
				bmFlag:false,
				bmurl:"",
				imgUrl:""
			}
		},
		mounted() {
			this.$nextTick(()=>{
				let lbWidth = $(".name").width()
				$(".ipt>label").css("width",`${lbWidth}px`)
			})
		},
		created() {
			this.bmurl = location.href
			let id = this.$route.query.id
			let left_id = this.$route.query.left_id
			this.getDetail(id,left_id)
			this.getCode(id)
		},
		methods:{
			// 查询二维码
			getCode(id) {
				this.$axios.get("Policyzone/getQrcode",{
					params:{
						id
					}
				}).then(res=>{
					if(res.code) {
						this.imgUrl = res.data
					}
				})
			},
			
			clkSave() {
				var oA = document.createElement("a");
				oA.download = 'photo'; // 设置下载的文件名，默认是'下载'
				oA.href = this.imgUrl
				document.body.appendChild(oA);
				oA.click();
				oA.remove();
			},
			
			// 查看详情
			getDetail(id,left_id) {
				let url = this.$api.ecation.InvestmentDetails
				let data = {
					id,
					left_id
				}
				this.$axios.post(
					url,
					qs.stringify(data)
				).then(res=>{
					if(res.code) {
						
						res.data.activitytime = moment(parseInt(res.data.activitytime*1000)).format("YYYY-MM-DD HH:mm:ss")
						this.formData = res.data
						this.submitData.address = res.data.activityplace
						this.submitData.starttime = res.data.activitytime
						this.submitData.theme_name = res.data.title
					}
				})
			},
			
			// 点击复制链接
			clkCopy() {
				var input = document.createElement("input");   // js创建一个input输入框
				input.value = location.href;  // 将需要复制的文本赋值到创建的input输入框中
				document.body.appendChild(input);    // 将输入框暂时创建到实例里面
				input.select();   // 选中输入框中的内容
				document.execCommand("Copy");   // 执行复制操作
				document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
				this.$message({
					message: '复制成功',
					type: 'success'
				});
			},
			
			// 输入时触发 用于验证
			iptChange(e) {
				let key = Object.keys(this.submitData)
				let flag = true
				this.bmFlag = false
				for(let i = 0;i<key.length;i++) {
					if(!this.submitData[key[i]]) {
						flag = false
						continue
					}
				}
				if(flag) {
					this.bmFlag = flag
				}
			},
			// 点击报名
			clkBm() {
				if(!this.bmFlag) {
					return false
				}
				let url = this.$api.ecation.ActivitySign
				this.$axios.post(
					url,
					qs.stringify(this.submitData)
				).then(res=>{
					if(res.code) {
						this.$message({
						    message: res.msg,
						    type: 'success'
						});
						setTimeout(()=>{
							this.$router.push({name:"activity",index:2})
						},1000)
					}else {
						this.$message({
						    message: res.msg,
						    type: 'error'
						});
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	/deep/ .el-input__inner {
		border-radius: 0 !important;
	}
	.athref {
		.ipt {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			
			.copy {
				font-size:16px;
				flex-shrink: 0;
				color: #fff;
				background-color: var(--themeColor);
				height: 40px;
				line-height: 40px;
				padding: 0 10px;
				cursor: pointer;
			}
			label {
				flex-shrink: 0;
				color: rgba(51, 51, 51, 1);
				font-size: 16px;
				font-weight: 400;
				margin-right: 16px;
				text-align: right;
			}
		}
		.qrimg {
			label {
				align-self: flex-start;
			}
			.img {
				border: 1px solid #eaebed;
				img {
					width: 144px;
				}
			}
			
			.save {
				align-self: flex-end;
				width: 104px;
				height: 40px;
				line-height: 40px;
				border: 1px solid rgba(10, 72, 144, 1);
				text-align: center;
				color: rgba(10, 72, 144, 1);
				font-size: 16px;
				font-weight: 400;
				margin-left: 28px;
			}
		}
		.cancal {
			margin-left: 96px;
			display: flex;
			div {
				width: 104px;
				height: 40px;
				border: 1px solid rgba(214, 217, 221, 1);
				font-size: 16px;
				line-height: 40px;
				text-align: center;
				cursor: pointer;
				&:last-child {
					background: rgba(214, 217, 221, 1);
					color: rgba(154, 160, 171, 1);
					margin-left: 16px;
					cursor: not-allowed;
				}
				&.active {
					background-color: var(--themeColor);
					color: #fff;
					cursor: pointer;
				}
			}
		}
	}
	
	@media (max-width:431px) {
		.athref {
			.qrimg {
				flex-wrap: wrap;
				.save {
					margin-left: 0;
					margin-top: 20px;
				}
			}
		} 
	}
</style>