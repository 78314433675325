<!-- 股权质押 -->
<template>
	<!-- 内容主题 -->
	<div class="gr-fa-txtm">
		<ul class="rg-context">
			<li class="rg-ctx-item" v-for="(item,index) in downList.data" :key="item.id">
				<p class="rg-txt">{{item.title}}</p>
				<p class="down" @click="downFile(item.file,item.title)">下载<i class="iconfont">&#xe855;</i></p>
			</li>
		</ul>
		<div class="block">
		    <el-pagination
				background
				:page-size="downList.per_page"
				@current-change="downPage"
				layout="total, prev, pager, next, jumper"
				:total="downList.total">
		    </el-pagination>
		</div>
		<phonePg :page="page" :total="downList.total" :perPage="downList.per_page" @pageNext="pageNext" @pagePref="pagePref"></phonePg>
	</div>
</template>

<script>
	import phonePg from "@/components/phonePg/phonePg"
	import qs from "qs"
	import util from "@/assets/script/util.js"
	export default {
		data() {
			return {
				page:1,
				downList:[],
				total:0,
				id:0
			}
		},
		created() {
			let id = this.$route.query.id
			this.id = id
			this.DownloadList()
		},
		components:{
			phonePg
		},
		methods:{
			// 获取资料下载
			DownloadList(id) {
				let url = this.$api.stock.DownloadList
				let data = {
					nav_id:this.id,
					page:this.page
				}
				this.$axios.post(
					url,
					qs.stringify(data)
				).then(res=>{
					if(res.code) {
						this.downList = res.list
					}
				})
			},
			// 页数变化时触发
			downPage(e) {
				this.page = e
				this.DownloadList()
			},
			
			// 点击上一页
			pageNext() {
				if(this.page-1 == 0) {
					return false
				}
				this.page--
				this.DownloadList()
			},
			// 点击下一页
			pagePref() {
				if(this.page*this.downList.per_page>this.downList.total) {
					return false
				}
				this.page ++
				this.DownloadList()
			},
			// 点击下载
			downFile(file,title) {
				window.location.href = file
			}
		}
	}
</script>

<style lang="less" scoped>
	// 内容主题
	.gr-fa-txtm {
		margin-bottom: 0;
		.rg-context {
			box-sizing: border-box;
			
			.rg-ctx-item {
				height: 88px;
				box-sizing: border-box;
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid #f5f6f7;
				align-items: center;
				font-size: 16px;
				font-weight: 400;
				color: #666;
				cursor: pointer;
				&:first-child {
					border-top: 1px solid #f5f6f7;
				}
				.rg-txt {
					box-sizing: border-box;
					padding-right: 20px;
				}
				.down {
					width: 112px;
					height: 48px;
					font-size: 16px;
					font-weight: 500;
					color: #fff;
					background-color: var(--themeColor);
					text-align: center;
					line-height: 48px;
					flex-shrink: 0;
					.iconfont {
						margin-left: 5px;
					}
				}
			}
		}
	}
</style>