<!-- 登记托管 -->
<template>
	<ul class="register">
		<li v-for="item in resList" :key="item.id" >
			<p class="img">
				<img :src="item.thumb" alt="">
			</p>
			<div class="re-ctx">
				<h3 class="tle">{{item.catname}}</h3>
				<p class="re-cotain">{{item.description}}</p>
				<div class="more" @click="clkServiceH(item.id)">
					了解更多<i class="iconfont">&#xe642;</i>
				</div>
			</div>
		</li>
		
		<!-- <li>
			<p class="img">
				<img src="../../assets/images/region/fz4.png" alt="">
			</p>
			<div class="re-ctx">
				<h3 class="tle">托管企业展示</h3>
				<p class="re-cotain">经审核，威特焊材企业符合我中心交易板挂牌条件，同意在交易板挂牌，现予以公告。经审核，威特焊材企业符合我中心交易板挂牌条件，同意在交易板挂牌，现予以公告。</p>
				<div class="more" @click="clkBusi">
					查看<i class="iconfont">&#xe642;</i>
				</div>
			</div>
		</li>
		<li>
			<p class="img">
				<img src="../../assets/images/region/fz5(1).png" alt="">
			</p>
			<div class="re-ctx">
				<h3 class="tle">业务办理</h3>
				<p class="re-cotain">经审核，威特焊材企业符合我中心交易板挂牌条件，同意在交易板挂牌，现予以公告。经审核，威特焊材企业符合我中心交易板挂牌条件，同意在交易板挂牌，现予以公告。</p>
				<div class="more" @click="clkSev">
					我要挂牌<i class="iconfont">&#xe642;</i>
				</div>
			</div>
		</li> -->
	</ul>
</template>

<script>
	export default {
		props:{
			resList:{
				type:Array,
				default:()=>[]
			}
		},
		methods:{
			// 托管企业展示
			clkBusi() {
				window.location.href = "http://61.178.68.41:8888/enterprise-style/enterprise-trusteeship.html"
			},
			// 托管业务办理‘
			clkSev() {
				window.location.href = "http://61.178.68.41:8888/enterprise-service/index-corporation-companies-service.html"
			},
			// 点击后台返回的数据的列表
			clkServiceH(id) {
				// 点击业务介绍
				if(id==53) {
					this.$router.push({name:'service',query:{index:0,id}})
				// 资料下载
				}else if(id==54) {
					this.$router.push({name:'means',query:{index:0,id}})
				// 挂牌展示
				}else if(id==188) {
					// this.$router.push({name:'enterprise',query:{index:0,id}})
					// location.href = "http://61.178.68.41:8888/enterprise-style/enterprise-trusteeship.html"
					location.href = "https://biz.gsotc.com.cn/#/companyList"
				}else if(id==189) {
					// window.location.href = "http://61.178.68.41:8888/enterprise-service/index-corporation-companies-service.html"
					window.location.href = "https://biz.gsotc.com.cn/#/login"
				}
			}
		}
	}
</script>

<style lang="less">
	.register {
		display: grid;
		grid-template-columns: repeat(2,1fr);
		column-gap: 20px;
		row-gap: 30px;
		box-sizing: border-box;
		background-color: #fff;
		li {
			background-color: #fff;
			transition: all .5s;
			box-sizing: border-box;
			cursor: pointer;
			&:hover {
				box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.06);
				transform: translateY(-5px);
			}
			.img {
				position: relative;
				
				img {
					width: 100%;
				}
			}
			&:nth-child(1) {
				.img {
					background: linear-gradient(135deg, rgba(40, 98, 168, 1) 0%, rgba(67, 131, 209, 1) 100%);
				}
			}
			&:nth-child(2) {
				.img {
					background: linear-gradient(135deg, rgba(51, 163, 111, 1) 0%, rgba(67, 209, 143, 1) 100%);
				}
			}
			&:nth-child(3) {
				.img {
					background: linear-gradient(135deg, rgba(232, 181, 63, 1) 0%, rgba(247, 205, 106, 1) 100%);
				}
			}
			&:nth-child(4) {
				.img {
					background: linear-gradient(135deg, rgba(65, 148, 181, 1) 0%, rgba(95, 197, 237, 1) 100%);;
				}
			}
			.re-ctx {
				padding: 0 20px 30px 20px;
				.tle {
					color: rgba(51, 51, 51, 1);
					font-size: 22px;
					font-weight: 700;
					margin-top: 30px;
					margin-bottom: 16px;
				}
				.re-cotain {
					color: rgba(102, 102, 102, 1);
					font-size: 16px;
					font-weight: 400;
					line-height: 25px;
				}
				.more {
					font-size: 16px;
					font-weight: 400;
					color: rgba(102, 102, 102, 1);
					padding: 12px 0;
					width: 140px;
					border: 1px solid rgba(154, 160, 171, 1);
					display: inline-block;
					margin-top: 15px;
					text-align: center;
					cursor: pointer;
					&:hover {
						border-color: var(--themeColor);
						color: var(--themeColor);
					}
					i {
						font-size: 14px;
						margin-left: 5px;
					}
				}
			}
		}
	}
	
</style>