<template>
  <div>
    <tap :taps="taps" :tapIndex="tapIndex" @clkTap="clkTap" :tapsTrue="true"
      ><i></i
    ></tap>
    <course
      :courses="courses"
      @clkImgDeal="clkImgDeal"
      v-show="courses.length"
    ></course>
    <el-empty description="暂无数据" v-show="!courses.length"></el-empty>
    <div class="block" v-show="courses.length">
      <el-pagination
        background
        layout="total, prev, pager, next, jumper"
        :total="total"
        :page-size="perPage"
        @current-change="curChge"
      >
      </el-pagination>
    </div>
    <dialog1 :dialogVisible.sync="dialogVisible" @submit="goDetail"></dialog1>
    <!-- <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="25%"
      :before-close="handleClose"
      custom-class="custom-class"
    >
      <div class="title">
        <span>信息登记表</span>
      </div>
      <div class="inputBox">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          label-position="top"
          class="demo-ruleForm"
        >
          <el-row :gutter="20">
            <el-col :span="12"
              ><el-form-item label="姓名" prop="title">
                <el-input v-model="ruleForm.title"></el-input> </el-form-item
            ></el-col>
            <el-col :span="12"
              ><el-form-item label="职位" prop="job">
                <el-input v-model="ruleForm.job"></el-input> </el-form-item
            ></el-col>
          </el-row>
          <el-form-item label="公司" prop="company">
            <el-input v-model="ruleForm.company"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input v-model="ruleForm.mobile"></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="address">
            <el-input v-model="ruleForm.address"></el-input>
          </el-form-item>

          <el-form-item
            label-width="200px"
            label-position="left"
            label="挂牌企业"
            prop="listedenterprise"
            class="leftBox"
          >
            <el-radio-group
              text-color="#fff"
              v-model="ruleForm.listedenterprise"
            >
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label-width="200px"
            label-position="left"
            label="专精特新企业"
            prop="specialized"
            class="leftBox"
          >
            <el-radio-group fill="#0a4890" v-model="ruleForm.specialized">
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label-width="200px"
            label-position="left"
            label="上市后备库企业"
            prop="backupenterprises"
            class="leftBox"
          >
            <el-radio-group fill="#0a4890" v-model="ruleForm.backupenterprises">
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item>
            <div class="fromBom">
              <el-button
                class="submit"
                type="primary"
                @click="submitForm('ruleForm')"
                >提交</el-button
              >
              <span @click="goDetail">我已填写过</span>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import course from "@/components/course/course";
import dialog1 from "./dialog.vue";
import qs from "qs";
export default {
  data() {
    return {
      taps: [], // 导航栏
      tapIndex: 0, // 导航栏选择项
      item_id: 0, // 顶部导航栏id
      courses: [], // 课程
      page: 1, // 当前页
      total: 0, // 总条数
      perPage: 0, // 每页显示多少条
      dialogVisible: false,
      clkId: "",
      ruleForm: {
        title: "",
        job: "",
        company: "",
        mobile: "",
        address: "",
        listedenterprise: "是",
        specialized: "是",
        backupenterprises: "是",
      },
      rules: {
        title: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        job: [{ required: true, message: "请输入职位", trigger: "change" }],
        company: [{ required: true, message: "请输入公司", trigger: "change" }],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "change" },
        ],
        address: [{ required: true, message: "请输入地址", trigger: "change" }],
        listedenterprise: [
          //   { required: true, message: "是否为挂牌企业", trigger: "change" },
        ],
        specialized: [
          //   { required: true, message: "是否为专精特新企业", trigger: "change" },
        ],
        backupenterprises: [
          //   { required: true, message: "是否为上市后备库企业", trigger: "change" },
        ],
      },
    };
  },
  components: {
    course,
    dialog1
  },
  created() {
    this.getTap();
  },
  methods: {
    // 点击标签栏
    clkTap(index, id) {
      this.tapIndex = index;
      this.item_id = id;
      // 分类栏id
      this.GetTrainList();
    },

    // 点击课程查看详情
    clkImgDeal(id) {
      this.clkId = id;
      this.dialogVisible = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.tjdj();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 判断完毕跳转至详情、
    goDetail(id) {
      let index = this.$route.query.index;
      let queryPath = "Policyzone/GetTrainDetails";
      this.$router.push({
        path: "/train/tadetail",
        query: { index, id: this.clkId, queryPath },
      });
    },
    handleClose(done) {
      this.dialogVisible=false
      return
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    // 获取标签栏
    async getTap() {
      let url = this.$api.train.GetLeftPar;
      let data = {
        nav_id: 35,
      };
      let res = await this.$axios.post(url, qs.stringify(data));
      if (res.code) {
        this.taps = res.data;
        this.item_id = res.data[0].id;
        this.GetTrainList();
      }
    },
    // 提交登记
    async tjdj() {
      let url = this.$api.train.InfoSubmit;
      let res = await this.$axios.post(url, qs.stringify(this.ruleForm));
      if (res.code) {
        this.goDetail();
      }
    },
    // 获取数据列表页
    async GetTrainList() {
      let url = this.$api.train.GetTrainList;
      let data = {
        nav_id: 25,
        clas_id: 35,
        item_id: this.item_id,
        page: this.page,
        limit: 6,
      };
      let res = await this.$axios.post(url, qs.stringify(data));
      if (res.code) {
        this.courses = res.data.data;
        this.total = res.data.total;
        this.perPage = res.data.per_page;
      }
    },
    curChge(e) {
      this.page = e;
      this.GetTrainList();
    },
  },
};
</script>

<style lang="less" scoped>
.not-tap {
  margin-bottom: 30px;
  .not {
    .not-item {
      padding-top: 0 !important;
    }
  }
}
.title {
  text-align: center;
  font-size: 1.7rem;
  font-weight: 700;
  color: var(--themeColor);
}
.inputBox {
  margin-top: 30px;
}
.fromBom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  .submit {
    width: 100%;
    background: var(--themeColor);
    border-color: var(--themeColor);
  }
}
.el-radio__input.is-checked .el-radio__inner {
  background: var(--themeColor) !important;
  border-color: var(--themeColor) !important;
}
.el-radio__input.is-checked + .el-radio__label {
  color: var(--themeColor) !important;
}
.el-radio__input .el-radio__inner {
}
.el-radio.is-bordered + .el-radio.is-bordered {
  border: 1px solid #dcdfe6;
}
.custom-class {
  border-radius: 12px !important;
   width: 30rem !important;
}
.el-form-item__label {
  line-height: 10px !important;
}

.leftBox {
  display: flex;
  align-items: center;
  margin-top: -10px;
  .el-form-item__label {
    width: 50%;
  }
}
.border {
  border: 1px solid red;
}
@media (max-width: 768px) {
  .not-tap {
    margin-bottom: 10px;
  }
  .custom-class {
    width: 90% !important;
  }
}
</style>