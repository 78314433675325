<!-- 重点项目 -->
<template>
	<div class="project">
		<!-- 标题 -->
		<titles tleText="产权业务重点项目推荐">
			<div @click="clkMore">
				了解更多
				<i class="iconfont">&#xe642;</i>
			</div>
		</titles>
		<!-- 内容 -->
		<div class="cotain">
			<div class="cotain-item" v-for="(item,index) in project" v-if="index<8" :key="item.id" @click="clkDail(item.id)">
				<div class="co-img">
					<img :src="item.thumb" alt="">
				</div>
				<div class="tle">{{item.title}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import $ from "jquery"
	export default {
		data() {
			return {
				project:[]
			}
		},
		created() {
			this.getProJect()
		},
		methods:{
			clkMore() {
				this.$router.push({name:"referrals"})
			},
			clkDail(id) {
				this.$router.push({name:"promote_details",query:{id}})
			},
			// 查找重点项目推荐
			async getProJect() {
				let url = "Property/projectList"
				let data = {
					catid:98,
					page:1
				}
				let res = await this.$axios.get(url,{params:data})
				if(res.code) {
					this.project = res.data.data
				}
			},
			getCotain() {
				let letain = $(".project")
				let width = letain.outerWidth()/1440*100+'px'
				letain.css("font-size",width)
			}
		},
		mounted() {
			$(document).ready(()=>{
				this.getCotain()
				$(window).resize(()=>{
					this.getCotain()
				})
			})
				
		}
	}
</script>

<style scoped lang="less">
	
	.project {
		max-width: 1440px;
		margin: 0 auto;
		background-color: #fff;
		position: relative;
		box-sizing: border-box;
		padding: 0.63em 0.40em 0.75em 0.40em;
		/deep/ .titleh {
			margin-bottom: 0.35em;
		}
	}
	.cotain {
		display: grid;
		grid-template-columns: repeat(4,1fr);
		column-gap: 0.24em;
		row-gap: 0.4em;
		.cotain-item {
			.co-img {
				overflow: hidden;
				border: 1px solid rgba(235, 236, 239, 1);
				cursor: pointer;
				transition: box-shadow .5s;
				&:hover {
					box-shadow: 0 0 10px rgba(0,0,0,.2);
					&~.tle {
						color: var(--themeColor);
					}
				}
				img {
					width: 100%;
					height: 2em;
					object-fit: cover;
					box-shadow: 0 0 10px rgba(0,0,0,.3);
					transition:transform .5s;
					&:hover {
						transform: scale(1.1);
					}
				}
			}
			.tle {
				font-size: 16px;
				text-align: center;
				margin-top: 20px;
				display: -webkit-box;
				overflow: hidden;
				transition: all .5s;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				line-height: 16px;
			}
		}
	}
	
	@media (max-width:1200px) {
		.cotain {
			grid-template-columns: repeat(3,1fr);
		}
		
	}
	@media (max-width:992px) {
		.cotain {
			grid-template-columns: repeat(2,1fr);
			.cotain-item {
				.co-img {
					img {
						height: 3.5em;
					}
				}
			}
		}
		
	}
	
	@media (max-width:768px) {
		.cotain {
			grid-template-columns: repeat(2,1fr);
			.cotain-item {
				.tle {
					font-size: 14px;
					margin-top: 10px;
				}
			}
		}
		/deep/ .moret {
			font-size: 14px;
			.iconfont {
				font-size: 12px;
			}
		}
	}
	// @media (max-width:500px) {
	// 	.cotain {
	// 		.cotain-item {
	// 			.co-img {
	// 				img {
	// 					height: 7.5em;
	// 				}
	// 			}
	// 		}
	// 	}
	// }
</style>