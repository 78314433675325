<!-- 导航栏 -->
<template>
	<div class="father">
		<!-- 登录注册 -->
		<ul class="login">
			<li @click="clkLog(4)">
				<i class="el-icon-goods"></i>
				<span>管理后台</span>
			</li>
			<li @click="clkLog(1)">
				<i class="iconfont">&#xe64d;</i>
				<span>邮箱</span>
			</li>
			<li @click="clkLog(2)">
				<i class="iconfont">&#xe624;</i>
				<span>OA 办公</span>
			</li>
			<li @click="clkLog(3)">
				<i class="iconfont">&#xeca7;</i>
				<span>股交通</span>
			</li>
			<li>
				<i class="iconfont">&#xe658;</i>
				<span @click="login">登录</span>
				<span style="margin: 0 5px;">|</span>
				<span @click="resign">注册</span>
			</li>
		</ul>
		<div class="nav">
			<img src="../../assets/images/index/tleimg.png" alt="" class="logo" v-if="!navFlag" @click="clkIntext">
			<div v-else class="regain">
				<span @click="login">登录</span>
				<span> | </span>
				<span @click="resign">注册</span>
			</div>
			<ul class="nav-item" :class="navFlag==true?'nav-block':''">
				<li :class="navTag=='index'?'active':''" @click="clkTag('index')">首页<i class="iconfont h">&#xe642;</i>
				</li>
				<li :class="navTag=='region'?'active':''" @click="clkTag('register')">区域股权业务<i class="iconfont h">&#xe642;</i>
				</li>
				<!-- <li :class="navTag=='home'?'active':''" @click="clkTag('home')">产权交易业务<i class="iconfont h">&#xe642;</i> -->
				<li :class="navTag=='home'?'active':''" @click="clkNewHref()">产权交易业务<i class="iconfont h">&#xe642;</i>
				</li>
				<li :class="navTag=='member'?'active':''" @click="clkTag('show')">会员服务<i class="iconfont h">&#xe642;</i>
				</li>
				<li :class="navTag=='ecation'?'active':''" @click="clkTag('proplem')">投资者教育<i class="iconfont h">&#xe642;</i>
				</li>
				<li :class="navTag=='infotion'?'active':''" @click="clkTag('infolist')">信息披露<i class="iconfont h">&#xe642;</i>
				</li>
				<li :class="navTag=='train'?'active':''" @click="clkTag('business')">路演培训<i class="iconfont h">&#xe642;</i></li>
				<li :class="navTag=='policy'?'active':''" @click="clkTag('cupon')">政策专区<i class="iconfont h">&#xe642;</i></li>
				<li :class="navTag=='about'?'active':''" @click="clkTag('centerIntroduce')" style="margin-right: 0;">
					关于我们<i class="iconfont h">&#xe642;</i></li>
				<!-- <li >邮箱</li> -->
				<li class="d">OA 办公<i class="iconfont h">&#xe642;</i></li>
				<li class="d">加入收藏<i class="iconfont h">&#xe642;</i></li>
				<li class="solid">|</li>
				<li :class="navTag=='search'?'active':''" @click="clkTag('search')">
					<i class="iconfont" style="margin-right: 10px;">&#xe600;</i>
					<span>搜索</span>
				</li>
			</ul>
			<ul class="nav-none" @click="clkNav" :class="navFlag==true?'nav-clk':''">
				<li></li>
				<li></li>
				<li></li>
			</ul>
		</div>
	</div>
</template>

<script>
	import $ from "jquery"
	export default {
		data() {
			return {
				navFlag: false
			}
		},
		props: {
			navTag: {
				type: String,
				default: ''
			}
		},
		methods: {
			// 点击三条斜杠
			clkNav() {
				this.navFlag = !this.navFlag
				$(".nav-item").stop().slideToggle();
			},
			// 点击导航
			clkTag(name) {
				this.$router.push({
					name,
					query: {
						index: 0
					}
				});
			},

			// 跳外链
			clkNewHref() {
				location.href = 'https://www.gsfae.cn/#/index'
			},

			// 点击登录
			clkLog(index) {
				switch (index) {
					case 1:
						location.href = "http://mail.gsotc.com.cn/"
						break;
					case 2:
						window.location.href = "http://192.168.1.88:88"
						break;
					case 3:
						// window.location.href = "http://mgr.gsotc.com.cn/#/login"
						window.open("https://mgr.gsotc.com.cn/#/login","_blank")
						break;
					case 4:
						window.open("http://www.gsotc.com.cn/admin","_")
						break;
				}
			},
			login() {
				// location.href = "http://61.178.68.41:8888/login.html"
				// location.href = "http://mgr.gsotc.com.cn"
				// location.href = "http://biz.gsotc.com.cn/#/login"
				window.open("https://biz.gsotc.com.cn/#/login","_blank")
			},
			resign() {
				// location.href = "http://61.178.68.41:8888/register-1.html"
				// location.href = "http://mgr.gsotc.com.cn"
				// location.href = "http://biz.gsotc.com.cn/#/register"
				window.open("https://biz.gsotc.com.cn/#/register","_blank")
			},
			//点击logo跳转至大首页
			clkIntext() {
				this.$router.push({
					name: "index"
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.father {
		background-color: #fff;
	}

	.login {
		display: flex;
		max-width: 1440px;
		margin: 0 auto;
		font-size: 16px;
		font-family: SourceHanSansCN;
		font-weight: 300;
		color: #000000;
		justify-content: flex-end;
		padding-top: 28px;
	}

	.login li {
		margin-right: 40px;
		cursor: pointer;

		&:hover:not(&:last-child) {
			color: var(--themeColor);
		}

		&:last-child {
			span {
				&:hover {
					color: var(--themeColor);
				}
			}
		}
	}

	.login li:last-child {
		margin-right: 0;
	}

	.login i {
		margin-right: 10px;
	}

	.nav {
		max-width: 1440px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 16px;
		font-family: SourceHanSansCN;
		font-weight: 300;
		box-sizing: border-box;
		padding: 28px 0;
		position: relative;

		img {
			cursor: pointer;
		}

		.regain {
			span {
				color: rgba(152, 155, 158, 1);

				&:nth-child(2) {
					margin: 0 10px;
				}
			}
		}

		.nav-none {
			display: none;
			width: 25px;
			height: 17px;

			li {
				width: 100%;
				height: 3px;
				border-radius: 10px;
				background-color: #000000;
				transition: all .2s;
				transform-origin: left;
			}

			&.nav-clk {
				li {
					&:nth-child(1) {
						transform: rotateZ(45deg);
						position: relative;
						top: -3px;
					}

					&:nth-child(2) {
						opacity: 0;
						position: relative;

					}

					&:nth-child(3) {
						transform: rotateZ(-45deg);
					}
				}
			}
		}
	}

	.nav .nav-item {
		display: flex;
	}

	.nav-item li {
		margin-right: 40px;
		cursor: pointer;

		&.d {
			display: none;
		}

		i.h {
			display: none;
		}

		&.active {
			color: var(--themeColor);
			font-weight: bold;
		}

		&:hover {
			color: var(--themeColor);
			font-weight: bold;
		}
	}

	.nav-item li:last-child {
		margin-right: 0;
	}

	.nav-item .about,
	.nav-item .solid {
		margin: 0 10px;
	}

	.nav .logo {
		max-width: 290px;
	}

	@media (max-width:1480px) {
		.login {
			padding: 20px;
		}

		.nav {
			padding: 20px 20px;

			.logo {
				width: 20%;
			}
		}

	}

	@media (max-width:1340px) {
		.login {
			display: none;
			margin-bottom: 10px;

			li {
				margin-right: 15px;
			}
		}

		.nav {
			padding: 15px 20px;
			height: 70px;
			box-sizing: border-box;
			background-color: white;

			.logo {
				height: 100%;
				width: auto;
			}

			.nav-none {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				cursor: pointer;
			}

			.nav-item {
				flex-direction: column;
				position: absolute;
				left: 0;
				top: 70px;
				right: 0;
				z-index: 9999999;
				background-color: white;
				display: none;

				&.nav-block {
					// display: block;
				}

				li {
					height: 50px;
					line-height: 50px;
					text-align: center;
					border-bottom: 1px solid #ccc;
					margin-right: 0;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0 20px;

					&.d {
						display: block;
						display: flex;
						justify-content: space-between;
						padding: 0 20px;
					}

					i.h {
						display: block;
						color: rgba(152, 155, 158, 1);
					}

					&:hover {
						color: var(--themeColor);
						cursor: pointer;
						font-weight: bold;
					}

					&.solid {
						display: none;
					}
				}
			}
		}
	}

	@media (max-width:768px) {
		.login {
			display: none;
		}

		.nav {
			.logo {}
		}
	}
</style>