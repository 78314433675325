<template>
	<ul class="cour">
		<li v-for="(item,index) in courses" :key="item.id" @click="clkImgDeal(item.id)">
			<div class="cou-img">
				<img :src="item.thumb" alt="">
				<p class="type imgs" v-if="item.type==1">图文</p>
				<p class="type autio" v-else-if="item.type==2">语音</p>
				<p class="type videos" v-else-if="item.type==3">视频</p>
				<p class="video"><i class="iconfont">&#xea82;</i></p>
			</div>
			<div class="co-cain">
				<h2 class="co-tle">{{item.title}}</h2>
				<p class="co-txt">{{item.description}}</p>
				<p class="eye"><i class="iconfont">&#xe661;</i>{{item.hits}}</p>
			</div>
		</li>
	</ul>
</template>

<script>
	export default {
		methods:{
			// 点击查看详情
			clkImgDeal(id) {
				this.$emit("clkImgDeal",id)
			}
		},
		
		created() {
			
		},
		watch:{
			courses() {}
		},
		props:{
			courses:{
				type:Array,
				default:()=>[]
			},
			// 路由跳转
			rouPath:{
				type:String,
				default:""
			},
			// 请求路径
			queryPath:{
				type:String,
				default:""
			}
		}
	}
</script>

<style lang="less" scoped>
	.cour {
		display: grid;
		grid-template-columns: repeat(3,1fr);
		column-gap: 20px;
		row-gap: 30px;
		li {
			box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
			transition: all .5s;
			cursor: pointer;
			&:hover {
				transform: translateY(-5px);
			}
			.cou-img {
				position: relative;
				&::after {
					display: none;
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: rgba(0,0,0,.5);
				}
				&:hover {
					.video {
						display: flex;
					}
					&::after {
						display: block;
					}
				}
				img {
					width: 100%;
					height: 2.06em;
					object-fit: cover;
				}
				.type {
					position: absolute;
					width: 56px;
					height: 24px;
					color: rgba(255, 255, 255, 1);
					font-size: 14px;
					font-weight: 400;
					border-radius: 0px 12px 12px 12px;
					line-height: 24px;
					text-align: center;
					top: 10px;
					left: 10px;
					z-index: 999;
					&.videos {
						// background: rgba(0, 100, 175, .8);
						background: #0064AFCC;
					}
					&.imgs {
						// background-color: orange;
						background-color:#F7C316CC;
					}
					&.autio {
						// background-color: limegreen;
						background: #43CF7CCC;
					}
				}
				.video {
					i {
						font-size: 30px;
					}
					color: white;
					width: 56px;
					height: 56px;
					background: rgba(255, 255, 255, 0.2);
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					border-radius: 50%;
					z-index: 999;
					display: none;
					align-items: center;
					justify-content: center;
					cursor: pointer;
				}
			}
			.co-cain {
				padding: 20px;
				.co-tle {
					color: rgba(51, 51, 51, 1);
					font-size: 22px;
					font-weight: 500;
					margin-bottom: 12px;
				}
				.co-txt {
					color: rgba(102, 102, 102, 1);
					font-size: 16px;
					font-weight: 400;
					line-height: 23px;
				}
				.eye {
					color: rgba(153, 153, 153, 1);
					font-size: 16px;
					font-weight: 400;
					display: flex;
					align-items: center;
					margin-top: 20px;
					i {
						font-size: 18px;
						margin-right: 5px;
					}
				}
			}
			
		}
	}
	
	@media (max-width:1200px) {
		.cour {
			grid-template-columns: repeat(2,1fr);
		}
	}
	@media (max-width:768px) {
		.cour {
			li {
				.co-cain {
					.co-tle {
						font-size: 18px;
					}
				}
			}
		}
	}
	@media (max-width:600px) {
		.cour {
			grid-template-columns: repeat(1,1fr);
			li {
				.cou-img {
					img {
						height: 250px;
					}
				}
			}
		}
	}
</style>