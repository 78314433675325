<template>
	<div>
		<!-- 导航栏 -->
		<navs navTag="index"></navs>
		<!-- 轮播图 -->
		<swiper></swiper>
		<!-- 活动 -->
		<activity></activity>
		<!-- 新闻 -->
		<news></news>
		<!-- 市场数据 -->
		<market></market>
		<!-- 重点项目 -->
		<project></project>
		<!-- 竞价大厅 -->
		<!-- <price></price> -->
		<!-- 路演培训 -->
		<train></train>
		<!-- 挂牌公告 -->
		<notice></notice>
		<!-- 友情链接 -->
		<frient></frient>
		<!-- 底部介绍 -->
		<bottom></bottom>
		<!-- 飘窗 -->
		<div class="boxx" v-if="pc">
			<p class="box" @click="clkBox" :style="{display:pcflag==true?'block':'none'}">
				<img :src="pc.thumb" alt="">
				<i class="el-icon-close" @click.stop="pcflag = false"></i>
			</p>
		</div>
		<!-- 固定连接 -->
		<div class="fixed"  v-if="ntcShow">
			<div v-for="(item,index) in fixedImg" :key="index" class="fix-item" @click="lj(item.url)">
				<div class="notic" style="font-size: 16px;">
					<div>公告</div>
					<div class="el-icon-close" @click.stop="clkNtcClo"></div>
				</div>
				<div class="ntc-tle">{{item.title}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	// 轮播图
	import swiper from "@/components/index/swiper"
	// 活动
	import activity from "@/components/index/activity"
	// 新闻
	import news from "@/components/index/news"
	// 市场数据
	import market from "@/components/index/market"
	// 重点项目
	import project from "@/components/index/project"
	// 竞价大厅
	import price from "@/components/index/price"
	// 路演培训
	import train from "@/components/index/train"
	// 公告
	import notice from "@/components/index/notice"
	// 友情链接
	import frient from "@/components/index/frient"
	// 底部介绍
	import $ from 'jquery'
	import qs from "qs"
	export default {
		data() {
			return {
				pc: {},
				a: 0,
				pcflag: true,
				fixedImg: [],
				text:'',
				ntcShow:true
			}
		},
		components: {
			swiper,
			activity,
			news,
			market,
			project,
			price,
			train,
			notice,
			frient
		},
		methods: {
			//固定图片链接
			lj(url){
				window.location.href = url
			},
			clkBox() {
				window.location.href = this.pc.url
			},
			// 右下角图片链接
			getFixed() {
				this.$axios.get("Index/getList", {
					params: {
						catid: 178
					}
				}).then(res => {
					if (res.code) {
						this.fixedImg = res.data.data
						console.log(this.fixedImg);
						// this.$nextTick(() => {
						// 	this.getLeft()
						// })
					}
				})
			},
			getCotain() {
				let letain = $("#idx-cain")
				let width = letain.outerWidth() / 1440 * 100 + 'px'
				letain.css("font-size", width)
			},
			getCk() {
				this.$axios.post("Base/getBanner", {
					catid: 146
				}).then(res => {
					if (res.code) {
						this.pc = res.list[0]
						this.$nextTick(() => {
							this.box()
						})
					}
				})
			},
			box() {
				var obj = $(".box");
				var x = obj.css("left"); //盒子距离左部的位置
				var y = obj.css("top"); //盒子距离顶部的位置
				x = parseFloat(x)
				y = parseFloat(y)
				var objwid = obj.width(); //盒子的宽
				var objhei = obj.height();
				var winwid = $(window).width(); //页面的宽
				var winhei = $(window).height();
				var max = 0; //设置最大视觉差，就是感觉这个距离刚好碰到
				var winx = winwid - objwid - max; //盒子x轴最远达到的距离
				var winy = winhei - objhei - max; //盒子y轴最远达到的距离
				var sx = 0; //x轴是否返回的状态，0是值++即正向移动，1是值--即返回
				var sy = 0;
				this.a = setInterval(function() {
					if (sx == 0) {
						obj.css("left", x++);
					} else if (sx == 1) {
						obj.css("left", x--);
					}
					if (x <= 0) {
						sx = 0;
					} else if (x >= winx) {
						sx = 1;
					}

					if (sy == 0) {
						obj.css("top", y++);
					} else if (sy == 1) {
						obj.css("top", y--);
					}
					if (y <= 0) {
						sy = 0;
					} else if (y >= winy) {
						sy = 1;
					}
				}, 10)
			},
			// 获取飘窗是否隐藏
			getPcDis() {
				let pcFlag = localStorage.getItem("pcdis")
				if (pcFlag == null) {
					return false
				}
				if (pcFlag == "false") {
					this.pcflag = false
				} else {
					this.pcflag = true
				}
			},
			
			// 点击关闭
			clkNtcClo() {
				this.ntcShow = false
			},
			
			clkPc() {
				let pcFlag = localStorage.getItem("pcdis")
				if (pcFlag == null) {
					localStorage.setItem("pcdis", false)
					this.pcflag = false
					return false
				}
				if (pcFlag == "false") {
					this.pcflag = true
					localStorage.setItem("pcdis", true)
				} else {
					this.pcflag = false
					localStorage.setItem("pcdis", false)
				}
			},
			hovBox() {
				let that = this
				$(".box").hover(function() {
					clearInterval(that.a)
				}, function() {
					that.box()
				})
			}
		},
		created() {
			this.getCk()
			this.getFixed()
		},
		mounted() {
			$(document).ready(() => {
				this.hovBox()
				$(window).resize(() => {
					// this.getLeft()
				})
			})
		}
	}
</script>

<style lang="less">
	.notic {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: white;
		background-color: var(--themeColor);
		padding: 5px 10px;
	}
	.ntc-tle {
		font-size: 14px;
		border: 1px solid lightgray;
		text-indent: 2em;
		padding: 8px 2px;
		line-height: 23px;
		text-align: justify;
	}
	.fixed {
		position: fixed;
		bottom: 0;
		z-index: 999999;
		width: 200px;
		right: 0;
		.fix-item {
			margin-bottom: 20px;
			cursor: pointer;
			transition: box-shadow .3s;
			background: #fff;
			position: relative;
			i {
				position: absolute;
				top: 5px;
				right: 5px;
				display: none;
			}

			&:hover {
				box-shadow: 0 0 10px rgba(0, 0, 0, .3);
				i {
					display: block;
				}
			}

			&:last-child {
				margin-bottom: 0;
			}

			img {
				width: 130px;
			}
		}

	}

	.pc {
		position: fixed;
		bottom: 0;
		right: 0;
		z-index: 999;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 15px 5px;
		color: white;
		background-color: var(--themeColor);
		cursor: pointer;

		&:hover {
			background-color: #4a8fc4;
		}

		.text {
			span {
				font-size: 14px;
				letter-spacing: 2px;
			}

			margin-top: 10px;
		}
	}

	#idx-cain {
		width: 1440px;
		margin: 0 auto;
	}

	.box {
		position: fixed;
		top: 10px;
		left: 10px;
		width: 200px;
		height: 200px;
		z-index: 999999999999;
		cursor: pointer;
		transition: transform .3s;

		&:hover {
			transform: scale(1.2);

			.el-icon-close {
				display: block;
			}
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.el-icon-close {
			display: none;
			position: absolute;
			top: 0;
			right: 0;
			background-color: rgba(0, 0, 0, .5);
			color: white;
			padding: 5px;
			font-size: 16px;
		}
	}

	body {
		background-color: #f2f2f2;
	}

	@media (max-width:768px) {

		.boxx,
		.pc {
			display: none;
		}
	}
</style>
