<!-- 业务介绍 -->
<template>
	<div class="serive">
		<div v-html="content"></div>
	</div>
</template>

<script>
	import qs from "qs"
	import util from "@/assets/script/util.js"
	export default {
		data() {
			return {
				title:"",
				index:1,
				content:""
			}
		},
		created() {
			let id = this.$route.query.id
			this.getDail(id)
		},
		methods:{
			// 获取详情
			getDail(id) { 
				let url = this.$api.stock.Detail
				let data = {
					nav_id:id
				}
				this.$axios.post(
					url,
					qs.stringify(data)
				).then(res=>{
					let ctx = util.setContent(res.list.content)
					this.content = ctx
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.serive {
		box-sizing: border-box;
		background-color: #fff;
		.img {
			img {
				width: 100%;
			}
			&.re-back1 {
				background: linear-gradient(135deg, rgba(40, 98, 168, 1) 0%, rgba(67, 131, 209, 1) 100%);
			}
			&.re-back2 {
				background: linear-gradient(135deg, rgba(232, 181, 63, 1) 0%, rgba(247, 205, 106, 1) 100%);
			}
		}
		.sv-info {
			padding: 0 20px 100px 20px;
			h3 {
				color: rgba(51, 51, 51, 1);
				font-size: 22px;
				font-weight: 700;
				margin-top: 30px;
				margin-bottom: 16px;
			}
			p {
				color: rgba(102, 102, 102, 1);
				font-size: 16px;
				font-weight: 400;
				margin-bottom: 20px;
				line-height: 30px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	
	@media (max-width:768px) {
		.serive {
			grid-template-columns: repeat(1,1fr);
		}
	}
</style>