<template>
	<!-- 表格 -->
	<div class="table">
		<div class="tal-item">
			<div class="tal-tm">
				<div class="lab-tal">
					<label>披露类型</label>
					<el-select v-model="typetxt" placeholder="请选择" style="width: 100%;" @change="selChange">
					    <el-option
							v-for="(item,index) in infoTye"
							:key="index"
							:label="item.title"
							:value="item.id">
					    </el-option>
					</el-select>
					
				</div>
				<div class="lab-tal">
					<label>披露时间</label>
					
					<el-date-picker
					      v-model="time"
					      type="daterange"
					      start-placeholder="开始日期"
					      end-placeholder="结束日期"
					      :default-time="['00:00:00', '23:59:59']"
						  value-format="yyyy-MM-dd">
					</el-date-picker>
				</div>
				<div class="lab-tal">
					<label>企业名称</label>
					<el-input v-model="search" placeholder="请输入内容"></el-input>
				</div>
				<div class="lab-sear" @click="clkSea">搜索</div>
			</div>
			
		</div>
	    <el-table
			:data="tableData.data"
			:header-cell-style="{background:'#f7fbfe',height:'70px'}"
			:row-style="{height:'70px'}"
			style="width: 100%">
			<el-table-column
				prop="title"
				label="名称"
				align="center"
				width="250">
			</el-table-column>
			<el-table-column
				prop="type_name"
				label="披露类型"
				align="center"
				width="120">
			</el-table-column>
			<el-table-column
				prop="company"
				label="企业名称"
				align="center">
			</el-table-column>
			<el-table-column
				prop="inputtime"
				label="上传日期"
				align="center"
				width="100">
			</el-table-column>
			<el-table-column
			    label="操作"
				align="center"
				fixed="right"
				width="150">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" @click="clkLook(scope.row.id)">查看</el-button>
						<el-button type="success" size="mini" @click="clkFile(scope.row)">下载</el-button>
					</template>
			</el-table-column>
	    </el-table>
		<!-- 分页 -->
		<div class="block">
		    <el-pagination
				background
				:page-size="parseInt(tableData.per_page)"
				layout="total, prev, pager, next, jumper"
				:total="tableData.total"
				@current-change="proChange">
		    </el-pagination>
		</div>
		<div class="block2">
			<phonePg></phonePg>
		</div>
	</div>
</template>

<script>
	import moment from "moment"
	export default {
		data() {
			return {
				crumbs:['首页','信息披露'],
				page:1,
				// 表格列表数据
				tableData:[],
				// 信息披露
				infoTye:[],
				// 信息披露文本
				typetxt:"",
				type:'',
				search:"",
				time:"",
			}
		},
		created() {
			this.getType()
			this.getList()
		},
		methods:{
			// 获取列表
			getList() {
				this.$axios.get("Download/getList",{
					params:{
						catid:193,
						page:this.page,
						limit:10,
						type:this.type,
						search:this.search,
						start_time:this.time[0],
						end_time:this.time[1]
					}
				}).then(res=>{
					if(res.code) {
						this.tableData = res.data
					}
				})
			},
			// 查询披露类型
			getType() {
				this.$axios.get("Download/getTypeList").then(res=>{
					console.log(res);
					if(res.code) {
						this.infoTye = res.data
					}
				})
			},
			// 选择信息披露触发
			selChange(e) {
				console.log(e);
				this.type = e
			},
			// 点击下载
			clkFile(item) {
				location.href = item.file
				let aDom = document.createElement('a');
				aDom.style.display = 'none';
				aDom.href = item.file;
				aDom.setAttribute('download', item.title);
				document.body.appendChild(aDom);aDom.click();
				document.body.removeChild(aDom);
			},
			// 页数改变时触发
			proChange(e) {
				this.page = e
				this.getList()
			},
			// 日期选择触发
			chgTime(e) {
				console.log(e);
			},
			// 点击搜索
			clkSea() {
				this.getList()
			},
			// 点击查看详情
			clkLook(id) {
				let index = this.$route.query.index
				this.$router.push({path:"/infotion/infodetail",query:{index,id}})
			}
		}
	}
</script>

<style lang="less" scoped>
		.table {
			background-color: white;
			box-sizing: border-box;
			.el-table .el-table__cell {
				padding: 20rpx 0 !important;
			}
			.tal-item {
				display: flex;
				margin-bottom: 30px;
				flex-wrap: wrap;
				.tal-tm {
					display: flex;
					flex-grow: 1;
				}
				.lab-tal,.lab-sear {
					flex-shrink: 0;
				}
			}
			.lab-tal {
				display: flex;
				align-items: center;
				margin-right: 24px;
				&:first-child {
					flex-grow: 1;
				}
				label {
					flex-shrink: 0;
					color: rgba(51, 51, 51, 1);
					font-size: 14px;
					font-weight: 400;
					margin-right: 12px;
				}
			}
			.lab-sear {
				width: 104px;
				font-size: 14px;
				font-weight: 400;
				color: white;
				background-color: var(--themeColor);
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 2px;
				cursor: pointer;
			}
			
		}
	
	@media (max-width:1265px) {
			.table {
				.tal-item {
					flex-direction: column;
					.tal-tm {
						&:first-child {
							margin-bottom: 15px;
						}
						.lab-tal {
							&:last-child {
								margin-right: 0;
							}
						}
					}
				}
			}
		}
	@media (max-width:900px) {
			.table {
				padding: 15px;
				.tal-item {
					margin-bottom: 20px;
				}
			}
		}
	
	@media (max-width:800px) {
			.table {
				.tal-item {
					.tal-tm {
						&:first-child {
							margin-bottom: 15px;
							flex-direction: column;
							.lab-tal {
								margin-right: 0;
								&:first-child {
									margin-bottom: 15px;
								}
								.el-date-editor {
									width: 100%;
								}
							}
						}
						
					}
				}
			}
		}
	
	@media (max-width:500px) {
		.table {
			.tal-item {
				.tal-tm {
					&:last-child {
						flex-direction: column;
						.lab-tal {
							margin-right: 0;
						}
						.lab-sear {
							margin-top: 15px;
						}
					}
				}
			}
		}
	}
</style>