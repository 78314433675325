<!-- 竞价大厅 -->
<template>
	<div class="price">
		<titles tleText="竞价大厅" style="margin-bottom: 20px;">
			<div class="tip">
				<i class="iconfont">&#xe68e;</i>
				提醒：竞价情况已实际为准
			</div>
		</titles>
		<iframe src="https://api.ejy365.com/e-jy/portal/jjdt/list?pages=1&pageSize=10&orgId=103027" 
		scrolling="no" frameborder="0" width="100%" height="298" ></iframe>
	</div>
</template>

<script>
	import $ from "jquery"
	export default {
		data() {
			return {
				taps:['产权交易','询价采购'],
				tapIndex:0,
				tableData:[
					{
						name:"中信银行兰州分行",
						time:"0",
						price:"2450",
						money:"100",
						type:1
					},
					{
						name:"中信银行兰州分行一批存取机等银行报废自助设备",
						time:"0",
						price:"2450",
						money:"100",
						type:2
					},
					{
						name:"中信银行兰州分行一批存取机等银行报废自助设备",
						time:"0",
						price:"2450",
						money:"100",
						type:0
					},
					{
						name:"中信银行兰州分行一批存取机等银行报废自助设备",
						time:"0",
						price:"2450",
						money:"100",
						type:1
					},
					{
						name:"中信银行兰州分行一批存取机等银行报废自助设备",
						time:"0",
						price:"2450",
						money:"100",
						type:1
					},
					{
						name:"中信银行兰州分行一批存取机等银行报废自助设备",
						time:"0",
						price:"2450",
						money:"100",
						type:1
					},
					{
						name:"中信银行兰州分行一批存取机等银行报废自助设备",
						time:"0",
						price:"2450",
						money:"100",
						type:1
					},
					{
						name:"中信银行兰州分行一批存取机等银行报废自助设备",
						time:"0",
						price:"2450",
						money:"100",
						type:1
					},
					{
						name:"中信银行兰州分行一批存取机等银行报废自助设备",
						time:"0",
						price:"2450",
						money:"100",
						type:1
					}
				],
				lineNum:0
			}
		},
		methods:{
			clkTap(index) {
				this.tapIndex = index;
			},
			// 点击前往报名
			clkBm() {
				this.$router.push({name:"options_details"})
			},
			// 点击查看更多
			tapMore() {
				// 获取总高度
				let priType = $(".pri-type").height()
				
			},
			// 计算有多少行
			getLine() {
				// 获取总高度
				let priType = $(".pri-type").height()
				let active = $(".pri-type>.pri-tap>.active")
				// 单个元素的高度
				let actHeg = active.outerHeight()
				// 元素的下边距
				let actMb = parseFloat(active.css("margin-bottom"))
				// 总高度/单个高度 = 行数
				let line = Math.round(priType/(actHeg+actMb))
				this.lineNum = line
				
				if(line>2) {
					$(".pri-type").css("height",(actHeg+actMb)*2+'px')
				}else{
					$(".more").css("display","none")
				}
			}
		},
		mounted() {
			this.$nextTick(()=>{
				this.getLine()
				window.onresize = ()=>{
					this.getLine()
				}
			})
		}
	}
</script>

<style lang="less" scoped>
	/deep/ .not-tap::after {
		height: 1px;
	}
	.el-btn {
		width: 100px;
		height: 45px;
		box-sizing: border-box;
		font-size: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		&.qw {
			background-color: var(--themeColor);
			color: white;
			&:hover {
				background-color: rgba(10, 72, 144, .6);
			}
		}
		&.jz {
			background-color: #eaebed;
			color: #9fa4af;
			cursor: no-drop;
		}
		&.hw {
			border: 1px solid #e3700b;
			color: #e3700b;
		}
	}
	.price {
		max-width: 1440px;
		margin: 0 auto;
		position: relative;
		padding: 75px 40px 63px 40px;
		box-sizing: border-box;
		background-color: #f9faff;
		/deep/ .block {
			padding-top: 60px;
			padding-bottom: 20px;
			background-color: #fff;
			margin-top: 0;
		}
		.tap {
			margin-top: 20px;
			.tap-type {
				display: none;
				color: #555;
			}
			.search {
				margin-bottom: 5px;
				input {
					border: none;
					outline: none;
					background-color: none;
					border: 1px solid #c2c6cd;
					height: 35px;
					width: 250px;
					padding-left: 10px;
				}
				.btn-sea {
					height: 37px;
					border-radius: 0;
					background-color: none;
					outline: none;
					border: none;
					width: 60px;
					background-color: var(--themeColor);
					color: white;
				}
			}
		}
		
		.pri-type {
			display: flex;
			margin-top: 30px;
			font-size: 16px;
			border-bottom: 1px solid #ccc;
			position: relative;
			.tle {
				flex-shrink: 0;
				margin-right: 40px;
				align-self: flex-start;
				position: relative;
				margin-top: 2px;
				padding-left: 7px;
				&::before {
					display: block;
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					width: 3px;
					left: 0;
					background-color: var(--themeColor);
				}
			}
			.pri-tap {
				display: flex;
				flex-wrap: wrap;
				overflow: hidden;
				.active {
					border: 1px solid var(--themeColor);
					color: var(--themeColor);
				}
				.tap-item {
					margin-right: 40px;
					padding: 2px 5px;
					margin-bottom: 20px;
					color: #333;
					cursor: pointer;
				}
			}
			.more {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: -45px;
				font-size: 16px;
				color: #666666;
				display: flex;
				align-items: center;
				height: 44px;
				z-index: 99999;
				border: 1px solid #cccccc;
				border-top: none;
				background-color: #f9faff;
				padding: 0 20px;
				cursor: pointer;
			}
		}
		.pri-table {
			margin-top: 70px;
		}
		.pri-pge {
			display: none;
		}
	}
	@media (max-width:768px) {
		.price {
			display: none;
			padding: 40px 10px;
			padding-top: 10px;
			.pri-table {
				margin-top: 0;
			}
			.block {
				display: none;
			}
			.tap {
				.search {
					display: none;
				}
				.tap-type {
					display: block;
					font-size: 14px;
					
				}
			}
			.pri-type {
				display: none;
			}
			.pri-pge {
				display: grid;
				grid-template-columns: repeat(2,1fr);
				&>div {
					text-align: center;
					padding: 20px 0;
					&.next {
						background-color: #d6d9dd;
						i {
							color: #9aa0ab;
						}
					}
					&.pref {
						border: 1px solid rgba(235, 236, 239, 1);
						background-color: white;
						i {
							color: #666666;
						}
					}
				}
			}
		}
	}
</style>