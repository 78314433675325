<template>
	<div>
		<scNav navTag="scZcfg" :themeColor="themeColor"></scNav>
		<!-- 面包屑 -->
		<div style="max-width: 1440px;margin: 0 auto;">
			<crumbs :crumbs="['首页','政策法规']"></crumbs>
		</div>
		<div class="sc-width">
			<tap :taps="taps" :bgColor="themeColor"><i></i></tap>
			<template v-if="zcfg.data.length">
				<newList @clkCup="clkCup" :themeColor="themeColor" :policy="zcfg.data"></newList>
				<div class="block">
					<el-pagination
						background
						:page-size="parseInt(zcfg.per_page)"
						@current-change="downPage"
						layout="total, prev, pager, next, jumper"
						:total="zcfg.total">
					</el-pagination>
				</div>
				<phonePg :page="page" :total="zcfg.total" :perPage="parseInt(zcfg.per_page)" @pageNext="pageNext" @pagePref="pagePref"></phonePg>
			</template>
			<template v-else>
				<el-empty description="暂无数据"></el-empty>
			</template>
		</div>
		
		<bottom :bgColor="themeColor"></bottom>
	</div>
</template>

<script>
	import newList from "@/components/new/newList"
	import scNav from '@/components/special/scNav'
	import phonePg from "@/components/phonePg/phonePg"
	export default {
		components:{
			newList,
			scNav,
			phonePg
		},
		data() {
			return {
				taps:['政策法规'],
				themeColor:"",
				page:1,
				zcfg:{},
				type:0,
				catid:0
			}
		},
		created() {
			let type = this.$route.query.type
			this.type = type
			if(type==1) {
				// 专精特新版
				this.themeColor = "#386eb8"
				this.catid = 121
			}else if(type==2) {
				// 科技创新版	
				this.themeColor = "#47a8c8"
				this.catid = 130
			}else if(type==3) {
				// 交易版
				this.themeColor = "#d3a21e"
				this.catid = 138
			}
			this.getNewList(type)
		},
		methods:{
			clkCup(index,id) {
				let type = this.$route.query.type
				this.$router.push({name:"scZcfgDail",query:{type,id}})
			},
			getNewList(type) {
				let url = ""
				if(type==1) {
					url = this.$api.base+"Specialization/getList"
				}else if(type==2) {
					url = this.$api.base+"Technology/getList"
				}else if(type==3) {
					url = this.$api.base+"Trading/getList"
				}
				let data = {
					catid:this.catid,
					limit:10,
					page:this.page
				}
				console.log(data);
				this.$axios.get(url,{params:data}).then(res=>{
					console.log(res);
					if(res.code) {
						this.zcfg = res.data
					}
				})
			},
			downPage(e) {
				this.page = e
				this.getNewList(this.type)
			},
			// 点击上一页
			pageNext() {
				if(!this.page--) {
					return false
				}
				this.page--
				this.getNewList(this.type)
			},
			// 点击下一页
			pagePref() {
				if((this.page+1)*this.zcfg.per_page>this.zcfg.total) {
					return false
				}
				this.page ++
				this.getNewList(this.type)
			},
		}
	}
</script>

<style>
	
</style>