<template>
	<div>
		<h1 class="title">{{probm.title}}</h1>
		<div class="time">
			<slot name="addIcon"></slot>
			<p><i class="iconfont">&#xe60a;</i>{{probm.inputtime || probm.date}}</p>
		</div>
		<div style="font-size: 16px;" class="vhtml" v-html="probm.subject || probm.content"></div>
		<slot name="shareIcon"></slot>
	</div>
</template>

<script>
	export default {
		props:{
			probm:{
				type:Object,
				default:()=>{}
			}
		}
	}
</script>

<style lang="less" scoped>
	h4,p {
		font-size: 16px;
	}
	h4 {
		margin: 30px 0;
	}
	p {
		line-height: 30px;
	}
	.title {
		color: rgba(51, 51, 51, 1);
		font-size: 34px;
		font-weight: 700;
		text-align: center;
		margin-bottom: 60px;
		line-height: 40px;
		margin-top: 50px;
	}
	.time {
		display: flex;
		color: rgba(153, 153, 153, 1);
		font-size: 14px;
		font-weight: 500;
		padding-bottom: 20px;
		border-bottom: 0.5px solid rgba(235, 236, 239, 1);
		margin-bottom: 40px;
		p:last-child {
			margin-left: 30px;
		}
	}
	.marb30 {
		margin-bottom: 10px;
		&.mg30 {
			margin-bottom: 40px;
		}
	}
	@media (max-width:768px) {
		.title {
			font-size: 22px;
			margin-top: 10px;
			margin-bottom: 10px;
			font-weight: inherit;
		}
		.time {
			flex-direction: column;
			font-size: 14px;
			font-weight: 300;
			margin-bottom: 5px;
			padding-bottom: 5px;
			P:first-child {
				font-size: 14px;
				font-weight: 300;
			}
			p:last-child {
				margin-left: 0;
				font-size: 14px;
				font-weight: 300;
			}
		}
	}
</style>