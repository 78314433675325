<!-- 交易制度 -->
<template>
	<div class="re-box">
		<div class="deal" v-for="(item,index) in list.data" :key="index" @click="clkTableRow(item.id)">
			<span class="text">
				<img src="../../assets/images/trading/logo.png" alt="">
				{{item.title}}</span>
			<span class="data">{{item.inputtime}}</span>
		</div>
		<div class="block" v-if="list.data.length">
		    <el-pagination
				background
				:page-size="list.per_page"
				layout="total, prev, pager, next, jumper"
				:total="list.total"
				@current-change="curChge">
		    </el-pagination>
		</div>
	</div>
</template>

<script>
	import moment from "moment"
	export default {
		data() {
			return {
				list: {},
				page:1
			};
		},
		created() {
			this.regimeList()
		},
		methods: {
			// 点击制度详情
			clkTableRow(id) {
				let index = this.$route.query.index
				this.$router.push({
					name: "system_details",
					query:{id}
				})
			},
			// 获取制度列表
			regimeList() {
				let url = this.$api.system.regimeList
				let data = {
					catid: 83,
					type: 1,
					page: this.page
				}
				this.$axios.get(
					url, {
						params: data
					}
				).then(res => {
					if (res.code) {
						res.data.data.forEach(item=>{
							item.inputtime = moment(item.inputtime*1000).format("YYYY-MM-DD")
						})
						this.list = res.data
					}
				})
			},
			curChge(e) {
				this.page = e
				this.regimeList()
			}
		}
	}
</script>

<style lang="less" scoped>
	.re-box {
		.deal {
			width: 100%;
			border-bottom: 0.5px solid rgba(235, 236, 239, 1);
			height: 88px;
			color: rgba(102, 102, 102, 1);
			font-size: 16px;
			font-weight: 400;
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;
			&:hover {
				background-color: #f5f7fa;
			}
			.text {
				// overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				line-height: 26px;
				flex: 0 0 223px;

				img {
					width: 26px;
				}

			}

			.data {}
		}

		.number {
			margin: 60px 0 30px;
			text-align: right;
		}

		.number-n {
			display: none;
			text-align: right;
			padding: 10px 0;
		}
	}

	@media (max-width:768px) {
		.re-box {
			padding: 0 10px;

			.deal {
				font-size: 14px;
				.text{
					overflow: hidden;
				}
			}

			.number {
				display: none;
			}

			.number-n {
				display: block;
			}
		}
	}
</style>
