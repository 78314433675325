<template>
	<div class="notice">
		<div>
			<tap :taps="notis" :tapIndex="tapIndex" @clkTap="clkTap" @more="More" :tapsTrue="true">
			</tap>
			<template v-if="notices.length">
				<div class="not-cotain">
					<div class="cotain-item" v-for="(item,index) in notices" :key="item.id" @click="clkNotic(index,item.id)" v-if="index<6">
						<p class="cot">
							<span class="tle">{{item.title}}</span>
							<span class="time">{{item.date}}</span>
						</p>
						<p class="detail">{{item.description}}</p>
					</div>
				</div>
			</template>
			<template v-else>
				<el-empty description="暂无数据"></el-empty>
			</template>
		</div>
		<div class="mores" @click="More">了解更多<i class="iconfont">&#xe642;</i></div>
		<div class="not-tain">
			<div class="tain-item" @click="clkZj(1)">
				<div class="tain-ctx">
					<p class="tle">专精特新板</p>
					<p class="tmore">了解更多<i class="iconfont">&#xe642;</i></p>
				</div>
				<img src="https://img.js.design/assets/img/62b907a994213d75a54cca7f.png" alt="">
			</div>
			<div class="tain-item" @click="clkZj(2)">
				<div class="tain-ctx">
					<p class="tle">科技创新板</p>
					<p class="tmore">了解更多<i class="iconfont">&#xe642;</i></p>
				</div>
				
				<img src="https://img.js.design/assets/img/62b907a976b197959bc8721e.png" alt="">
			</div>
			<div class="tain-item" @click="clkZj(3)">
				<div class="tain-ctx">
					<p class="tle">交易板</p>
					<p class="tmore">了解更多<i class="iconfont">&#xe642;</i></p>
				</div>
				
				<img src="https://img.js.design/assets/img/62b907a9d69ea3565ae1ed4e.png" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				notis:['托管挂牌公告','产权交易公告','可转债公告'],
				tapIndex:0,
				notices:[]
			}
		},
		created() {
			this.getTopNav()
		},
		mounted() {
		},
		methods:{
			getHtmlFz() {
				// 获取html
				var html = document.documentElement;
				// 获取html的宽度
				var windowWidth = html.clientWidth;
				// 设置html的字体大小
				// 屏幕宽度/设计稿宽度
				html.style.fontSize = windowWidth /1920 * 100+ 'px';
			},
			clkTap(index,item) {
				this.tapIndex = index;
				this.getNoticeList(item)
				if(index==1) {
					// this.noticeList()
					// window.open("https://www.gsfae.cn/#/tzgg","_blank");
					// window.location.href = "https://www.gsfae.cn/#/tzgg"
				}else {
					// this.getNoticeList(item)
				}
			},
			
			// 获取公告列表
			noticeList() {
				let url = this.$api.trading.noticeList
				let data = {
					catid: 60,
					page: 1,
					type:1
				}
				this.$axios.get(
					url, {
						params: data
					}
				).then(res => {
					if (res.code) {
						this.notices = res.data.data
					}
				})
			},
			
			clkZj(type) {
				this.$router.push({name:"scIndex",query:{type}})
			},
			to(){
				this.$router.push({
					name:"technological"
				})
			},
			// 交易公告详情
			clkNotic(index,id) {
				
				this.$router.push({path:"/infotion/details",query:{index:this.tapIndex,id}})
				// if(this.tapIndex==1) {
				// 	this.$router.push({path:"/trading/annodetail",query:{id}})
				// }else {
				// 	this.$router.push({path:"/infotion/details",query:{index:this.tapIndex,id}})
				// }
				
			},
			// 点击更多
			More() {
				this.$router.push({name:"infolist",query:{index:this.tapIndex}})
			},
			// 查找左边导航
			async getTopNav() {
				let url = this.$api.index.notLeftNav
				let res = await this.$axios.get(url,{params:{nav_id:181}})
				if(res.code) {
					let navs = []
					for(let i = 0;i<res.list.length;i++) {
						if(i==3) {
							break;
						}
						if(i==0) {
							this.getNoticeList(res.list[i].id)
						}
						let obj = {
							id:res.list[i].id,
							catname:res.list[i].catname
						}
						navs.push(obj)
					}
					this.notis = navs
				}
			},
			// 查询公告列表
			async getNoticeList(id) {
				let url = this.$api.index.noticeList
				let data = {
					catid:id,
					page:1
				}
				let res = await this.$axios.get(url,{params:data})
				if(res.code) {
					this.notices = res.data.data
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.notice {
		max-width: 1440px;
		margin: 0 auto;
		position: relative;
		padding: 75px 40px 63px 40px;
		box-sizing: border-box;
		background-color: #f7fbfe;
		display: grid;
		grid-template-columns: repeat(2,1fr);
		column-gap: 36px;
		.mores {
			display: none;
			.iconfont {
				font-size: 14px;
			}
		} 
		.not-tap {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			position: relative;
			&::after {
				display: block;
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 2px;
				background-color: #e9edef;
			}
			.not {
				display: flex;
				.not-item {
					font-size: 19px;
					font-family: SourceHanSansCN;
					font-weight: 300;
					color: #5D6779;
					margin-right: 56px;
					padding: 13px 0;
					border-bottom: 2px solid transparent;
					cursor: pointer;
					position: relative;
					z-index: 999;
					&.active {
						border-color: #0a4890;
						color: #0a4890;
						font-weight: bold;
					}
					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
		.not-cotain {
			.cotain-item {
				margin-top: 18px;
				background-color: #fff;
				box-sizing: border-box;
				padding: 20px;
				cursor: pointer;
				transition: box-shadow .3s,transform .3s;
				&:hover {
					box-shadow: 0 0 10px rgba(0,0,0,.2);
					transform: translateY(-5px);
				}
				.cot {
					display: flex;
					justify-content: space-between;
					margin-bottom: 13px;
					align-items: flex-end;
					.tle {
						font-size: 16px;
						font-weight: 400;
						color: #333333;
						// display: -webkit-box;
						overflow: hidden;
						// -webkit-box-orient: vertical;
						// -webkit-line-clamp: 1;
						text-overflow: ellipsis;
						white-space: nowrap;
						width: 80%;
					}
					.time {
						font-size: 14px;
						font-family: SourceHanSansCN;
						font-weight: 300;
						color: #555555;
						flex-shrink: 0;
					}
				}
				.detail {
					font-size: 14px;
					font-family: SourceHanSansCN;
					font-weight: 300;
					color: #666666;
					line-height: 25px;
				}
			}
		}
		.not-tain {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.tain-item {
				width: 100%;
				border-radius: 5px;
				box-sizing: border-box;
				background-size: cover;
				cursor: pointer;
				position: relative;
				margin-bottom: 20px;
				// overflow: hidden;
				transition: box-shadow .3s;
				&:hover {
					box-shadow: 0 0 10px rgba(0,0,0,.5);
				}
				&:last-child {
					margin-bottom: 0;
				}
				img {
					width: 100%;
					transition: transform .5s;
				}
				.tain-ctx {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					padding-left: 30px;
					z-index: 99999999;
					.tle {
						font-size: 30px;
						font-family: SourceHanSansCN;
						color: #FFFFFF;
						margin-bottom: 30px;
					}
					.tmore {
						font-size: 16px;
						font-family: SourceHanSansCN;
						font-weight: 400;
						color: #FFFFFF;
						i {
							font-size: 14px;
						}
					}
				}
			}
		}
	}
	@media (max-width:1250px) {
		.notice {
			display: block;
			/deep/ .not-tap {
				.tmore {
					display: none;
				}
				.not {
					width: 100%;
					justify-content: space-between;
				}
			}
			.not-tain {
				.tain-item {
					margin-bottom: 18px;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			.mores {
				display: block;
				text-align: center;
				font-size: 16px;
				border: 1px solid #666;
				color: #666;
				border-radius: 5px;
				padding: 20px 0;
				margin: 18px 0;
				transition: all .4s;
				box-sizing: border-box;
				cursor: pointer;
				&:hover {
					background-color: var(--themeColor);
					color: white;
					border: 1px solid var(--themeColor);
					
				}
			}
		}
	}
	@media (max-width:1000px) {
		.notice {
			padding: 40px 10px;
		}
	}
	@media (max-width:768px) {
		/deep/ .not-tap {
			margin-bottom: 20px;
		}
		.notice {
			padding: 20px 10px;
			.mores {
				padding: 15px 0;
				font-size: 14px;
				margin: 10px 0;
			}
			.not-tain {
				.tain-item {
					margin-bottom: 10px;
					.tain-ctx {
						.tle {
							font-size: 24px;
						}
						.tmore {
							font-size: 14px;
						}
					}
				}
			}
			.not-cotain {
				.cotain-item {
					padding: 10px;
					margin-top: 10px;
					.cot {
						.tle {
							font-size: 14px;
						}
					}
					&:first-child {
						margin-top: 0;
					}
				}
			}
		}
	}
	@media (max-width:500px) {
		.notice {
			.not-tain {
				.tain-item {
					.tain-ctx {
						.tle {
							font-size: 20px;
							margin-bottom: 10px;
						}
					}
				}
			}
			.not-tap {
				.not {
					flex-wrap: wrap;
				}
			}
		}
	}
</style>