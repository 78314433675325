<!-- 重点项目推荐 -->
<template>
	<div class="re-box">
		<div class="deal" v-for="(item,index) in list.data" :key="index" @click="clkRef(item.id)">
			<span class="text">
				<img src="../../assets/images/trading/logo.png" alt="">
				{{item.title}}</span>
			<span class="data">{{item.inputtime}}</span>
		</div>
		<div class="block" v-if="list.data.length">
			<el-pagination background :page-size="list.per_page" layout="total, prev, pager, next, jumper"
				:total="list.total" @current-change="curChge">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import moment from "moment"
	export default {
		data() {
			return {
				page: 1,
				list: {}
			};
		},
		created() {
			this.projectList()
			// this.sort()
		},
		methods: {
			//日期排序
			// sort() {
			// 	// this.list.sort(this.compare());
			// 	this.list.sort(function(a, b) {
			// 		let obj1 = a['inputtime']
			// 		let obj2 = b['inputtime']
			// 		const val1 = Math.floor(new Date(obj1).getTime() / 1000)
			// 		const val2 = Math.floor(new Date(obj2).getTime() / 1000)
			// 		return val1 - val2
			// 		// return val2 - val1
			// 	});
			// 	console.log(this.timeData)
			// },
			// 获取重点项目列表
			projectList() {
				let url = this.$api.promote.projectList
				let data = {
					catid: 98,
					page: this.page
				}
				this.$axios.get(
					url, {
						params: data
					}
				).then(res => {
					if (res.code) {
						res.data.data.forEach(item => {
							item.inputtime = moment(item.inputtime * 1000).format("YYYY-MM-DD")
						})
						this.list = res.data
					}
				})
			},
			curChge(e) {
				this.page = e
				this.projectList()
			},
			clkRef(id) {
				this.$router.push({
					name: "promote_details",
					query: {
						id
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.re-box {
		.deal {
			width: 100%;
			border-bottom: 0.5px solid rgba(235, 236, 239, 1);
			height: 88px;
			color: rgba(102, 102, 102, 1);
			font-size: 16px;
			font-weight: 400;
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;

			&:hover {
				background-color: #f5f7fa;
			}

			.text {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				line-height: 26px;
				padding-right: 30px;
				img {
					width: 26px;
				}

			}

			.data {
				flex-shrink: 0;
			}
		}

		.number {
			margin: 60px 0 30px;
			text-align: right;
		}

		.number-n {
			display: none;
			text-align: right;
			padding: 10px 0;
		}
	}

	@media (max-width:768px) {
		.re-box {
			padding: 0 10px;

			.deal {
				font-size: 14px;
				height: 60px;
			}

			.number {
				display: none;
			}

			.number-n {
				display: block;
			}
		}
	}
</style>
