<template>
	<!-- 会员展示 -->
	<div>
		<div class="sh-show" style="font-size: 16px;">
			<ul class="sh-nav">
				<li @click="clkTap(0)" :class="type==0?'active':''">
					<p>全部会员</p>
					<p>{{count.count}}名</p>
				</li>
				<li @click="clkTap(1)" :class="type==1?'active':''">
					<p>机构投资商会员</p>
					<p>{{count.type1}}名</p>
				</li>
				<li @click="clkTap(2)" :class="type==2?'active':''">
					<p>专业服务商会员</p>
					<p>{{count.type2}}名</p>
				</li>
				<li @click="clkTap(3)" :class="type==3?'active':''">
					<p>一般会员</p>
					<p>{{count.type3}}名</p>
				</li>
			</ul>
			<div class="sh-input">
				<div class="sh-ipt">
					<input type="text" v-model="keywords" placeholder="请输入会员名称或编号查询" />
					<i class="iconfont">&#xe600;</i>
				</div>
				<div class="sh-btn" @click.stop="clkSearch">搜索</div>
			</div>
		</div>
		<!-- 数据展示 -->
		<el-table :data="menber.data" :header-cell-style="{background:'#f7fbfe',height:'70px'}"
			:row-style="{height:'70px',cursor:'pointer'}" @row-click="clkTableRow">
			<el-table-column prop="title" label="会员名称">
			</el-table-column>
			<el-table-column prop="number" label="会员编号">
			</el-table-column>
			<el-table-column prop="time" label="入会时间">
			</el-table-column>
			<el-table-column prop="capital" label="注册资本">
			</el-table-column>
			<el-table-column prop="business" label="主营业务">
			</el-table-column>
		</el-table>
		<div class="block">
			<el-pagination background :page-size="menber.per_page" layout="total, prev, pager, next, jumper"
				:total="menber.total" @current-change="curChge">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import phonePg from "@/components/phonePg/phonePg"
	export default {
		data() {
			return {
				type: 0,
				page: 1,
				menber: [],
				keywords: "",
				count: {}
			}
		},
		components: {
			phonePg
		},
		created() {
			this.getCount()
			this.getMenber()
		},
		watch: {
			type() {
				this.getMenber()
			}
		},
		methods: {
			// 点击tap
			clkTap(index) {
				this.type = index
			},
			// 单击某一行
			clkTableRow(row) {
				let id = row.id
				this.$router.push({
					name: 'shdetail',
					query: {
						id
					}
				})
				
			},
			// 查询会员信息
			getMenber() {
				this.$axios.get("Member/getList", {
					params: {
						nav_id: 106,
						limit: 10,
						keywords: this.keywords,
						page: this.page,
						type: this.type
					}
				}).then(res => {
					if (res.code) {
						res.data.per_page = parseInt(res.data.per_page)
						this.menber = res.data
					}
				})
			},
			curChge(e) {
				this.page = e
				this.getMenber()
			},
			clkSearch() {
				this.getMenber()
			},
			// 获取统计
			getCount() {
				this.$axios.get("Member/getCount", {
					params: {
						nav_id: 5
					}
				}).then(res => {
					if (res.code) {
						this.count = res.data
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.sh-show {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid rgba(235, 236, 239, 1);
		margin-bottom: 20px;

		.sh-nav {
			display: flex;
			position: relative;

			li {
				text-align: center;
				margin-right: 50px;
				padding-bottom: 13px;
				border-bottom: 3px solid #fff;
				cursor: pointer;

				&:last-child {
					margin-right: 0;
				}

				&.active {
					border-bottom: 3px solid var(--themeColor);

					p {
						color: rgba(10, 72, 144, 1);

						&:nth-child(1) {
							font-weight: 500;
						}
					}
				}

				p {
					color: rgba(93, 103, 121, 1);

					&:nth-child(1) {
						font-size: 16px;
						font-weight: 400;
						margin-bottom: 5px;
					}

					&:nth-child(2) {
						font-size: 22px;
						font-weight: 700;
						margin-top: 10px;
					}
				}
			}
		}

		.sh-input {
			display: flex;
			align-items: center;

			.sh-ipt {
				position: relative;

				input {
					width: 304px;
					height: 40px;
					font-size: 16px;
					font-weight: 400;
					outline: none;
					border: 1px solid rgba(194, 198, 205, 1);
					box-sizing: border-box;
					padding-left: 40px;
					border-right: none;
				}

				.iconfont {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: 14px;
				}
			}

			.sh-btn {
				height: 40px;
				color: #fff;
				background-color: var(--themeColor);
				font-size: 16px;
				line-height: 40px;
				width: 80px;
				text-align: center;
				cursor: pointer;
			}
		}
	}

	@media (max-width:1300px) {
		.sh-show {
			flex-direction: column;

			.sh-nav {
				// justify-content: space-between;
				overflow: auto;

				li {
					flex-shrink: 0;
				}
			}

			.sh-input {
				margin-top: 20px;

				.sh-ipt {
					flex-grow: 1;

					input {
						width: 100%;
					}
				}
			}
		}
	}

	@media (max-width:768px) {
		.sh-show {
			.sh-nav {
				li {
					p:nth-child(2) {
						font-size: 18px;
						font-weight: inherit;
					}
				}
			}

			.sh-input {
				.sh-ipt {
					input {
						font-size: 14px;
					}
				}

				.sh-btn {
					font-size: 14px;
				}
			}
		}
	}
</style>
