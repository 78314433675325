<template>
	<div>
		<div v-if="policy.length">
			<div v-for="(item,index) in policy" :key="item.id" class="cup-item" @click="clkCup(item.id)">
				<div class="cup-icon">
					<p class="p-icon">
						<i class="iconfont" :style="{color:themeColor}">&#xe605;</i>
					</p>
					<p class="p-cxc">{{item.title}}</p>
				</div>
				<p class="time">{{item.inputtime}}</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		props:{
			// 政策新闻数据
			policy:{
				type:Array,
				default:()=>[]
			},
			themeColor:{
				type:String,
				default:""
			}
		},
		methods:{
			// 点击某一行
			clkCup(id) {
				let index = this.$route.query.index;
				this.$emit("clkCup",index,id)
			}
		}
	}
</script>

<style lang="less" scoped>
	.cup-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: rgba(102, 102, 102, 1);
		font-size: 16px;
		font-weight: 400;
		padding: 30px 0;
		border-bottom: 1px solid rgba(235, 236, 239, 1);
		cursor: pointer;
		&:hover {
			background-color: #f5f7fa;
		}
		
		.cup-icon {
			display: flex;
			align-items: center;
			.p-icon {
				width: 26px;
				height: 26px;
				background: rgba(10, 72, 144, 0.08);
				border-radius: 50%;
				text-align: center;
				line-height: 26px;
				margin-right: 8px;
				.iconfont {
					color: var(--themeColor);
				}
			}
			.p-cxc {
				padding-right: 30px;
				display: -webkit-box;
				overflow: hidden;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				// box-sizing: border-box;
			}
		}
		.time {
			flex-shrink: 0;
		}
	}
	
	@media (max-width:768px) {
		.cup-item {
			font-size: 14px;
			padding: 20px 0;
		}
	}
</style>