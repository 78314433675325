<!-- 重点项目推荐 -->
<template>
	<div>
		<homenav navTag="promote"></homenav>
		<div class="region">
			<!-- 面包屑 -->
			<crumbs :crumbs="crumbs" @clkItem="clkItemd"></crumbs>
			<div class="rg-cotain">
				<!-- 子组件渲染 -->
				<div class="chirn">
					<router-view />
				</div>
			</div>
			<!-- 底部 -->
			<bottom></bottom>
		</div>
	</div>
</template>

<script>
	import $ from "jquery"
	export default {
		data() {
			return {
				crumbs: ['首页', '重点项目推荐']
			}
		},
		methods: {
			clkItemd(index) {
				switch(index) {
					case 0:
						this.$router.push({name:"index"})
					break;
				}
			},
			getCotain() {
				let letain = $(".chirn")
				let width = letain.outerWidth()/1440*100+'px'
				letain.css("font-size",width)
			},
			clkItemd(index) {
				switch (index) {
					case 0:
						this.$router.push({name:"index"})
					break;
				}
			}
		},
		mounted() {
			$(document).ready(()=>{
				this.getCotain()
				$(window).resize(()=>{
					this.getCotain()
				})
			})
		}
	}
</script>

<style lang="less" scoped>
	.chirn {
		background-color: #fff;
		padding: 30px;
		box-sizing: border-box;
		width: 100%;
		align-self: normal;
		
	}
	.region .rg-cotain {
		margin-bottom: 60px;
	}
	@media (max-width: 900px){
		.region .rg-cotain{
			flex-direction: column;
		}
	}
	@media (max-width:768px) {
		.chirn {
			padding: 10px;
		}
	}
</style>
