<!-- 市场数据 -->
<template>
	<div class="market">
		<div id="map"></div>
		<!-- 展示 -->
		<div class="city">
			<h2 class="city-name">{{cityname}}</h2>
			<h4 class="city-data">挂牌展示企业数据</h4>
			<h2 class="city-num num">{{city.num11}}家</h2>
		</div>
		<div class="shic">
			<div class="tle">市场数据统计</div>
			<div class="shujv">
				<ul>
					<li>
						<p class="tles">挂牌展示企业</p>
						<p class="num">{{province.num1}}</p>
					</li>
					<li>
						<p class="tles">托管企业</p>
						<p class="num">{{province.num3}}</p>
					</li>
					<li>
						<p class="tles">会员数量</p>
						<p class="num">{{province.num5}}</p>
					</li>
					<li>
						<p class="tles">投资者数量</p>
						<p class="num">{{province.num7}}</p>
					</li>
					<li>
						<p class="tles">融资总额</p>
						<p class="num">{{province.num9}}亿元</p>
					</li>
				</ul>
				<ul>
					<li>
						<p class="tles">上月新增</p>
						<p class="num">{{province.num2}}</p>
					</li>
					<li>
						<p class="tles">上月新增</p>
						<p class="num">{{province.num4}}</p>
					</li>
					<li>
						<p class="tles">上月新增</p>
						<p class="num">{{province.num6}}</p>
					</li>
					<li>
						<p class="tles">上月新增</p>
						<p class="num">{{province.num8}}</p>
					</li>
					<li>
						<p class="tles">上月新增</p>
						<p class="num">{{province.num10}}亿元</p>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	// 引入echarts
	import * as echarts from "echarts";
	import $ from "jquery"
	import anime from "animejs"
	const map = require("./gansu.json")
	export default {
		data() {
			return {
				cityname: "酒泉市",
				city: {},
				province: {}
			}
		},
		mounted() {
			$(document).ready(() => {
				this.getCotain()
				this.gansuMap()
				$(window).resize(() => {
					this.getCotain()
				})
			})
		},
		created() {
			this.getCity()
			this.getProvince()
		},
		methods: {
			getCotain() {
				let letain = $(".market")
				let width = letain.outerWidth() / 1440 * 100 + 'px'
				letain.css("font-size", width)
			},

			// 加载数据
			loadNum(className) {
				var roundLogEl = document.getElementsByClassName(className);
				for (let i = 0; i < roundLogEl.length; i++) {
					let num = parseFloat(roundLogEl[i].innerText)
					anime({
						targets: roundLogEl[i],
						innerText: [0, num],
						easing: 'linear',
						round: 1,
						duration: 1000
					});
				}
			},

			// 根据城市获取数据
			getCity() {
				this.$axios.get(
					"Index/getStore", {
						params: {
							search: this.cityname
						},
					},
				).then(res => {
					if (res.data) {
						this.city = res.data
						console.log('this.city', this.city)
						// this.$nextTick(()=>{
						// 	this.loadNum("num")
						// })
					} else {
						this.$message({
							message: '该城市暂无数据',
							type: 'warning'
						});
					}
				})
			},
			// 获取省数据
			getProvince() {
				this.$axios.get(
					"Index/getStore", {
						params: {
							search: ''
						},
					},
				).then(res => {
					if (res.data) {
						this.province = res.data
						console.log('this.province', this.province)
						// this.$nextTick(()=>{
						// 	this.loadNum("num")
						// })
					} else {
						this.$message({
							message: '该城市暂无数据',
							type: 'warning'
						});
					}
				})
			},

			gansuMap() {
				let that = this
				const myChart = echarts.init(document.getElementById('map'))
				echarts.registerMap('gansu', map)

				var option = {
					series: [{
						type: 'map',
						map: 'gansu',
						zoom: 1.25,
						label: {
							show: true, // 显示
							fontSize: 14
						},
						itemStyle: {
							normal: { // 静态的时候显示的默认样式
								areaColor: '#d5e4fa', // 地图颜色
							},
							emphasis: { // 鼠标移入动态的时候显示的默认样式
								areaColor: '#0170f0' // 地图颜色
							}
						},
						// 选中的颜色
						select: {
							itemStyle: {
								areaColor: "#0170f0"
							}
						},
						data: [{
							name: "酒泉市",
							selected: true
						}]
					}]
				}
				myChart.setOption(option)
				myChart.on('click', function(e) {
					that.cityname = e.name
					that.getCity()
				});
			}
		}
	}
</script>

<style scoped lang="less">
	.market {
		max-width: 1440px;
		height: 766px;
		margin: 0 auto;
		position: relative;
		background-color: #fff;
		background-image: url("https://img.js.design/assets/img/62b6720cf5ab6432e9b3b7a8.png");
		background-size: cover;

		.pott {
			position: relative;
		}
	}

	.city {
		background-color: var(--themeColor);
		color: #fff;
		width: 170px;
		height: 179px;
		background: #0A4890CC;
		color: white;
		box-sizing: border-box;
		padding: 0.3em 0.2em;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: absolute;
		left: 0.71em;
		bottom: 2.03em;

		.city-name {
			font-size: 18px;
			font-weight: 500;
			position: relative;
			align-self: flex-start;
			padding-bottom: 5px;

			&::after {
				content: '';
				display: block;
				position: absolute;
				bottom: -8px;
				height: 3px;
				width: 65%;
				background-color: #fff;
			}
		}

		.city-data {
			font-size: 14px;
			font-weight: 500;
		}

		.city-num {
			font-size: 18px;
			font-weight: 500;
		}
	}

	#map {
		width: 7.46em;
		height: 5.88em;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0.46em;
	}

	.backimg {
		width: 100%;
	}

	.shic {
		background-color: white;
		width: 4.87em;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0.56em;
	}

	.shic .tle {
		font-size: 18px;
		font-family: SourceHanSansCN;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 26px;
		background-color: #0170f0;
		text-align: center;
		padding: 21px 0;
	}

	.shic .shujv {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		box-sizing: border-box;
		padding: 1em;
	}

	.shic .shujv li {
		margin-bottom: 30px;
	}

	.shic .shujv .tles {
		font-size: 18px;
		color: #0A4890FF;
		margin-bottom: 15px;
		font-weight: bold;
	}

	.shic .shujv .num {
		font-size: 14px;
	}

	@media (max-width:1480px) {
		.market {
			padding: 0 10px;
		}
	}

	@media (max-width:1000px) {
		.market {
			display: flex;
			flex-direction: column;
			margin-bottom: 476px;
			height: 510px;
			#map {
				width: 90%;
				height: 733px;
			}

			.shic {
				top: 131%;
				right: 0px;
				width: 100%;
			}
		}
	}
	
	@media (max-width:768px) {
		
		.market {
			display: none;
			#map {
				height: 950px;
			}
		}
	}
</style>
