<!-- 投资者课程 -->
<template>
	<div class="course">
		<course :courses="courseList" @clkImgDeal="clkImgDeal"></course>
		<div class="block" v-show="courseList.length">
		    <el-pagination
				background
				:page-size="perPage"
				layout="total, prev, pager, next, jumper"
				:total="total"
				@current-change="curChge">
		    </el-pagination>
		</div>
		
		<el-empty v-show="!courseList.length" description="暂无数据"></el-empty>
		
		<div v-if="courseList.length>perPage">
			<phonePg :page="page" :total="total" :prePage="perPage" @pageNext="pageNext" @pagePref="pagePref"></phonePg>
		</div>
	</div>
</template>

<script>
	import course from "@/components/course/course"
	import phonePg from "@/components/phonePg/phonePg"
	import qs from "qs"
	export default {
		data() {
			return {
				page:1, // 当前页
				total:0, // 总数
				perPage:0, // 
				courseList:[],
				
			}
		},
		components:{
			course,
			phonePg
		},
		created() {
			this.getCourse()
		},
		methods:{
			// 获取问题列表
			getCourse() {
				let url = this.$api.ecation.InvestmentList
				let data = {
					nav_id:5,
					clas_id:40,
					page:this.page
				}
				this.$axios.post(
					url,
					qs.stringify(data)
				).then(res=>{
					if(res.code) {
						if(res.data.data.length) {
							this.courseList = res.data.data
							this.total = res.data.total
							this.perPage = res.data.per_page
						}
					}
				})
			},
			
			// 点击课程
			clkImgDeal(id) {
				let index = this.$route.query.index
				let queryPath = "Policyzone/InvestmentDetails"
				this.$router.push({path:"/ecation/etrain",query:{index,id,queryPath}})
			},
			
			// 切换页执行
			curChge(e) {
				this.page = e
				this.getCourse()
			},
			
			// 上一页
			pageNext() {
				if(this.page-1 == 0) {
					return false
				}
				this.page--
				this.getCourse()
			},
			
			// 下一页
			pagePref() {
				if(this.page*this.perPage>this.total) {
					return false
				}
				this.page++
				this.getCourse()
			}
		}
	}
</script>

<style lang="less" scoped>
	
</style>