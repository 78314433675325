<template>
	<div>
		<navs navTag="policy"></navs>
		<div class="region">
			<!-- 面包屑 -->
			<crumbs :crumbs="crumbs" @clkItem="clkItemd"></crumbs>
			<div class="rg-cotain">
				<!-- 侧边导航栏 -->
				<navleft :navs="navs" :tapItem="tapItem" @clkItem="clkItem" :navRqt="true"></navleft>
				<!-- 子组件渲染 -->
				<div class="chirn">
					<router-view :policy="policy" :page="page" @changePg="changePg"/>
				</div>
			</div>
		</div>
		<!-- 底部 -->
		<bottom></bottom>
	</div>
</template>

<script>
	import qs from "qs"
	import moment from "moment"
	export default {
		data() {
			return {
				crumbs:['首页','政策专区'],
				tapItem:0,
				navs:[],
				page:1,
				policy:{},
				left_id:0
			}
		},
		
		created() {
			this.LeftSidebar()
		},
		
		methods:{
			// page 改变是触发
			changePg(e) {
				this.page = e
				this.getlist(this.left_id)
			},
			clkItem(index,text) {
				this.tapItem = index
				this.crumbs = ['首页','政策专区','优惠政策']
				// 修改数组
				this.$set(this.crumbs,2,text.catname)
				this.$router.push({path:"/policy/cupon",query:{index}})
				this.left_id = text.id
				this.getlist(text.id)
			},
			
			// 查找左边栏
			async LeftSidebar() {
				let url = this.$api.policy.LeftSidebar
				let data = {
					nav_id:26
				}
				let res = await this.$axios.post(url,qs.stringify(data))
				if(res.code) {
					this.navs = res.data
					this.$set(this.crumbs,2,res.data[0].catname)
					let left_id = res.data[0].id
					this.left_id = res.data[0].id
					this.getlist(left_id)
				}
			},
			// 获取右边数据列表
			async getlist(left_id) {
				let url = this.$api.policy.getlist
				let data = {
					left_id,
					page:this.page
				}
				let res = await this.$axios.post(url,qs.stringify(data))
				if(res.code) {
					res.data.data.forEach((item,index)=>{
						item.inputtime = moment(parseInt(item.inputtime*1000)).format("YYYY-MM-DD")
					})
					this.policy = res.data
				}
			},
			clkItemd(index) {
				switch (index) {
					case 0:
						this.$router.push({name:"index"})
					break;
				}
			}
		},
		mounted() {
			this.$bus.$on("clkCup",(data)=>{
				this.crumbs.push(data.text)
				this.navs = data.navs
			})
		}
	}
</script>

<style lang="less" scoped>
	.region {
		margin-bottom: 60px;
	}
	@media (max-width:900px) {
		.region {
			.rg-cotain {
				flex-direction: column;
				.chirn {
					margin-top: 10px;
				}
			}
		}
	}
</style>