<!-- 底部介绍 -->
<template>
	<div>
		<div :style="{'background-color': bgColor}">
			<div class="bottom" :style="{'background-color': bgColor}">
				<div class="adres">
					<div class="phone">
						<p class="bom">
							<span>挂牌咨询热线：{{date.phone1}}</span>
							<span>托管咨询热线：{{date.phone2}}</span>
							<span>产权交易热线：{{date.phone3}}</span>
						</p>
						<p class="bom">
							<span>会员机构热线：{{date.phone4}}</span>
							<span>融资服务热线：{{date.phone5}}</span>
							<span>培训服务热线：{{date.phone6}}</span>
						</p>
					</div>
					<!-- 手机版显示 -->
					<div class="phone-adres">
						<img :src="date.qrcode" alt="">
						<div>
							<p>地址：{{date.address}}</p>
							<div class="pho">
								<p>电话：{{date.mobile}}</p>
								<p>传真：{{date.tax}}</p>
								<p style="cursor: pointer;" v-for="(item,index) in list" :key="index"
									@click="statement(index)"> | {{item.title}} </p>
							</div>
						</div>
					</div>
					<!-- <p class="renzhen">免责声明 <span>|</span> 法律声明  <span>|</span> 隐私保护 <span>|</span> 联系我们</p> -->
					<div class="compony">
						<!-- <p>甘肃股权交易中心 版权所有： Copyright © 2013</p>
					<p>备案信息：陇ICP备07001814号-2</p> -->
						<p>{{date.copyright}}</p>
					</div>
					<!-- 手机版显示 -->

					<!-- 电脑版显示 -->
					<div class="address">
						<p class="bom">地址：{{date.address}}</p>
						<p>电话：{{date.mobile}} | 传真：{{date.tax}}<span v-for="(item,index) in list" :key="index"
								@click="statement(index)" style="cursor: pointer;" class="phspan"> | {{item.title}}
							</span></p>
					</div>
					<div class="banq bantom" @click="clkBom">{{date.copyright}}</div>
				</div>



				<div class="qr-img">
					<img :src="date.qrcode" alt="">
					<p>微信公众平台</p>
				</div>
				<!-- 电脑版显示 -->
			</div>
			<div id="KC_id" style="text-align: center;">
			</div>
		</div>
		<div class="contxt" v-if="ctxshow" @click="ctxshow=false">
			<div class="close" @click="ctxshow=false">
				<i class="el-icon-close"></i>
			</div>
			<div class="contxt2" v-html="content">

			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				date: {
					content: ''
				},
				list: [],
				content: '',
				title: '',
				ctxshow: false
			}
		},
		props: {
			bgColor: {
				type: String,
				default: "var(--themeColor)"
			}
		},
		created() {
			this.getBase()
			this.getList()
		},
		mounted() {
			console.log('123')
			const oScript = document.createElement('script');
			const KC_id = document.getElementById('KC_id');
			oScript.type = 'text/javascript';
			oScript.src = 'https://kxlogo.knet.cn/seallogo.dll?sn=e1407226201005149113dq000000&h=47';
			oScript.setAttribute('id', 'kXScript');
			KC_id.appendChild(oScript);

		},
		methods: {
			//声明详情
			statement(index) {
				this.content = this.list[index].content
				this.ctxshow = true
			},
			clkBom() {
				console.log('ppp');
				window.open("https://beian.miit.gov.cn/", "_blank")
			},
			//获取声明
			getList() {
				this.$axios.get("Index/getList", {
					params: {
						catid: 192
					}
				}).then(res => {
					if (res.code) {
						this.list = res.data.data
						console.log('this.list', this.list)
					}
				})
			},
			getBase() {
				this.$axios.get("Base/getSetting").then(res => {
					if (res.code) {
						this.date = res.data
						console.log('this.date', this.date)
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.phspan {
		cursor: pointer;

		&:hover {
			text-decoration: underline;
			color: #fff;
		}

	}

	.contxt {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(0, 0, 0, .5);
		z-index: 999999999999999;

		.close {
			position: absolute;
			right: 0;
			top: 0;
			color: #fff;
			font-size: 50px;
			cursor: pointer;
		}

		.contxt2 {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 50vw;
			height: 90vh;
			background-color: #fff;
			overflow-y: auto;
			padding: 20px;

			.el-icon-delete {
				position: absolute;
				top: 0;
				right: 0;
			}
		}
	}

	.bottom {
		max-width: 1440px;
		margin: 0 auto;
		color: #fff;
		background-color: #0064AF;
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;
		padding: 103px 40px;
		position: relative;

		.adres {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.bom {
				margin-bottom: 10px;
			}

			.phone {
				font-size: 16px;
			}
		}

		.address,
		.banq {
			font-size: 14px;
			font-family: SourceHanSansCN;
			font-weight: 400;
			color: #B7CEDF;

			span {}
		}

		.bantom:hover {
			cursor: pointer;
			text-decoration: underline;
			color: #fff;
		}

		.qr-img {
			text-align: center;

			p {
				font-size: 16px;
				font-family: SourceHanSansCN;
				font-weight: 400;
				color: #B7CEDF;
				margin-top: 16px;
			}
		}

		.phone-adres {
			display: none;
		}

		.renzhen {
			display: none;
		}

		.compony {
			display: none;
		}
	}

	@media (max-width:768px) {
		.bottom {
			padding: 30px 20px;

			.address,
			.banq,
			.qr-img {
				display: none;
			}

			.phone-adres,
			.renzhen {
				display: block;
				font-size: 14px;
				opacity: .8;
			}

			.phone-adres {
				display: flex;
				align-items: center;
				margin-top: 20px;
				margin-bottom: 20px;

				img {
					margin-right: 10px;
				}

				div {
					p {
						line-height: 20px;
					}

					.pho {
						margin-top: 15px;
					}
				}
			}

			.renzhen {
				span {
					margin: 0 10px;
				}
			}

			.compony {
				display: block;
				margin-top: 30px;
				font-size: 12px;
				opacity: .8;

				p {
					line-height: 20px;
				}

			}

			.adres {
				.bom {
					span {
						display: block;
						margin-bottom: 10px;
					}

					&:last-child {
						margin-top: 20px;
					}
				}

				.phone {
					font-size: 14px;
				}
			}
		}

	}
</style>
