<!-- 交易制度 -->
<template>
	<div>
		<homenav navTag="system"></homenav>
		<div class="region">
			<!-- 面包屑 -->
			<crumbs :crumbs="crumbs" @clkItem="clkItemd"></crumbs>
			<div class="rg-cotain">
				<!-- 子组件渲染 -->
				<div class="chirn">
					<router-view :system="system"/>
				</div>
			</div>
			<!-- 底部 -->
			<bottom></bottom>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tapItem: 0,
				crumbs: ['首页', '交易制度'],
				system:{}
			}
		},
		methods: {
			clkItemd(index) {
				switch(index) {
					case 0:
						this.$router.push({name:"index"})
					break;
				}
			},
			clkItem(index, text) {
				this.tapItem = index
				this.crumbs = ['首页', '交易制度']
				// 修改数组
				this.$set(this.crumbs, 2, text)
			},
			getCotain() {
				let letain = $(".chirn")
				let width = letain.outerWidth()/1440*100+'px'
				letain.css("font-size",width)
			}
		},
		mounted() {
			$(document).ready(()=>{
				this.getCotain()
				$(window).resize(()=>{
					this.getCotain()
				})
			})
		}
	}
</script>

<style lang="less" scoped>
	.chirn {
		background-color: #fff;
		padding: 30px;
		box-sizing: border-box;
		width: 100%;
		align-self: normal;
		
	}
	.region .rg-cotain {
		margin-bottom: 60px;
	}
	@media (max-width: 900px){
		.region .rg-cotain{
			flex-direction: column;
		}
	}
	@media (max-width:768px) {
		.chirn {
			padding: 10px;
		}
	}
</style>
