<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="25%"
      :before-close="handleClose"
      custom-class="custom-class"
    >
      <div class="title">
        <span>信息登记表</span>
      </div>
      <div class="inputBox">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          label-position="top"
          class="demo-ruleForm"
        >
          <el-row :gutter="20">
            <el-col :span="12"
              ><el-form-item label="姓名" prop="title">
                <el-input v-model="ruleForm.title"></el-input> </el-form-item
            ></el-col>
            <el-col :span="12"
              ><el-form-item label="职位" prop="job">
                <el-input v-model="ruleForm.job"></el-input> </el-form-item
            ></el-col>
          </el-row>
          <el-form-item label="公司" prop="company">
            <el-input v-model="ruleForm.company"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input v-model="ruleForm.mobile"></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="address">
            <el-input v-model="ruleForm.address"></el-input>
          </el-form-item>

          <el-form-item
            label-width="200px"
            label-position="left"
            label="挂牌企业"
            prop="listedenterprise"
            class="leftBox"
          >
            <el-radio-group
              text-color="#fff"
              v-model="ruleForm.listedenterprise"
            >
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label-width="200px"
            label-position="left"
            label="专精特新企业"
            prop="specialized"
            class="leftBox"
          >
            <el-radio-group fill="#0a4890" v-model="ruleForm.specialized">
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label-width="200px"
            label-position="left"
            label="上市后备库企业"
            prop="backupenterprises"
            class="leftBox"
          >
            <el-radio-group fill="#0a4890" v-model="ruleForm.backupenterprises">
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item>
            <div class="fromBom">
              <el-button
                class="submit"
                type="primary"
                @click="submitForm('ruleForm')"
                >提交</el-button
              >
              <span @click="emit">我已填写过</span>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import qs from "qs";
export default {
    
  props: {
    dialogVisible: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      ruleForm: {
        title: "",
        job: "",
        company: "",
        mobile: "",
        address: "",
        listedenterprise: "是",
        specialized: "是",
        backupenterprises: "是",
      },
      rules: {
        title: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        job: [{ required: true, message: "请输入职位", trigger: "change" }],
        company: [{ required: true, message: "请输入公司", trigger: "change" }],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "change" },
        ],
        address: [{ required: true, message: "请输入地址", trigger: "change" }],
        listedenterprise: [
          //   { required: true, message: "是否为挂牌企业", trigger: "change" },
        ],
        specialized: [
          //   { required: true, message: "是否为专精特新企业", trigger: "change" },
        ],
        backupenterprises: [
          //   { required: true, message: "是否为上市后备库企业", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.tjdj();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 提交登记
    async tjdj() {
      let url = this.$api.train.InfoSubmit;
      let res = await this.$axios.post(url, qs.stringify(this.ruleForm));
      if (res.code) {
        this.emit();
      }
    },
    emit() {
      this.$emit("submit");
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    handleClose(done) {
      this.$emit("update:dialogVisible", false);
      return
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>
<style lang="less" scoped>
.title {
  text-align: center;
  font-size: 1.7rem;
  font-weight: 700;
  color: var(--themeColor);
}
.inputBox {
  margin-top: 30px;
}
.fromBom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  .submit {
    width: 100%;
    background: var(--themeColor);
    border-color: var(--themeColor);
  }
}
/deep/ .el-radio__input.is-checked .el-radio__inner {
  background: var(--themeColor) !important;
  border-color: var(--themeColor) !important;
}
/deep/ .el-radio__input.is-checked + .el-radio__label {
  color: var(--themeColor) !important;
}
/deep/ .el-radio__input .el-radio__inner {
}
/deep/ .el-radio.is-bordered + .el-radio.is-bordered {
  border: 1px solid #dcdfe6;
}
/deep/ .custom-class {
  border-radius: 12px !important;
   width: 30rem !important;
}
/deep/ .el-form-item__label {
  line-height: 10px ;
}

/deep/ .leftBox {
  display: flex;
  align-items: center;
  margin-top: -10px;
  .el-form-item__label {
    width: 50%;
  }
}
.border {
  border: 1px solid red;
}
@media (max-width: 768px) {

  .custom-class {
    width: 90% !important;
  }
}
</style>