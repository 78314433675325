<template>
	<div>
		<price></price>
	</div>
</template>

<script>
	import price from "@/components/index/price"
	export default {
		components:{
			price
		}
	}
</script>

<style>
	
</style>