<!-- 导航栏 -->
<template>
	<div class="father">
		<div class="gstle">甘肃股权交易中心官网</div>
		<div class="nav">
			<img src="../../assets/images/trading/zjteb-LOGO.png"  alt="" class="logo" v-show="type==1" v-if="!navFlag" @click="clkIndex"></img>
			<img src="../../assets/images/trading/kjcxb-LOGO.png"  alt="" class="logo" v-show="type==2" v-if="!navFlag" @click="clkIndex"></img>
			<img src="../../assets/images/trading/jib-LOGO.png"  alt="" class="logo" v-show="type==3" v-if="!navFlag" @click="clkIndex"></img>
			<div v-else class="regain">
				<span>登录</span>
				<span>|</span>
				<span>注册</span>
			</div>
			<ul class="nav-item" :class="navFlag==true?'nav-block':''">
				<li :class="navTag=='scIndex'?'active':''" :style="{color:(navTag=='scIndex'?themeColor:'')}"  @click="clkTag('scIndex')">首页<i class="iconfont h">&#xe642;</i></li>
				<li :class="navTag=='area'?'active':''" :style="{color:(navTag=='area'?themeColor:'')}" @click="clkTag('area')">企业专区<i class="iconfont h">&#xe642;</i></li>
				<li :class="navTag=='scGpDail'?'active':''" :style="{color:(navTag=='scGpDail'?themeColor:'')}" @click="clkTag('scGpDail')">专享产品服务<i class="iconfont h">&#xe642;</i></li>
				<li :class="navTag=='scNews'?'active':''" :style="{color:(navTag=='scNews'?themeColor:'')}" @click="clkTag('scNews')">新闻中心<i class="iconfont h">&#xe642;</i></li>
				<!-- <li :class="navTag=='scZcfg'?'active':''" :style="{color:(navTag=='scZcfg'?themeColor:'')}" @click="clkTag('scZcfg')">政策法规<i class="iconfont h">&#xe642;</i></li> -->
				<li :class="navTag=='scDown'?'active':''" :style="{color:(navTag=='scDown'?themeColor:'')}" @click="clkTag('scDown')">下载专区<i class="iconfont h">&#xe642;</i></li>
				<li :class="navTag=='scAbout'?'active':''" :style="{color:(navTag=='scAbout'?themeColor:'')}" @click="clkTag('scAbout')">关于我们<i class="iconfont h">&#xe642;</i></li>
			</ul>
			<ul class="nav-none" @click="clkNav" :class="navFlag==true?'nav-clk':''">
				<li></li>
				<li></li>
				<li></li>
			</ul>
		</div>
	</div>
</template>

<script>
	import $ from "jquery"
	export default {
		data() {
			return {
				navFlag:false,
				active:{},
				type:1
			}
		},
		
		props:{
			navTag:{
				type:String,
				default:''
			},
			themeColor:{
				type:String,
				default:"#0a4890"
			}
		},
		created() {
			let type = this.$route.query.type
			this.type = type
		},
		methods:{
			// 点击三条斜杠
			clkNav() {
				this.navFlag = !this.navFlag
				$(".nav-item").stop().slideToggle();
			},
			// 点击导航
			clkTag(name) {
				this.$router.push({name,query:{type:this.$route.query.type}})
			},
			clkIndex() {
				this.$router.push({name:"index"})
			}
		}
	}
</script>

<style lang="less" scoped>
	.father {
		background-color: #fff;
	}
	.gstle {
		text-align: right;
		line-height: 24px;
		color: rgba(102, 102, 102, 1);
		font-size: 16px;
		font-weight: 400;
		max-width: 1440px;
		margin: 0 auto;
		padding-top: 16px;
	}
	.nav {
		max-width: 1440px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 16px;
		font-family: SourceHanSansCN;
		font-weight: 300;
		box-sizing: border-box;
		padding: 28px 0;
		position: relative;
		img {
			cursor: pointer;
		}
		.regain {
			span {
				color: rgba(152, 155, 158, 1);
				&:nth-child(2) {
					margin: 0 10px;
				}
			}
		}
		.nav-none {
			display: none;
			width: 25px;
			height: 17px;
			li {
				width: 100%;
				height: 3px;
				border-radius: 10px;
				background-color: #000000;
				transition: all .2s;
				transform-origin: left;
			}
			&.nav-clk {
				li {
					&:nth-child(1) {
						transform: rotateZ(45deg);
						position: relative;
						top: -3px;
					}
					&:nth-child(2) {
						opacity: 0;
						position: relative;
						
					}
					&:nth-child(3) {
						transform: rotateZ(-45deg);
					}
				}
			}
		}
	}
	.nav .nav-item {
		display: flex;
		.active {
			font-weight: bold;
		}
	}
	.nav-item li {
		margin-right: 40px;
		cursor: pointer;
		&.d {
			display: none;
		}
		i.h {
			display: none;
		}
		
	}
	.nav-item li:last-child {
		margin-right: 0;
	}
	.nav-item .about,.nav-item .solid {
		margin:0 10px;
	}
	.nav .logo {
		max-width: 390px;
	}
	@media (max-width:1480px) {
		.login {
			padding: 20px;
		}
		.nav,.gstle {
			padding: 20px 20px;
			.logo {
				width: 20%;
			}
		}
		
	}
	@media (max-width:1000px) {
		.login {
			display: none;
			margin-bottom: 10px;
			li {
				margin-right: 15px;
			}
		}
		.gstle {
			display: none;
		}
		.nav {
			padding: 15px 20px;
			height: 70px;
			box-sizing: border-box;
			background-color: white;
			.logo {
				height: 100%;
				width: auto;
			}
			.nav-none {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				cursor: pointer;
			}
			.nav-item {
				flex-direction: column;
				position: absolute;
				left: 0;
				top: 70px;
				right: 0;
				z-index: 9999999;
				background-color: white;
				display: none;
				&.nav-block {
					// display: block;
				}
				li {
					height: 50px;
					line-height: 50px;
					text-align: center;
					border-bottom: 1px solid #ccc;
					margin-right: 0;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0 20px;
					color: rgba(152, 155, 158, 1);
					&.d {
						display: block;
						display: flex;
						justify-content: space-between;
						padding: 0 20px;
					}
					i.h {
						display: block;
						color: rgba(152, 155, 158, 1);
					}
					&:hover {
						color: var(--themeColor);
						cursor: pointer;
						font-weight: bold;
					}
					&.solid {
						display: none;
					}
				}
			}
		}
	}
	@media (max-width:768px) {
		.login {
			display: none;
		}
		.nav {
			padding: 10px 10px;
			.logo {
				width: 70%;
				height: auto;
			}
		}
	}
</style>