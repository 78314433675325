<!-- 左边菜单栏 -->
<template>
	<ul class="rg-nav">
		<template v-if="navRqt">
			<li v-for="(item,index) in navs" :key="item.id" class="nav-item nav-item2"
				@click.stop="clkItem(index,item)">
				<div class="catname" :class="tapItem==index?'active':''">{{item.catname}}</div>
				
				<ul v-if="child" class="nav-child" :class="tapItem==index?'nav-child-block':''">
					<li v-for="(item2,index2) in item.child" :key="item2.id" class="catname2"
						:class="tapItem2==index2?'active':''" @click.stop="clkItem2(index2,item2)">{{item2.catname}}
					</li>
				</ul>
				
			</li>
		</template>

		<template v-else>
			<li class="nav-item" @click="clkItem(index,item)" v-for="(item,index) in navs" :key="index">
				<div class="catname" :class="tapItem==index?'active':''">{{item}}</div>
			</li>
		</template>
	</ul>
</template>

<script>
	import $ from "jquery"
	export default {
		props: {
			navs: {
				type: Array,
				default: null
			},
			tapItem: {
				type: Number,
				default: 0
			},
			tapItem2: {
				type: Number,
				default: 0
			},
			// 是否是发送请求获取的左边栏
			navRqt: {
				type: Boolean,
				default: false
			},
			//是否展示二级列表
			child:{
				type: Boolean,
				default: true
			}
		},
		mounted() {

		},
		watch: {
			// 数据更新后重新挂载
			navs() {
				this.$nextTick(() => {
					this.showNav()
				})
			}
		},
		methods: {
			clkItem(index, item) {
				this.$emit("clkItem", index, item)
			},
			// 点击子菜单
			clkItem2(index, item) {
				this.$emit("clkItem2", index, item)
			},
			// 点击标题栏展示二级菜单
			showNav() {
				$(".nav-child-block").slideDown()
				$(".nav-item2").click(function() {
					$(this).children(".nav-child").stop().slideToggle()
					$(this).siblings().children(".nav-child").stop().slideUp()
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	// 导航
	.rg-nav {
		width: 230px;
		background-color: #fff;
		margin-right: 12px;
		flex-shrink: 0;

		.nav-item {
			.catname {
				height: 60px;
				line-height: 60px;
				text-align: center;
				border-bottom: 1px solid #f5f6f7;
				color: rgba(153, 153, 153, 1);
				font-size: 16px;
				font-weight: 400;
				cursor: pointer;

				&.active {
					background-color: var(--themeColor);
					color: #fff;

					&:hover {
						color: #fff;
						font-weight: normal;
					}
				}

				&:hover {
					color: var(--themeColor);
					font-weight: bold;
				}
			}

			.nav-child {
				display: none;

				.catname2 {
					height: 60px;
					line-height: 60px;
					text-align: center;
					border-bottom: 1px solid #f5f6f7;
					color: darkgray;
					font-size: 16px;
					font-weight: 400;
					cursor: pointer;
					background-color: #f7fbfe;

					&.active {
						color: var(--themeColor);
						font-weight: bold;
					}
				}
			}
		}
	}

	@media (max-width:1000px) {
		.rg-nav {
			width: 25%;
		}
	}

	@media (max-width:900px) {
		.rg-nav {
			display: flex;
			width: 100%;
			overflow: auto;

			.nav-item {
				div {
					padding: 0 20px;
				}

				flex-shrink: 0;
			}
		}
	}
	@media (max-width:768px) {
		.rg-nav {
			.nav-item {
				.catname {
					height: 50px;
					line-height: 50px;
					font-size: 15px;
				}
			}
		}
	}
</style>
