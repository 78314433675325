<template>
	<div class="activity">
		<div class="acty">
			<div class="act-item" @click="clkAct(item.url)" v-for="(item,index) in actis" :key="index">
				<img src="../../assets/images/index/dbx1.png" alt="" class="back">
				<div class="dd"></div>
				<div class="info">
					<img :src="item.thumb" alt="" class="icon">
					<div class="text">{{item.title}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import $ from "jquery"
	export default {
		data() {
			return {
				actis: []
			}
		},
		created() {
			this.getActy()
		},
		methods: {
			clkAct(url) {
				location.href = url
			},
			getActy() {
				this.$axios.get("Index/getList", {
					params: {
						catid: 176
					}
				}).then(res => {
					if (res.code) {
						this.actis = res.data.data
					}
				})
			},
			getCotain() {
				let letain = $(".activity")
				let width = letain.outerWidth() / 1440 * 100 + 'px'
				letain.css("font-size", width)
			}
		},
		mounted() {
			$(document).ready(() => {
				this.getCotain()
				$(window).resize(() => {
					this.getCotain()
				})
			})
		}
	}
</script>

<style scoped lang="less">
	.activity {
		max-width: 1440px;
		margin: 0 auto;
	}
	.activity .title {
		display: flex;
		font-size: 14px;
		font-family: SourceHanSansCN;
		font-weight: 400;
		color: var(--themeColor);
		align-items: center;
		box-sizing: border-box;
		padding: 0 42px;
		background-color: #fff;
		height: 74px;
		div {
			display: -webkit-box;
			overflow: hidden;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
		}
	}
	.title img {
		width: 33px;
		margin-right: 28px;
	}
	/* 活动标题 */
	.acty {
		display: grid;
		grid-template-columns: repeat(4,1fr);
		column-gap: 53px;
		background-color: #f7f7f7;
		padding: 56px 46px;
	}
	.acty .act-item {
		text-align: center;
		position: relative;
		font-size: 16px;
		font-family: SourceHanSansCN;
		font-weight: 300;
		color: #FFFFFF;
		box-sizing: border-box;
		cursor: pointer;
		&:hover {
			&::after {
				display: block;
				opacity: .3;
				
			}
		}
		&::after {
			content: '';
			display: none;
			position: absolute;
			top: -15px;
			bottom: -15px;
			left: 50px;
			right: 50px;
			background-color: #013CA1;
			opacity: 0;
			filter: blur(10px);
			border-radius: 50%;
		}
		.back {
			width: 50%;
		}
	}
	
	.acty .info {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 100%;
		z-index: 9999;
		.text {
			font-size: 16px;
		}
	}
	.acty .icon {
		width: 17px;
		margin-bottom: 12px;
	}
	@media (max-width:1440px) {
		.acty {
			column-gap: 40px;
		}
	}
	@media (max-width:1300px) {
		.acty {
			column-gap: 30px;
		}
	}
	@media (max-width:1200px) {
		.acty {
			.info {
				.text {
					font-size: 14px;
				}
			}
			column-gap: 30px;
		}
	}
	@media (max-width:1000px) {
		.activity {
			.title {
				height: 60px;
			}
		}
		.acty {
			padding: 30px 46px;
			column-gap: 80px;
			row-gap: 20px;
			.info {
				.text {
					font-size: 14px;
				}
			}
			.icon {
				width: 20px;
			}
			.act-item {
				&::after {
					left: 30px;
					right: 30px;
				}
			}
		}
	}
	@media (max-width:900px) { 
		.activity {
			.title {
				padding: 0 10px;
			}
		}
		.acty {
			padding: 20px 10px;
			.info {
				.text {
					font-size: 12px;
				}
			}
			.act-item {
				&::after {
					left: 20px;
					right: 20px;
				}
			}
		}
	}
	@media (max-width:800px) {
		.acty {
			column-gap: 50px;
			row-gap: 20px;
			.icon {
				width: 20px;
			}
			.act-item {
				&::after {
					left: 15px;
					right: 15px;
				}
			}
		}
	}
	@media (max-width:700px) {
		.acty {
			column-gap: 30px;
			row-gap: 20px;
			.icon {
				width: 18px;
			}
			.act-item {
				&::after {
					left: 10px;
					right: 10px;
				}
				.back{
					width: 100%;
				}
			}
		}
	}
	@media (max-width:550px) {
		.acty {
			column-gap: 20px;
			row-gap: 20px;
			.info {
				.text {
					font-size: 12px;
				}
			}
			.act-item {
				&::after {
					left: -5px;
					right: -5px;
				}
			}
		}
	}
</style>
