import qs from "qs"
// const baseUrl = "http://gansu.web.zhongwangyingtong.com/index.php/api/"
const baseUrl = ""

export let base = baseUrl
// 区域股权业务
export let stock = {
	// 获左边栏
	LeftSidebar:baseUrl+"Business/LeftSidebar",
	// 获取右侧列表
	LeftSidebarList:baseUrl+"Business/LeftSidebarList",
	// 获取下载列表
	DownloadList:baseUrl+"Business/DownloadList",
	// 获取详情
	Detail:baseUrl+"Business/Detail"
}
// 首页
export let index = {
	// 获取首页轮播
	getBanner:baseUrl+"Base/getBanner",
	// 查找重点项目推荐
	getProJect:baseUrl+"Property/projectList",
	// 查询详情
	getProDeal:baseUrl+"Property/projectDetail",
	// 查询新闻详情
	news_detail:baseUrl+"Aboutus/news_detail",
	// 左侧导航
	notLeftNav:baseUrl+"Property/leftNav",
	// 查询公告列表
	noticeList:baseUrl+"Property/noticeList",
	// 查询公告详情
	noticeDetail:baseUrl+"Property/noticeDetail",
	// 获取友情链接
	getFriend:baseUrl+"Base/getFriend"
}
// 会员服务
export let member = {
	// 获取侧边栏导航
	LeftSidebar:baseUrl+"Member/LeftSidebar"
}

// 投资者教育
export let ecation = {
	// 常见问题
	InvestmentList:baseUrl+"Policyzone/InvestmentList",
	// 问题详情
	InvestmentDetails:baseUrl+"Policyzone/InvestmentDetails",
	// 活动报名
	ActivitySign:baseUrl+"Policyzone/ActivitySign"
}

// 培训路演
export let train = {
	// 获取列表页
	GetTrainList:baseUrl+"Policyzone/GetTrainList",
	// 获取企业路演分类
	GetLeftPar:baseUrl+"Policyzone/GetLeftPar",
	// 提交信息登记
	InfoSubmit:baseUrl+"Policyzone/InfoSubmit"
}

// 政策专区
export let policy = {
	// 左边栏数据
	LeftSidebar:baseUrl+"Policyzone/LeftSidebar",
	// 获取右边列表
	getlist:baseUrl+"Policyzone/getlist",
	// 详情页
	getdetails:baseUrl+"Policyzone/getdetails"
}

// 产权业务首页
export let home = {
	// 获取首页轮播
	getBanner:baseUrl+"Base/getBanner"
}

//交易公告
export let trading = {
	//左边导航
	leftNav:baseUrl+"Property/leftNav",
	//公告列表
	noticeList:baseUrl+"Property/noticeList",
	//公告详情
	noticeDetail:baseUrl+"Property/noticeDetail",
	//在线留言
	OnlineMessage:baseUrl+"Property/OnlineMessage"
}

//交易制度
export let system = {
	//交易制度列表
	regimeList:baseUrl+"Property/regimeList",
	//交易制度详情
	regimeDetail:baseUrl+"Property/regimeDetail"
}

//重点项目推荐
export let promote = {
	//项目列表
	projectList:baseUrl+"Property/projectList",
}

 //项目展示
export let project = {
	//项目列表
	projectList:baseUrl+"Property/projectList",
	//项目详情
	projectDetail:baseUrl+"Property/projectDetail"
}

	
// 关于我们
export let about = {
	// 在线留言
	OnlineMessage:baseUrl+"Aboutus/OnlineMessage",
	// 获取详情
	Getsubject:baseUrl+"Aboutus/Getsubject",
	// 查询详情
	getSubDail(left_id,that) {
		return new Promise(resole=>{
			let url = this.Getsubject
			let data = {
				id:left_id
			}
			that.$axios.post(
				url,
				qs.stringify(data)
			).then(res=>{
				if(res.code) {
					resole(res.data)
				}
			})
		})
	},
	// 发展历程
	history:baseUrl+"Aboutus/history",
	// 党建工作
	work_center:baseUrl+"Aboutus/work_center",
	// 新闻公告
	news_center:baseUrl+"Aboutus/news_center",
	// 上传图片
	upload:"http://gansu.web.zhongwangyingtong.com/index.php/attachment/upload/upload",
	// 联系我们
	OnlineMessage:baseUrl+"Aboutus/OnlineMessage",
	// 党建工作详情
	work_detail:baseUrl+"Aboutus/work_detail",
	// 新闻列表
	news_center2:baseUrl+"Aboutus/news_center2",
	//顶部导航栏
	navigation:baseUrl+"Aboutus/navigation",
	//左边导航栏
	LeftSidebar:baseUrl+"Aboutus/LeftSidebar"
}