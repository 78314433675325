<template>
	<div class="not-tap">
		<div class="not">
			<div v-show="!tapsTrue" class="not-item" v-for="(item,index) in taps" :class="index==tapIndex?'active':''" :style="[{borderColor:index==tapIndex?bgColor:''},{color:index==tapIndex?bgColor:''}]" @click="clkTap(index)">{{item}}</div>
			<div v-show="tapsTrue" class="not-item" v-for="(item,index) in taps" :class="index==tapIndex?'active':''" :style="[{borderColor:index==tapIndex?bgColor:''},{color:index==tapIndex?bgColor:''}]" @click="clkTap(index,item.id)">{{item.catname}}</div>
		</div>
		<slot>
			<div class="tmore" v-if="isMore" @click="More">了解更多<i class="iconfont">&#xe642;</i></div>
		</slot>
	</div>
</template>

<script>
	export default {
		props:{
			taps:{
				type:Array,
				default:[]
			},
			tapIndex:{
				type:Number,
				default:0
			},
			isMore:{
				type:Boolean,
				default:true
			},
			bgColor:{
				type:String,
				default:"var(--themeColor)"
			},
			// 是否是后台传值的
			tapsTrue:{
				type:Boolean,
				default:false
			}
		},
		methods:{
			clkTap(index,id=0) {
				this.$emit("clkTap",index,id)
			},
			More(){
				this.$emit("more")
			}
		}
	}
</script>

<style lang="less" scoped>
	.not-tap {
		display: flex;
		justify-content: space-between;
		position: relative;
		&::after {
			display: block;
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 2px;
			background-color: #e9edef;
		}
		.not {
			display: flex;
			.not-item {
				font-size: 19px;
				font-family: SourceHanSansCN;
				font-weight: 300;
				color: #5D6779;
				margin-right: 56px;
				padding: 13px 0;
				border-bottom: 2px solid transparent;
				cursor: pointer;
				position: relative;
				z-index: 999;
				flex-shrink: 0;
				&.active {
					border-color: #0a4890;
					color: #0a4890;
					font-weight: bold;
				}
				&:last-child {
					margin-right: 0;
				}
				&:hover {
					color: #0a4890;
				}
			}
		}
		.tmore{
			cursor: pointer;
			font-size: 16px;
			i {
				font-size: 14px;
			}
			&:hover{
				color: var(--themeColor);
			}
		}
	}
	@media (max-width:900px) {
		.not-tap  {
			.not {
				.not-item {
					font-size: 18px;
				}
			}
			.tmore {
				font-size: 14px;
			}
		}
	}
	@media (max-width:768px) {
		.not-tap  {
			.not {
				.not-item {
					margin-right: 30px;
				}
			} 
		}
	}
	
	@media (max-width:500px) {
		.not-tap {
			.not {
				.not-item {
					font-size: 16px;
				}
			}
		}
	}
</style>