<!-- 公告详情 -->
<template>
	<div class="details">
		<probm :probm="delObj">
			<template v-slot:addIcon>
				<p><i class="iconfont">&#xe616;</i>{{delObj.title}}</p>
			</template>
			<template v-slot:shareIcon>
				<div class="share">
					<div>分享到：</div>
					<div class="sh-icon">
						<p><i class="iconfont">&#xe609;</i></p>
						<p><i class="iconfont">&#xe602;</i></p>
						<p><i class="iconfont">&#xe63d;</i></p>
					</div>
				</div>
			</template>
		</probm>
		<div class="tip">如果您对以上项目有意向，请在下方留下您的联系方式：</div>
		<div class="from">
			<el-form label-width="40px" :model="ruleForm">
				<el-form-item label="主题">
					<el-input v-model="ruleForm.theme"></el-input>
				</el-form-item>
				<el-form-item label="栏目">
					<el-select v-model="ruleForm.column" class="column">
						<el-option label="推介公告" value="1"></el-option>
						<el-option label="公告" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="姓名">
					<el-input v-model="ruleForm.name" placeholder="请输入您的姓名"></el-input>
				</el-form-item>
				<el-form-item label="电话">
					<el-input v-model="ruleForm.mobile" placeholder="请输入您的电话"></el-input>
				</el-form-item>
				<el-form-item label="邮箱">
					<el-input v-model="ruleForm.email" placeholder="请输入您的邮箱"></el-input>
				</el-form-item>
				<el-form-item label="单位">
					<el-input v-model="ruleForm.unit" placeholder="请输入您的单位"></el-input>
				</el-form-item>
				<el-form-item label="内容">
					<el-input type="textarea" v-model="ruleForm.content" placeholder="请输入您的内容"></el-input>
				</el-form-item>
				<el-form-item>
					<div class="sub" @click="onSubmit">提交</div>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import qs from "qs"
	import probm from "@/components/probm/probm"
	import util from "@/assets/script/util.js"
	import moment from "moment"
	export default {
		created() {
			let id = this.$route.query.id
			this.getDeail(id)
		},
		components:{
			probm
		},
		data() {
			return {
				ruleForm: {
					theme: '永登县城关镇和平街住宅转让推荐公告',
					column: '推荐公告',
					name: '',
					mobile: '',
					email: '',
					resource: '',
					content: ''
				},
				delObj:{}
			}
		},
		methods: {
			onSubmit() {
				this.OnlineMessage()
			},
			// 在线留言
			OnlineMessage() {
				let url = this.$api.trading.OnlineMessage
				this.$axios.post(
					url,
					qs.stringify(this.ruleForm)
				).then(res => {
					// if (res.code) {
					// 	this.ruleForm = {}
					// }
					
					if (res.code) {
						this.$message({
						    message: '提交成功',
						    type: 'success'
						});
						this.ruleForm = {}
						
					}else {
						this.$message({
						    message: res.msg,
						    type: 'error'
						});
					}
					
					
				})
			},
			// 根据id查询详情			async getDeail(id) {				let url = this.$api.index.getProDeal				let res = await this.$axios.get(url,{params:{id}})				if(res.code) {
					res.data.inputtime = moment(res.data.inputtime*1000).format("YYYY-MM-DD")
					res.data.content = util.setContent(res.data.content)					this.delObj = res.data
					this.ruleForm.theme = res.data.title				}			}
		}
	}
</script>

<style lang="less" scoped>
	.share {
		display: flex;
		align-items: center;
		color: rgba(102, 102, 102, 1);
		font-size: 16px;
		font-weight: 500;
		justify-content: flex-end;
		border-top: 0.5px solid rgba(235, 236, 239, 1);
		padding-top: 34px;
		margin-top: 34px;
		.sh-icon {
			display: flex;
			p {
				width: 32px;
				height: 32px;
				background: rgba(235, 236, 239, 1);
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 12px;
				&:last-child {
					margin-right: 0;
				}
				i {
					font-size: 24px;
				}
			}
		}
	}
	.tip {
		font-size: 22px;
		font-weight: 500;
		color: var(--themeColor);
		margin-top: 84px;
		padding-bottom: 20px;
		border-bottom: 0.5px solid rgba(235, 236, 239, 1);
	}
	.from {
		margin-top: 40px;
	}
	.sub {
		width: 114px;
		height: 48px;
		background-color: var(--themeColor);
		color: white;
		text-align: center;
		line-height: 48px;
		cursor: pointer;
	}
</style>
