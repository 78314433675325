import Vue from 'vue'
import App from './App.vue'
import router from './router'

// 使用全局样式
import './assets/styles/base.css'
import './assets/styles/icon/iconfont.css'
import "./assets/styles/style.css"

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)
// 轮播图
import Swiper from "swiper"
import 'swiper/dist/css/swiper.css'
// 动画样式
import animate from "animate.css";
Vue.config.productionTip = false
// 全局组件
import navs from "@/components/nav/nav"
Vue.component("navs",navs)
import title from "@/components/title/title"
Vue.component("titles",title)
import tap from "@/components/tap/tap"
Vue.component("tap",tap)
import less from 'less'
import crumbs from "@/components/crumbs/crumbs"
Vue.component("crumbs",crumbs)
import bottom from "@/components/index/bottom"
Vue.component("bottom",bottom)
import navleft from "@/components/navleft/navleft"
Vue.component("navleft",navleft)
import homenav from "@/components/home/homenav"
Vue.component("homenav",homenav)
Vue.use(less)


// 获取axios
import axios from "axios"
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.baseURL="https://www.gsotc.com.cn/api/";
// axios.defaults.baseURL="http://47.113.125.133/index.php/api/"
axios.interceptors.response.use(res => {
　　return res.data;
})
// 获取接口api
import * as api from "@/assets/script/api.js"


new Vue({
	router,
	render: h => h(App),
	// 安装全局事件总线，$bus就是相当于当前应用的vm
	beforeCreate() {
		Vue.prototype.$bus = this;
		Vue.prototype.$api = api;
		Vue.prototype.$axios = axios
	},
}).$mount('#app')
