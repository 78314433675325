<!-- 竞价大厅详情 -->
<template>
	<div>
		<price></price>
	</div>
</template>

<script>
	import price from "@/components/index/price"
	export default {
		components:{
			price
		}
	}
</script>

<style lang="less" scoped>
	/deep/ .titleh {
		display: none;
	}
	/deep/ .price {
		background-color: #fff;
	}
	/deep/ .price .pri-type .more {
		background-color: #fff;
	}
</style>
