<template>
	<div>
		<!-- 导航栏 -->
		<navs navTag="infotion"></navs>
		<div class="region">
			<!-- 面包屑 -->
			<crumbs :crumbs="crumbs" @clkItem="clkItemd"></crumbs>
			<div class="rg-cotain">
				<!-- 侧边导航栏 -->
				<navleft :navs="navs" :tapItem="tapItem" @clkItem="clkItem" :navRqt="true"></navleft>
				<!-- 子组件渲染 -->
				<div class="chirn">
					<router-view :catid="catid"/>
				</div>
			</div>
			<!-- 底部 -->
			<bottom></bottom>
		</div>
	</div>
</template>

<script>
	import phonePg from "@/components/phonePg/phonePg"
	import $ from "jquery"
	import moment from "moment"
	export default {
		data() {
			return {
				crumbs: ['首页', '信息披露'],
				left_id: 169,
				navs: [],
				tapItem: 0,
				catid:0
			}
		},
		components: {
			phonePg
		},
		created() {
			let index = parseInt(this.$router.history.current.query.index)
			this.tapItem = index
			this.getDetail()
			this.getCategory()
		},
		mounted() {
			this.getIptHg()
		},
		methods: {
			// 点击菜单栏
			clkItem(index, text) {
				this.catid = text.id
				this.tapItem = index
				this.crumbs = ['首页','托管挂牌公告']
				// 修改数组
				this.$set(this.crumbs,1,text.catname)
				// this.left_id = text.child[0].id
				switch (index) {
					case 0:
						this.$router.push({name:"infolist",query:{index}})
					break;
					case 1:
						
						this.$router.push({name:"infolist",query:{index}})
					break;
					case 2:
						this.$router.push({name:"infolist",query:{index}})
					break;
					case 3:
						this.$router.push({name:"info",query:{index}})
					break;
				}
			},
			getIptHg() {
				let iptHg = $(".el-input__inner").eq(0).height() + 2;
				$(".lab-sear").css("height", iptHg + "px")
			},
			clkItemd(index) {
				switch (index) {
					case 0:
						this.$router.push({name:"index"})
					break;
				}
			},
			//获取左边导航列表
			getCategory() {
				this.$axios.get("Index/getCategory", {
					params: {
						catid: 181
					}
				}).then(res => {
					if (res.code) {
						this.navs = res.list
					}
				})
			},
			// 获取详情
			getDetail() {
				this.$axios.get("Download/getDetail", {
					params: {
						id: 1
					}
				}).then(res => {
					if (res.code) {
						this.details = res
						console.log('this.details', this.details)
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.chirn {
		background-color: #fff;
		padding: 30px;
		box-sizing: border-box;
		width: 100%;
		align-self: normal;
		
	}
	.region .rg-cotain {
		margin-bottom: 60px;
	}
	@media (max-width: 900px){
		.region .rg-cotain{
			flex-direction: column;
		}
	}
	@media (max-width:768px) {
		.chirn {
			padding: 10px;
		}
	}
</style>
