<!-- 友情链接 -->
<template>
	<div class="frient" id="frient">
		<titles tleText="友情链接" style="margin-bottom: 20px;">
			<div></div>
		</titles>
		<!-- <div class="img">
			<div v-for="(item,index) in frients" :key="index" @click="clkAhref(item.url)" class="img-item">
				<img :src="item.thumb" alt="">
			</div>
		</div> -->


		<el-carousel trigger="click" :height="imgHeight" :loop="true" :autoplay="false" arrow="never">
			<el-carousel-item v-for="(item,index) in frients" :key="index">
				<div class="img">
					<img v-for="(item2,index2) in item" :key="index2" @click="clkAhref(item2.url)"
						:src="item2.thumb" class="img-item">
				</div>
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script>
	import $ from "jquery"
	export default {
		data() {
			return {
				frients: {},
				width: '',
				imgHeight:""
			}
		},
		created() {
			this.getFriend()
		},
		methods: {
			getFriend() {
				let url = this.$api.index.getFriend
				let params = {
					limit: 50
				}
				this.$axios.get(url, {
					params
				}).then(res => {
					if (res.code) {
						// this.frients = res.data.data
						// this.$nextTick(() => {
						// 	this.getMaxHeight()
						// })
						this.frients = res.data
						this.$nextTick(()=>{
							this.imgHeight = ($(".frient .img").height()+40)+"px";
						})
						
					}
				})
			},
			clkAhref(url) {
				window.location.href = url
			},
			getCotain() {
				let letain = $("#frient")
				let width = letain.outerWidth() / 1440 * 100 + 'px'
				letain.css("font-size", width)
			},
			getMaxHeight() {
				let imgItem = $(".img-item")
				let higArr = []
				for (let i = 0; i < imgItem.length; i++) {
					higArr.push(imgItem.eq(i).outerHeight())
				}
				var max = Math.max.apply(null, higArr);
				imgItem.css("height", max + "px")
			}
		},
		mounted() {
			$(document).ready(() => {
				this.getFriend()
				this.getCotain()
				if($())
				$(window).resize(() => {
					this.getCotain()
				})
			})
		},
	}
</script>

<style lang="less" scoped>
	.frient {
		max-width: 1440px;
		margin: 0 auto;
		position: relative;
		padding: 75px 40px 40px;
		box-sizing: border-box;

		// background-color: #fff;
		.arrow {}

		.img {
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			column-gap: 23px;
			row-gap: 18px;
			align-items: center;
			// margin-top: 42px;

			.img-item {
				overflow: hidden;
				background-color: #fff;
				display: flex;
				align-items: center;
				transition: box-shadow .5s, transform .5s;
				cursor: pointer;
				// box-shadow: 0 0 5px rgba(0,0,0,.3);
				box-shadow: 0 0 5px rgba(0, 0, 0, .1);
				width: 100%;
				height: 80px;

				&:hover {
					transform: translateY(-5px);

					img {
						transform: scale(1.1);
					}
				}

				img {
					width: 100%;
					transition: transform .5s;
				}
			}

		}
		/deep/.el-carousel__button{
			background-color: var(--themeColor);
		}
	}

	@media (max-width:768px) {
		.frient {
			padding: 20px 10px 20px 10px;

			.img {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				column-gap: 15px;
				row-gap: 15px;
				margin-top: 15px;
				.img-item {
					width: 100%;
					height: 50px;
				}
			}
		}
	}
</style>
