<!-- 项目列表 -->
<template>
	<div class="property">
		<div class="table">
			<el-table :data="tableData.data" style="width: 100%"
				:header-cell-style="{background:'rgba(247, 251, 254, 1)',height:'56px',textAlign: 'center'}"
				:cell-style="{textAlign: 'center'}" :row-style="{height:84+'px'}"
				@row-click="clkTableRow">
				<el-table-column prop="projectno" label="项目编号" width="300"></el-table-column>
				<el-table-column prop="title" label="项目名称"></el-table-column>
				<el-table-column :formatter="PriceFormat" prop="price" label="挂牌价格(万元)"></el-table-column>
				<el-table-column prop="enddate" label="挂牌截止日期"></el-table-column>
				<el-table-column label="项目状态" v-if="false">
					<template slot-scope="scope">
						<div class="baombtn" v-show="timeFlag(scope.row.enddate)" @click.stop="clkTableRow(scope.row)">报名中</div>
						<div class="baombtn jzbm" v-show="!timeFlag(scope.row.enddate)" @click.stop="clkTableRow(scope.row)">截止报名</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 分页 -->
		<div class="block">
			<el-pagination background :page-size="parseInt(tableData.per_page)" layout="total, prev, pager, next, jumper"
				:total="tableData.total" @current-change="proChange">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import $ from "jquery"
	export default {
		data() {
			return {
				page: 1,
				tableData: []
			};
		},
		props: {
			left_id: {
				type: Number,
				default: 0
			}
		},
		computed:{
			timeFlag() {
				return val=>{
					let date = new Date()
					let endDate = new Date(val.replace("-","/"))
					if(endDate>date) {
						return true
					}else {
						return false
					}
				}
			}
		},
		watch: {
			left_id(news, old) {
				this.projectList(news)
			}
		},
		created() {
			this.projectList(this.left_id)
		},
		methods: {
			// 单击某一行
			clkTableRow(row) {
				let index = this.$route.query.index
				let id = row.id
				this.$router.push({
					path: '/project/annodetail',
					query: {
						index,
						id
					}
				})
			},
			// 价格格式化显示
			PriceFormat(row, column) {
				return column.price == null ? "详见公告" : column.price;
			},
			// 获取项目列表
			projectList(catid) {
				// 获取公告列表
				let url = this.$api.trading.noticeList
				let data = {
					catid,
					page: this.page
				}
				this.$axios.get(
					url, {
						params: data
					}
				).then(res => {
					if (res.code) {
						this.tableData = res.data
					}
				})
			},
			proChange(e) {
				this.page = e
				this.projectList(this.left_id)
			}
		}
	}
</script>

<style lang="less" scoped>
	.baombtn {
		background-color: var(--themeColor);
		color: #fff;
		width: 100px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		color: #fff;
		margin: 0 auto;
		cursor: pointer;
		
		&:hover {
			background-color: rgba(10, 72, 144,.6);
		}
	}
	.jzbm {
		background-color: rgba(218, 218, 218,1);
		&:hover {
			background-color: rgba(218, 218, 218,1);
		}
	}
	.property {
		background-color: #fff;
	}

	.number {
		text-align: right;
		margin-top: 60px;

	}

	.number-n {
		display: none;
	}

	@media (max-width:768px) {
		.property {
			padding: 10px 0;
		}

		.number {
			display: none;

		}

		.number-n {
			margin-top: 10px;
			display: block;
		}
	}
</style>
