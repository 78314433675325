<template>
	<div class="cupon">
		<newList @clkCup="clkCup" :policy="policy.data"></newList>
		<!-- 分页 -->
		<template v-if="policy.data.length">
			<div class="block">
				<el-pagination background :page-size="policy.per_page" layout="total, prev, pager, next, jumper"
					:total="policy.total" @current-change="changePg">
				</el-pagination>
			</div>
			<div class="block2">
				<phonePg :page="page" :total="policy.total" :perPage="policy.per_page" @pageNext="pageNext"
					@pagePref="pagePref"></phonePg>
			</div>
		</template>
		<template v-else>
			<el-empty description="暂无数据"></el-empty>
		</template>
	</div>
</template>

<script>
	import newList from "@/components/new/newList"
	import phonePg from "@/components/phonePg/phonePg"
	import moment from "moment"
	export default {
		data() {
			return {
				policy:{},
				page:1,
				catid:0
			}
		},
		components: {
			newList,
			phonePg
		},
		props: {
			catid:{
				type:Number,
				default:0
			}
		},
		watch:{
			catid(newVal,oldVal) {
				this.getNoticeList(newVal)
				if(newVal==170) {
					// this.catid = 60
					return false
				}
				this.catid = newVal
			}
		},
		created() {
			let index = this.$route.query.index
			if(index==0) {
				this.getNoticeList(169)
			}else if(index==1) {
				// this.getNoticeList(60)
				this.getNoticeList(170)
			}else if(index==2) {
				this.getNoticeList(171)
			}
			// this.getNoticeList(this.catid)
		},
		methods: {
			// 查询公告列表
			async getNoticeList(id) {
				let url = this.$api.index.noticeList
				let data = {
					catid:id,
					page:this.page
				}
				if(id==60) {
					data.type = 1
				}
				let res = await this.$axios.get(url,{params:data})
				if(res.code) {
					this.policy = res.data
					res.data.data.forEach((item,index)=>{
						item.inputtime = moment(item.inputtime*1000).format("YYYY-MM-DD")
					})
				}
			},
			// 分页触发
			changePg(e) {
				this.page = e
				this.getNoticeList(this.catid)
			},
			// 点击查看详情
			clkCup(index,id) {
				this.$router.push({"path":"/infotion/details",query:{index,id}})
			}
		}
	}
</script>

<style lang="less" scoped>
	@media (max-width:900px) {
		.cupon {
			padding: 10px 0px 0 0px;
		}
	}
</style>
