<template>
	<!-- 会员服务 -->
	<div>
		<navs navTag="member"></navs>
		<div class="region">
			<!-- 面包屑 -->
			<crumbs :crumbs="crumbs" @clkItem="clkItemd"></crumbs>
			<div class="rg-cotain">
				<!-- 侧边导航栏 -->
				<navleft :navs="navs" :tapItem="tapItem" @clkItem="clkItem"></navleft>
				<!-- 子组件渲染 -->
				<div class="chirn">
					<router-view/>
				</div>
			</div>
			
		</div>
		<!-- 底部 -->
		<bottom></bottom>
	</div>
</template>

<script>
	import qs from "qs"
	export default {
		data() {
			return {
				crumbs:['首页','会员服务','会员展示'],
				tapItem:0,
				navs:['会员展示','会员注册','业务办理']
			}
		},
		created() {
		},
		methods:{
			clkItem(index,text) {
				this.tapItem = index
				this.crumbs = ['首页','会员服务','会员展示']
				// 修改数组
				this.$set(this.crumbs,2,text)
				
				switch (index) {
					case 0:
						this.$router.push({name:"show"})
					break;
					case 1:
						this.$router.push({name:"shreg"})
					break;
				}
			},
			clkItemd(index) {
				switch(index) {
					case 0:
						this.$router.push({name:"index"})
					break;
				}
			}
		},
		mounted() {
			this.$bus.$on('clkTableRow',(data)=>{
				this.crumbs.push(data)
			})
		},
	}
</script>

<style lang="less" scoped>
	.region {
		.rg-cotain {
			margin-bottom: 60px;
		}
	}
	
	
	
	@media (max-width:900px) {
		.region {
			.rg-cotain {
				flex-direction: column;
				.chirn {
					margin-top: 10px;
				}
			}
		}
	}
	
</style>