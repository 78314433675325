<!-- 投资者教育活动 -->
<template>
	<div class="activity">
		<tap :taps="taps" :tapIndex="tapIndex" @clkTap="clkTap"><span></span></tap>
		<!-- 活动预告 -->
		<template v-if="tapIndex==0">
			<ul class="acty">
				<li v-for="(item,index) in eventpres.data" :key="item.id" @click="clkBaoMin(item.id)">
					<img :src="item.thumb" alt="" class="img">
					<div class="ac-cotain">
						<h2 class="ac-tle">{{item.title}}</h2>
						<h3 class="teacher">主讲老师：{{item.speaker}}</h3>
						<h4 class="ac-time">活动时间：{{item.activitytime}}</h4>
						<h5 class="ac-adres">
							<P>活动地点：</P>
							<p>{{item.activityplace}}</p>
						</h5>
						<div class="ac-btn">报名参加</div>
					</div>
				</li>
			</ul>
			<!-- 分页 -->
			<div class="block">
				<el-pagination
					background
					:page-size="eventpres.per_page"
					layout="total, prev, pager, next, jumper"
					:total="eventpres.total"
					@current-change="eventPre">
				</el-pagination>
			</div>
			<!-- 手机端分页 -->
			<div class="block2" v-if="eventpres.length>eventpres.per_page">
				<phonePg :page="page" :total="eventpres.total" :perPage="eventpres.per_page" @pageNext="pageNext" @pagePref="pagePref"></phonePg>
			</div>
			
		</template>
		<!-- 历史活动 -->
		<template v-else>
			<ul class="acty">
				<li v-for="(item,index) in hisActs.data" :key="index" @click="clkHisDail(item.id)">
					<img :src="item.thumb" alt="" class="img">
					<div class="ac-cotain">
						<h2 class="ac-tle">{{item.title}}</h2>
						<h3 class="teacher">主讲老师：{{item.speaker}}</h3>
					</div>
				</li>
			</ul>
			<!-- 分页 -->
			<div class="block">
			    <el-pagination
					background
					:page-size="hisActs.per_page"
					layout="total, prev, pager, next, jumper"
					:total="hisActs.total"
					@current-change="eventPre">
			    </el-pagination>
			</div>
			<!-- 手机端分页 -->
			<div class="block2">
				<phonePg></phonePg>
			</div>
		</template>
	</div>
</template>

<script>
	import phonePg from "@/components/phonePg/phonePg"
	import qs from "qs"
	import moment from "moment"
	export default {
		data() {
			return {
				taps:['活动预告','历史活动'],
				tapIndex:0,
				item_id:44,
				// 活动预告
				eventpres:{},
				// 历史活动
				hisActs:{},
				page:1
			}
		},
		components:{
			phonePg
		},
		created() {
			this.InvestmentList(this.page)
		},
		methods:{
			// 点击标签栏
			clkTap(index) {
				if(index==this.tapIndex) {
					return false
				}
				this.tapIndex = index
				this.page = 1
				if(!index) {
					this.item_id = 44
				}else {
					this.item_id = 45
				}
				this.InvestmentList(this.page)
			},
			// 点击报名参加
			clkBaoMin(id) {
				this.$router.push({name:"athref",query:{index:2,id,left_id:this.item_id}})
				this.$bus.$emit("ettap","活动报名")
			},
			// 点击历史项目
			clkHisDail(id) {
				this.$router.push({name:"hisDetail",query:{index:2,id,left_id:this.item_id}})
			},
			// 上一页
			pageNext() {
				if(this.page-1==0) {
					return false
				}
				this.page = this.page-1
				this.InvestmentList(this.page)
			},
			// 下一页
			pagePref() {
				if(!this.tapIndex) {
					if(this.page*this.eventpres.per_page>this.eventpres.total) {
						return false
					}
					this.page = this.page+1
					this.InvestmentList(this.page)
				}else{
					if(this.page*this.hisActs.per_page>this.hisActs.total) {
						return false
					}
					this.page = this.page+1
					this.InvestmentList(this.page)
				}
				
			},
			// 获取列表
			InvestmentList(page) {
				let url = this.$api.ecation.InvestmentList
				let data = {
					nav_id:5,
					clas_id:41,
					item_id:this.item_id,
					page
				}
				this.$axios.post(
					url,
					qs.stringify(data)
				).then(res=>{
					if(res.code) {
						if(res.data.data.length) {
							res.data.data.forEach(item=>{
								item.activitytime = moment(parseInt(item.activitytime*1000)).format("YYYY-MM-DD")
							})
							if(!this.tapIndex) {
								this.eventpres = res.data
							}else {
								this.hisActs = res.data
							}	
						}
					}
				})
			},
			// 活动预告分页触发
			eventPre(e) {
				this.page = e
				this.InvestmentList()
			}
		}
	}
</script>

<style lang="less" scoped>
	.activity {
		.acty {
			display: grid;
			grid-template-columns: repeat(3,1fr);
			column-gap: 20px;
			row-gap: 30px;
			margin-top: 30px;
			li {
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
				transition: all .5s;
				cursor: pointer;
				&:hover {
					transform: translateY(-5px);
				}
				.img {
					width: 100%;
					height: 2.06em;
					object-fit: cover;
				}
				.ac-cotain {
					padding: 20px;
					.ac-tle {
						color: rgba(51, 51, 51, 1);
						font-size: 22px;
						font-weight: 500;
						margin-bottom: 20px;
					}
					.teacher,.ac-time,.ac-adres {
						color: rgba(102, 102, 102, 1);
						font-size: 16px;
						font-weight: 400;
						margin-top: 7px;
						line-height: 23px;
					}
					.ac-adres {
						display: flex;
						p {
							line-height: 23px;
							&:first-child {
								flex-shrink: 0;
							}
						}
					}
					.ac-btn {
						width: 100%;
						height: 48px;
						box-sizing: border-box;
						border: 1px solid var(--themeColor);
						font-size: 16px;
						font-weight: 400;
						color: var(--themeColor);
						text-align: center;
						line-height: 48px;
						margin-top: 20px;
						cursor: pointer;
						transition: all .2s;
						&:hover {
							background-color: var(--themeColor);
							color: #fff;
						}
					}
				}
			}
		}
	}
	
	@media (max-width:1350px) {
		.activity {
			.acty {
				grid-template-columns: repeat(2,1fr);
			}
		}
	}
	
	@media (max-width:768px) {
		.activity {
			.acty {
				li {
					.ac-cotain {
						.ac-tle {
							font-size: 18px;
						}
					}
				}
			}
		}
	}
	
	@media (max-width:700px) {
		.activity {
			.acty {
				column-gap: 15px;
				margin-top: 15px;
			}
			/deep/ .not-tap {
				margin-bottom: 15px;
			}
		}
	}
	
	@media (max-width:600px) {
		.activity {
			.acty {
				grid-template-columns: repeat(1,1fr);
				li {
					.img {
						height: 300px;
					}
				}
			}
		}
	}
</style>