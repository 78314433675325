<!-- 公告详情 -->
<template>
  <div class="details">
    <probm :probm="delObj">
      <template v-slot:addIcon>
        <p><i class="iconfont">&#xe616;</i>{{ delObj.title }}</p>
      </template>
      <template v-slot:shareIcon>
        <div class="share">
          <div>分享到：</div>
          <div class="sh-icon " >
            <p ><i class="iconfont " @click="clkShare(1)">&#xe609;</i></p>
            <p><i class="iconfont" @click="clkShare(2)">&#xe602;</i></p>
            <p><i class="iconfont" @click="clkShare(3)">&#xe63d;</i></p>
          </div>
        </div>
      </template>
    </probm>
    <div class="tip">如果您对以上项目有意向，请在下方留下您的联系方式：</div>
    <div class="from">
      <el-form label-width="40px" :model="ruleForm" >
        <el-form-item label="主题">
          <el-input v-model="ruleForm.title"></el-input>
        </el-form-item>
        <!-- <el-form-item label="栏目">
					<el-select v-model="ruleForm.column" class="column">
						<el-option label="推介公告" value="1"></el-option>
						<el-option label="公告" value="2"></el-option>
					</el-select>
				</el-form-item> -->
        <el-form-item label="姓名">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入您的姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input
            v-model="ruleForm.mobile"
            placeholder="请输入您的电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input
            v-model="ruleForm.email"
            placeholder="请输入您的邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="单位">
          <el-input
            v-model="ruleForm.unit"
            placeholder="请输入您的单位"
          ></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input
            type="textarea"
            v-model="ruleForm.content"
            placeholder="请输入您的内容"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="sub" @click="onSubmit">提交</div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import probm from "@/components/probm/probm";
import util from "@/assets/script/util.js";
import moment from "moment";
import Clipboard from "clipboard";
export default {
  created() {
    let id = this.$route.query.id;
    this.getDetail(id);
  },
  components: {
    probm,
  },
  data() {
    return {
      ruleForm: {
        // title: '永登县城关镇和平街住宅转让推介公告',
        title: "",
        column: "推介公告",
        name: "",
        mobile: "",
        email: "",
        resource: "",
        content: "",
      },
      delObj: {},
    };
  },
  mounted() {},
  methods: {
    // 点击分享按钮
    clkShare(index) {
      switch (index) {
        case 1:
          let url = location.href;
          location.href = `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${url}&sharesource=qzone&title=${this.delObj.title}`;
          location.href = `http://connect.qq.com/widget/shareqq/index.html?url=${url}&sharesource=qzone&title=${this.delObj.title}`;
          break;
        case 2:
          this.$alert(
            this.delObj.title + location.href,
            "复制以下链接分享到微信",
            {
              confirmButtonText: "确定",
              callback: (action) => {
                console.log(action);
                if (action == "confirm") {
                  var input = document.createElement("input"); // js创建一个input输入框
                  input.value = this.delObj.title + location.href; // 将需要复制的文本赋值到创建的input输入框中
                  document.body.appendChild(input); // 将输入框暂时创建到实例里面
                  input.select(); // 选中输入框中的内容
                  document.execCommand("Copy"); // 执行复制操作
                  document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
                  this.$message({
                    message: "复制成功",
                    type: "success",
                  });
                }
              },
            }
          );
          break;
        case 3:
          // 分享到微博
          window.sharetitle = this.delObj.title; //分享内容的标题
          window.shareUrl = encodeURIComponent(location.href); //分享内容的链接,链接一定要用encodeURIComponent处理一下，不然分享之后的链接会没有参数

          var d = window;
          d.location.href =
            "http://service.weibo.com/share/share.php?url=" +
            d.shareUrl +
            "&title=" +
            d.sharetitle +
            "&searchPic=true"; //微博自动抓取图片用这个
          d.location.href =
            "http://service.weibo.com/share/share.php?url=" +
            d.shareUrl +
            "&title=" +
            d.sharetitle +
            "&pic=" +
            d.shareImg; //自定义图片用这个

          break;
      }
    },
    onSubmit() {
      this.OnlineMessage();
    },
    // 在线留言
    OnlineMessage() {
      let url = this.$api.trading.OnlineMessage;
      this.$axios.post(url, qs.stringify(this.ruleForm)).then((res) => {
        if (res.code) {
          this.ruleForm = {};
          this.ruleForm.title = this.delObj.title;
          this.$message({
            message: "提交成功",
            type: "success",
          });
        }else{
			this.$message({
            message: res.msg,
            type: "error",
          });
		}
      });
    },
    // 查询详情
    getDetail(id) {
      let url = this.$api.index.noticeDetail;
      this.$axios.get(url, { params: { id } }).then((res) => {
        if (res.code) {
          res.data.content = util.setContent(res.data.content);
          this.delObj = res.data;
          this.ruleForm.title = this.delObj.title;
          console.log("this.delObj", this.delObj);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.border{
  border: 1px solid red;
}
 .share {
  display: flex;
  align-items: center;
  color: rgba(102, 102, 102, 1);
  font-size: 16px;
  font-weight: 500;
  justify-content: flex-end;
  border-top: 0.5px solid rgba(235, 236, 239, 1);
  padding-top: 34px;
  margin-top: 34px;
  .sh-icon {
    display: flex;
    
    p {
      width: 32px;
      height: 32px;
      background: rgba(235, 236, 239, 1);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      }
      &:last-child {
        margin-right: 0;
      }
      i {
        font-size: 24px;
      }
    }
  }
}
.tip {
  font-size: 22px;
  font-weight: 500;
  color: var(--themeColor);
  margin-top: 84px;
  padding-bottom: 20px;
  border-bottom: 0.5px solid rgba(235, 236, 239, 1);
}
.from {
  margin-top: 40px;
}
.sub {
  width: 114px;
  height: 48px;
  background-color: var(--themeColor);
  color: white;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
}
.el-form-item__label{
 
}
</style>
