<!-- 交易公告和重点项目推荐 -->
<template>
	<div class="mores">
		<div class="lef">
			<tap :taps="['交易公告']" @more="clkLft"></tap>
			<div class="title" v-for="(item,index) in list" :key="index" @click="clkTle(item.id)">
				<span class="zb">{{item.title}}</span>
				<span class="yb">{{item.date}}</span>
			</div>
		</div>
		<div class="rig">
			<tap :taps="['重点项目推荐']" @more="clkRig"></tap>
			<div class="img">
				<div v-for="(item,index) in project" :key="index" v-if="index<6" @click="clkZd(item.id)">
					<div class="image">
						<img :src="item.thumb">
					</div>
					<p class="title">{{item.title}}</p>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import $ from "jquery"
	export default {
		data() {
			return {
				page: 1,
				list: {},
				project: {},
			};
		},
		created() {
			this.noticeList()
			this.getProJect()
		},
		methods: {
			clkTap(index) {
				this.tapIndex = index;
			},
			// 获取公告列表
			noticeList() {
				let url = this.$api.trading.noticeList
				let data = {
					catid: 60,
					page: this.page,
					type:1
				}
				this.$axios.get(
					url, {
						params: data
					}
				).then(res => {
					if (res.code) {
						this.list = res.data.data
					}
				})
			},
			// 查找重点项目推荐
			async getProJect() {
				let url = "Property/projectList"
				let data = {
					catid:98,
					page:1
				}
				let res = await this.$axios.get(url,{params:data})
				if(res.code) {
					this.project = res.data.data
				}
			},
			
			// 点击查看详情
			clkTle(id) {
				this.$router.push({path:"/trading/annodetail",query:{id}})
			},
			getCotain() {
				let letain = $(".mores")
				let width = letain.outerWidth()/1440*100+'px'
				letain.css("font-size",width)
			},
			clkZd(id) {
				this.$router.push({name:"promote_details",query:{id}})
			},
			// 重点项目推荐更多
			clkRig() {
				this.$router.push({name:"referrals"})
			},
			// 交易公告更多
			clkLft() {
				this.$router.push({name:'announcelist',query:{index:0}})
			}
		},
		mounted() {
			$(document).ready(()=>{
				this.getCotain()
				$(window).resize(()=>{
					this.getCotain()
				})
			})
		}
	}
</script>

<style lang="less" scoped>
	/deep/ .not-tap {
		margin-bottom: 0;
		&::after {
			height: 1px;
		}
	}
	.mores {
		display: flex;
		max-width: 1440px;
		margin: 0 auto;
		background-color: #f7fbfe;
		box-sizing: border-box;
		padding: 40px;
		.lef {
			width: 60%;
			.title {
				padding: 20px 0;
				border-bottom: 0.5px solid rgba(235, 236, 239, 1);
				display: flex;
				justify-content: space-between;
				cursor: pointer;
				align-items: center;
				&:hover {
					background-color: #fff;
				}
				.zb {
					color: rgba(51, 51, 51, 1);
					font-size: 16px;
					display: -webkit-box;
					overflow: hidden;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					width: 80%;
					line-height: 16px;
					padding-right: 20px;
				}
				.yb {
					color: rgba(51, 51, 51, 1);
					font-size: 16px;
					flex-shrink: 0;
				}
			}
		}
		.rig {
			width: 40%;
			margin-left: 40px;
			.img {
				display: grid;
				grid-template-columns: repeat(2,1fr);
				column-gap: 18px;
				row-gap: 18px;
				margin-top: 20px;
				.image {
					overflow: hidden;
					border: 1px solid rgba(235, 236, 239, 1);
					transition: box-shadow .5s;
					cursor: pointer;
					&:hover {
						box-shadow: 0 0 10px rgba(0,0,0,.2);
						img {
							transform: scale(1.1);
						}
					}
					img {
						width: 100%;
						height: 1.52em;
						object-fit: cover;
						transition: transform .5s;
					}
				}
				.title {
					font-size: 16px;
					display: -webkit-box;
					overflow: hidden;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					box-sizing: border-box;
					padding: 0 20px;
					margin-top: 10px;
					letter-spacing: 1px;
				}
			}
		}
	}
	
	@media (max-width:992px) {
		.mores {
			flex-direction: column;
			.lef {
				width: 100%;
			}
			.rig {
				width: 100%;
				margin-left: 0;
				margin-top: 30px;
				.img {
					.image {
						img {
							height: 3.8em;
						}
					}
				}
			}
		}
	}
	
	@media (max-width:768px) {
		
		.mores {
			padding: 20px 10px;
			.lef {
				.title {
					align-items: center;
				}
			} 
			.rig {
				.img {
					row-gap: 10px;
					column-gap: 10px;
					.title {
						font-size: 14px;
					}
				}
			}
		}
	}
</style>
