<template>
	<div>
		<scNav navTag="scDown" :themeColor="themeColor"></scNav>
		<!-- 面包屑 -->
		<div class="crumbs">
			<crumbs :crumbs="['首页','下载专区']"></crumbs>
		</div>
		<!-- 内容主题 -->
		<div class="gr-fa-txt sc-width">
			<tap :taps="['下载专区']" :bgColor="themeColor"><i></i></tap>
			
			<template v-if="downList.data.length">
				<ul class="rg-context">
					<li class="rg-ctx-item" v-for="(item,index) in downList.data" :key="item.id">
						<p class="rg-txt">{{item.title}}</p>
						<p class="down" @click="clkDown(item.file)" :style="{backgroundColor:themeColor}">下载<i class="iconfont">&#xe855;</i></p>
					</li>
				</ul>
				<div class="block">
					<el-pagination
						background
						:page-size="parseInt(downList.per_page)"
						@current-change="downPage"
						layout="total, prev, pager, next, jumper"
						:total="downList.total">
					</el-pagination>
				</div>
				<phonePg :page="page" :total="downList.total" :perPage="parseInt(downList.per_page)" @pageNext="pageNext" @pagePref="pagePref"></phonePg>
			</template>
			<div class="gr-fa-txt" v-else>
				<el-empty description="暂无数据"></el-empty>
			</div>
		</div>
		
		
		<bottom :bgColor="themeColor"></bottom>
	</div>
</template>

<script>
	import scNav from '@/components/special/scNav'
	import phonePg from '@/components/phonePg/phonePg'
	export default {
		components:{
			scNav,
			phonePg
		},
		data() {
			return {
				limit:10,
				page:1,
				themeColor:"",
				downList:{},
				catid:0,
				type:1
			}
		},
		created() {
			let type = this.$route.query.type
			this.type = type
			if(type==1) {
				// 科技创新版
				this.themeColor = "#386eb8"
				
				this.catid = 122
			}else if(type==2) {
				// 专精特新版
				this.themeColor = "#47a8c8"
				this.catid = 131
			}else if(type==3) {
				// 交易版
				this.themeColor = "#d3a21e"
				this.catid = 139
			}
			this.DownloadList(type)
		},
		methods:{
			// 获取资料下载
			async DownloadList(type) {
				let url = ""
				if(type==1) {
					url = this.$api.base+"Specialization/getList"
				}else if(type==2) {
					url = this.$api.base+"Technology/getList"
				}else if(type==3) {
					url = this.$api.base+"Trading/getList"
				}
				let params = {
					catid:this.catid,
					limit:this.limit,
					page:this.page
				}
				let res = await this.$axios.get(url,{params})
				if(res.code) {
					if(res.code) {
						this.downList = res
					}
				}
			},
			// 页数变化时触发
			downPage(e) {
				this.page = e
				this.DownloadList(this.type)
			},
			
			// 点击上一页
			pageNext() {
				if(!this.page--) {
					return false
				}
				this.page--
				this.DownloadList(this.type)
			},
			// 点击下一页
			pagePref() {
				if((this.page+1)*this.downList.per_page>this.downList.total) {
					return false
				}
				this.page ++
				this.DownloadList(this.type)
			},
			clkDown(url) {
				//url:下载文件的url
				let a = document.createElement('a')
				a.href = url;
				a.click();
			}
		}
	}
</script>

<style lang="less">
	.crumbs {
		max-width: 1440px;
		margin: 0 auto;
	}
	.gr-fa-txt .rg-context {
		padding: 0;
	}
	// 内容主题
	.gr-fa-txt {
		max-width: 1440px;
		margin: 0 auto;
		background-color: #fff;
		overflow: hidden;
		box-sizing: border-box;
		padding: 0 30px;
		margin-bottom: 60px;
		.rg-context {
			.rg-ctx-item {
				height: 88px;
				box-sizing: border-box;
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid #f5f6f7;
				align-items: center;
				font-size: 16px;
				font-weight: 400;
				color: #666;
				cursor: pointer;
				&:first-child {
					border-top: 1px solid #f5f6f7;
				}
				.rg-txt {
					box-sizing: border-box;
					padding-right: 20px;
				}
				.down {
					width: 112px;
					height: 48px;
					font-size: 16px;
					font-weight: 500;
					color: #fff;
					background-color: var(--themeColor);
					text-align: center;
					line-height: 48px;
					flex-shrink: 0;
					.iconfont {
						margin-left: 5px;
					}
				}
			}
		}
	}
	
	@media (max-width:900px) {
		.gr-fa-txt {
			padding: 0 20px;
		}
		.crumbs {
			padding: 0 20px;
		}
	}
	
	@media (max-width:768px) {
		.gr-fa-txt {
			padding: 0 10px;
		}
	}
</style>