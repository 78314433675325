<template>
	<div>
		<!-- 轮播图 -->
		<div class="swiper">
			<el-carousel :interval="6000" arrow="hover" :height="width+'px'" indicator-position="none" ref="swiper"
				@change="swpChge">
				<el-carousel-item v-for="(item,index) in banners" :key="item.id">
					<img :src="item.thumb" style="width: 100%;height: 100%;object-fit: cover;" alt="">
				</el-carousel-item>
			</el-carousel>
			
			
			<el-carousel :height="tleheg+'px'" direction="vertical" :autoplay="true" indicator-position="none" :interval="20000">
			    <el-carousel-item v-for="(item,index) in tz" :key="index">
					<div class="title" @click="go(item.url)">
						<img :src="item.thumb" alt="">
						<div>{{item.title}}</div>
					</div>
			    </el-carousel-item>
			</el-carousel>
			
			

			<ul class="pre-tips">
				<li class="pre-tip" @click.stop="tapSwip(index)" :class="preIndex==index?'active':''"
					v-for="(item,index) in banners" :key="item.id">
					<p class="pre-cle"></p>
					<p class="pre-num">{{'0'+(index+1)}}</p>
				</li>
			</ul>
		</div>

	</div>
</template>

<script>
	import $ from "jquery"
	import anime from "animejs"
	export default {
		data() {
			return {
				banners: [],
				preIndex: 0,
				tz: [],
				width: 600,
				tleheg:74
			}
		},
		mounted() {
			$(document).ready(() => {
				if ($(".swiper").width() < 768) {
					this.width = 250
					this.tleheg = 50
				} else {
					this.width = 600
					this.tleheg = 74
				}
				$(window).resize(() => {
					if ($(".swiper").width() < 768) {
						this.width = 250
						this.tleheg = 50
					} else {
						this.width = 600
						this.tleheg = 74
					}
				})
			})
		},
		created() {
			this.getBanner()
			this.getTongZhi()
		},
		methods: {
			//跳转通知链接
			go(url) {
				window.location.replace(url);
			},
			// 获取通知
			getTongZhi() {
				this.$axios.get("Index/getList", {
					params: {
						catid: 177
					}
				}).then(res => {
					if (res.code) {
						this.tz = res.data.data
					}
				})
			},

			swpChge(e) {
				this.preIndex = e
			},

			// 轮播图
			getSwiper() {
				let that = this
				new Swiper('.swiper-container', {
					pagination: '.swiper-pagination', //分页器
					loop: true, //循环
					paginationClickable: true, //分页器点击
					autoplay: 5000, //时长
					speed: 600,
					prevButton: '.swiper-button-prev',
					nextButton: '.swiper-button-next',
					onSlideChangeEnd: function(swiper) {
						that.preIndex = swiper.realIndex
					}
				})
			},

			// 点击数字
			tapSwip(index) {
				this.preIndex = index
				this.$refs.swiper.setActiveItem(index)
			},

			// 获取首页轮播
			getBanner() {
				let url = this.$api.index.getBanner
				this.$axios(url, {
					params: {
						catid: 47
					}
				}).then(res => {
					if (res.code) {
						this.banners = res.list
						this.$nextTick(() => {
							this.getSwiper()
						})
					}
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.swiper {
		position: relative;

		.title {
			display: flex;
			font-size: 14px;
			font-family: SourceHanSansCN;
			font-weight: 400;
			color: var(--themeColor);
			align-items: center;
			box-sizing: border-box;
			padding: 0 42px;
			background-color: #fff;
			height: 74px;
			position: absolute;
			z-index: 99999;
			bottom: 0;
			transform: translateX(-50%);
			width: 1440px;
			left: 50%;
			cursor: pointer;
			&:hover{
				font-weight: bold;
			}

			div {
				// display: -webkit-box;
				overflow: hidden;
				// -webkit-box-orient: vertical;
				// -webkit-line-clamp: 1;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.title img {
			width: 33px;
			margin-right: 28px;
		}
	}

	.pre-tips {
		display: flex;
		justify-content: center;
		position: absolute;
		z-index: 9999999999999;
		left: 50%;
		bottom: 84px;
		transform: translateX(-50%);

		.pre-tip {
			display: flex;
			flex-direction: column;
			align-items: center;
			opacity: .5;
			margin-right: 40px;
			cursor: pointer;

			&:last-child {
				margin-right: 0;
			}

			.pre-cle {
				width: 5px;
				height: 5px;
				border-radius: 50%;
				border: 1px solid #fff;
				margin-bottom: 5px;
			}

			.pre-num {
				color: #fff;
				font-size: 18px;
			}

			&.active {
				opacity: 1;

				.pre-cle {
					background-color: #fff;
				}
			}
		}
	}

	.swiper-container {
		position: relative;

	}

	.swiper-slide {
		position: relative;
	}

	.swiper-slide img {
		width: 100%;
		object-fit: cover;
	}

	.swiper-slide .txt {
		font-size: 42px;
		font-family: SourceHanSansCN;
		font-weight: 500;
		text-align: center;
		position: absolute;
		top: 45%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.swiper-slide .txt>div {
		margin-bottom: 30px;
		background: linear-gradient(0deg, #EF9742 0%, #FFFFEC 16.3330078125%, #EF9742 55.6884765625%, #FFFFC7 85.498046875%, #E79E58 100%);
		-webkit-background-clip: text;
		color: transparent;
	}

	.swiper-slide .txt>div:last-child {
		margin-bottom: 0;
	}

	@media (max-width:1440px) {
		.swiper {
			.title {
				width: 100%;
			}
		}

	}

	@media (max-width:1275px) {
		.swiper-slide {
			.txt {
				font-size: 32px;
			}
		}
	}

	@media (max-width:1000px) {
		.swiper-slide {
			img {
				height: 500px;
			}

			.txt {}
		}
	}

	@media (max-width:900px) {
		.swiper {
			.title {
				padding: 0 10px;
			}
		}

	}

	@media (max-width:790px) {
		.swiper-slide {
			.txt {
				font-size: 28px;
				right: 0;
			}

			img {
				height: 350px;
			}
		}
	}

	@media (max-width:768px) {
		.swiper {
			.title {
				height: 50px;

				img {
					width: 25px;
					margin-right: 10px;
				}
			}
		}

		.pre-tips {
			bottom: 60px;

			.pre-tip {
				margin-right: 15px;

				.pre-num {
					font-size: 16px;
				}
			}
		}
	}
</style>
