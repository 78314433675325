import { render, staticRenderFns } from "./pledge.vue?vue&type=template&id=05c10959&scoped=true&"
import script from "./pledge.vue?vue&type=script&lang=js&"
export * from "./pledge.vue?vue&type=script&lang=js&"
import style0 from "./pledge.vue?vue&type=style&index=0&id=05c10959&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05c10959",
  null
  
)

export default component.exports