<!-- 交易公告 -->
<template>
	<div class="item">
		<tap :taps="taps" :tapIndex="tapIndex" @clkTap="clkTap" :isMore="false" :tapsTrue="true">
		</tap>
		<div class="table">
			<el-table :data="tableData" style="width: 100%"
				:header-cell-style="{background:'rgba(247, 251, 254, 1)',height:'56px',textAlign: 'center'}"
				:cell-style="{textAlign: 'center'}" :row-style="{height:84+'px'}">
				<el-table-column prop="projectno" label="项目编号" width="300"></el-table-column>
				<el-table-column prop="title" label="项目名称"></el-table-column>
				<el-table-column :formatter="PriceFormat" prop="price" label="挂牌价格(万元)"></el-table-column>
				<el-table-column prop="enddate" label="挂牌截止日期"></el-table-column>
				<el-table-column label="项目状态">
					<template slot-scope="scope">
						<div class="baombtn" v-show="timeFlag(scope.row.enddate)" @click.stop="clkCell(scope.row)">报名中</div>
						<div class="baombtn jzbm" v-show="!timeFlag(scope.row.enddate)" @click.stop="clkCell(scope.row)">截止报名</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination :hide-on-single-page="true" :total="5" layout="prev, pager, next"></el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				taps: [],
				tapIndex: 0,
				currentPage: 1,
				tableData: [],
				page: 1
			};
		},
		created() {
			this.projectList(60)
			this.leftNav()
		},
		computed:{
			timeFlag() {
				return val=>{
					let date = new Date()
					let endDate = new Date(val.replace("-","/"))
					if(endDate>date) {
						return true
					}else {
						return false
					}
				}
			}
		},
		methods: {
			// 点击报名
			clkBm(id) {
				console.log(id);
			},
			clkCell(e) {
				this.$router.push({path:"/trading/annodetail",query:{id:e.id}})
			},
			// 价格格式化显示
			PriceFormat(row, column) {
				return column.price == null ? "详见公告" : column.price;
			},
			// 查找左边导航
			async leftNav() {
				let url = this.$api.trading.leftNav
				let data = {
					nav_id: 59
				}
				let res = await this.$axios.get(url, {
					params: data
				})
				if (res.code) {
					this.taps = res.list
					console.log('this.taps', this.taps)
				}
			},
			clkTap(index, id) {
				this.tapIndex = index;
				this.projectList(id)
			},
			// 获取公告列表
			projectList(catid) {
				let url = this.$api.trading.noticeList
				let data = {
					catid,
					page: this.page
				}
				this.$axios.get(
					url, {
						params: data
					}
				).then(res => {
					if (res.code) {
						this.tableData = res.data.data
						console.log('this.tableData', this.tableData)
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.baombtn {
		background-color: var(--themeColor);
		color: #fff;
		width: 100px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		color: #fff;
		margin: 0 auto;
		cursor: pointer;
		
		&:hover {
			background-color: rgba(10, 72, 144,.6);
		}
	}
	.jzbm {
		background-color: rgba(218, 218, 218,1);
		&:hover {
			background-color: rgba(218, 218, 218,1);
		}
	}
	.item {
		background-color: #fff;
		max-width: 1360px;
		margin: 0 auto;
		padding: 60px 40px;
	}

	@media (max-width:768px) {
		.item {
			padding: 10px 10px 0;
			overflow-y: hidden;

			.table {
				padding: 0;
				overflow-x: scroll;
			}
		}
	}
</style>
