<!-- 投资者教育问题 -->
<template>
	<div>
		<navs navTag="ecation"></navs>
		<div class="region">
			<!-- 面包屑 -->
			<crumbs :crumbs="crumbs" @clkItem="clkItemd"></crumbs>
			<div class="rg-cotain">
				<!-- 侧边导航栏 -->
				<navleft :navs="navs" :tapItem="tapItem" @clkItem="clkItem"></navleft>
				<!-- 子组件渲染 -->
				<div class="chirn">
					<router-view/>
				</div>
			</div>
		</div>
		<!-- 底部 -->
		<bottom></bottom>
	</div>
</template>

<script>
	import $ from "jquery"
	export default {
		data() {
			return {
				crumbs:['首页','投资者教育','常见问题'],
				tapItem:0,
				navs:['常见问题','投资者课程','投资者教育活动']
			}
		},
		created() {
			let index = parseInt(this.$router.history.current.query.index)
			this.tapItem = index
		},
		methods:{
			clkItemd(index) {
				switch (index) {
					case 0:
						this.$router.push({name:"index"})
					break;
				}
			},
			clkItem(index,text) {
				this.tapItem = index
				this.crumbs = ['首页','投资者教育','常见问题']
				// 修改数组
				this.$set(this.crumbs,2,text)
				
				switch (index) {
					case 0:
						this.$router.push({name:"proplem",query:{index}})
					break;
					case 1:
						this.$router.push({name:"course",query:{index}})
					break;
					case 2:
						this.$router.push({name:"activity",query:{index}})
					break;
				}
			},
			getCotain() {
				let letain = $(".chirn")
				let width = letain.outerWidth()/1198*100+'px'
				letain.css("font-size",width)
			}
		},
		mounted() {
			this.$nextTick(()=>{
				this.getCotain()
				$(window).resize(()=>{
					this.getCotain()
				})
			})
			
			this.$bus.$on('ettap',(data)=>{
				this.crumbs.push(data)
			})
		},
	}
</script>

<style lang="less" scoped>
	.region {
		.rg-cotain {
			margin-bottom: 60px;
		}
	}
	
	
	@media (max-width:900px) {
		.region {
			.rg-cotain {
				flex-direction: column;
				.chirn {
					margin-top: 10px;
				}
			}
		}
	}
</style>