<template>
	<div>
		<div v-for="(item,index) in proList" :key="item.id" class="cup-item" @click="clkCup(item.id)">
			<div class="cup-icon">
				<p class="p-icon">
					<i class="iconfont">&#xe605;</i>
				</p>
				<p style="display: -webkit-box;overflow: hidden;-webkit-box-orient: vertical;-webkit-line-clamp: 1;">{{item.title}}</p>
			</div>
			<p style="flex-shrink: 0;padding-left: 20px;">{{item.inputtime}}</p>
		</div>
		
		<el-empty v-show="!proList.length" description="暂无数据"></el-empty>
		
		<!-- 分页 -->
		<div class="block" v-show="proList.length">
		    <el-pagination
				background
				:page-size="per_page"
				layout="total, prev, pager, next, jumper"
				:total="total"
				@current-change="proChange">
		    </el-pagination>
		</div>
		<!-- 手机端分页 -->
		<!-- <div class="block2" v-if="proList.data.length>proList.per_page">
			<phonePg :page="page" :total="proList.total" :perPage="proList.per_page" @pageNext="pageNext" @pagePref="pagePref"></phonePg>
		</div> -->
	</div>
</template>

<script>
	import phonePg from "@/components/phonePg/phonePg"
	import qs from "qs"
	import moment from "moment"
	export default {
		data() {
			return {
				// 常见问题列表
				proList:[],
				page:1,
				total:0,
				per_page:0
			}
		},
		created() {
			this.getProplem()
		},
		components:{
			phonePg
		},
		methods:{
			// 点击某一行
			clkCup(id) {
				let index = this.$route.query.index
				let queryPath = "Policyzone/InvestmentDetails"
				this.$router.push(
					{
						name:"plemdeal",
						query:{
							id,
							index,
							queryPath
						},
					}
				)
				this.$bus.$emit("ettap","问题详情")
			},
			// 获取问题列表
			getProplem() {
				let url = this.$api.ecation.InvestmentList
				let data = {
					nav_id:5,
					clas_id:39,
					page:this.page
				}
				this.$axios.post(
					url,
					qs.stringify(data)
				).then(res=>{
					if(res.code) {
						if(res.data.data.length) {
							res.data.data.forEach((item,index)=>{
								item.inputtime = moment(parseInt(item.inputtime*1000)).format("YYYY-MM-DD")
							})
							this.proList = res.data.data
							this.perPage = res.data.per_page
							this.total = res.data.total
						}
					}
				})
			},
			// 分页变化时触发
			proChange(e) {
				this.page = e
				this.getProplem()
			},
			// 点击上一页
			pageNext() {
			    if(this.page-1 == 0) {
			     return false
			    }
			    this.page--
			    this.getProplem()
			},
			// 点击下一页
			pagePref() {
			    if(this.page*this.downList.per_page>this.downList.total) {
					return false
			    }
			    this.page ++
			    this.getProplem()
			}
		}
	}
</script>

<style lang="less" scoped>
	.cup-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: rgba(102, 102, 102, 1);
		font-size: 16px;
		font-weight: 400;
		padding: 30px 0;
		border-bottom: 1px solid rgba(235, 236, 239, 1);
		cursor: pointer;
		&:first-child {
			padding-top: 0;
		}
		
		.cup-icon {
			display: flex;
			align-items: center;
			.p-icon {
				width: 26px;
				height: 26px;
				background: rgba(10, 72, 144, 0.08);
				border-radius: 50%;
				text-align: center;
				line-height: 26px;
				margin-right: 8px;
				.iconfont {
					color: var(--themeColor);
				}
			}
			
		}
	}
	@media (max-width:900px) {
		.cup-item:first-child {
			padding-top: 20px;
		}
	}
</style>